export const EMOTION_DISTRIBUTION_URL = 'planning-tool/emotion-distribution';
export const SENTIMENT_DISTRIBUTION_URL = 'planning-tool/sentiment-distribution';
export const TOTAL_REACH_URL = 'planning-tool/reach-estimate';
export const INTENT_SEASONALITY_URL = 'planning-tool/intent-seasonality';
export const INTENT_CONFIRMATION_URL = 'planning-tool/intent-confirmation';
export const ALL_GEO_LANGUAGES = 'planning-tool/geo-language/getAll';
export const LANGUAGES_BY_GEO = 'language/getByGeo';
export const SEGMENT_GEO_LANGUAGES = 'planning-tool/geo-language';
export const SEGMENT_SCALE_IMPACT_URL = 'planning-tool/scale-impact';

export const infoTitles = { negative: 'negative', neutral: 'neutral', positive: 'positive' };
export const defaultGeoLanguages = {
  Geo: [],
};
export const D3_PACK_SCALING_FACTOR = 1;
export const GEO_LANGUAGES_BAR_WIDTH = 192;
export const MAX_COLLAPSED_GEO_ITEMS = 3;
