import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { KeywordGroupItem } from '../keywordGroupApiTypes';

export const keywordGroupListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    keywordGroupList: build.query<KeywordGroupItem[], null>({
      query: () => ({ method: 'GET', url: 'keyword-group/getAll' }),
      providesTags: ['KeywordGroups'],
    }),
  }),
});

export const { useKeywordGroupListQuery } = keywordGroupListApiSlice;
