import { api } from 'services/api';

import { KEYWORD_URL, KEYWORDS_GROUP_URL } from './constants';
import { KeywordGroupPayload, KeywordGroupResponse } from './keywordGroupApiTypes';

const getKeywordsGroup = () => {
  return api.get(KEYWORDS_GROUP_URL);
};

const getKeywordGroupById = (id?: string) => {
  return api.get<KeywordGroupResponse>(`${KEYWORD_URL}/${id}`);
};

const saveKeywordGroup = (data: KeywordGroupPayload) =>
  api.post<KeywordGroupResponse>(`/${KEYWORD_URL}/`, data);

const deleteKeywordGroup = (id: string) =>
  api.delete<KeywordGroupResponse>(`/${KEYWORD_URL}/${id}`);

const updateKeywordGroup = (id: string, data: KeywordGroupPayload) => {
  return api.put<KeywordGroupResponse>(`${KEYWORD_URL}/${id}`, data);
};

export const keywordGroupApi = {
  getKeywordsGroup,
  getKeywordGroupById,
  saveKeywordGroup,
  updateKeywordGroup,
  deleteKeywordGroup,
};
