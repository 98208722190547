import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { scrapeUrlApi } from '../distanceChecker/scrapeUrl/scrapeUrlApi';
import {
  ScrapeUrlRequest,
  ScrapeUrlResponse,
} from '../distanceChecker/scrapeUrl/scrapeUrlApiTypes';
import { ScrapeUrlState } from '../distanceChecker/scrapeUrl/types';
import { searchByVectorApi } from './searchByVectorApi';
import { getSearchByVector } from './searchByVectorSelectors';
import {
  searchByVectorStart,
  searchByVectorFailure,
  searchByVectorSuccess,
  scrapeUrlStart,
  scrapeUrlSuccessful,
  scrapeUrlFailure,
} from './searchByVectorSlice';
import { SearchByVectorRequest, SearchByVectorResponse } from './types';

function* searchByVectorSaga() {
  try {
    const searchByVector: SearchByVectorRequest = yield select(getSearchByVector);
    const uniqueKeywords = Array.from(
      new Set(searchByVector.keywords!.map((keyword) => keyword.trim())),
    );

    const searchByVectorData: SearchByVectorRequest = {
      keywords: uniqueKeywords,
      // matchingUrl: searchByVector.matchingUrl,
      language: searchByVector.language,
      // weightType: searchByVector.weightType,
      minSimilarity: searchByVector.minSimilarity,
      maxSimilarity: searchByVector.maxSimilarity,
      numOfURLs: searchByVector.numOfURLs,
      type: searchByVector.type,
    };

    const response: SearchByVectorResponse = yield call(
      searchByVectorApi.searchByVector,
      searchByVectorData,
    );

    response.urlList = response.urlList.map((entry) => {
      if (entry.url.endsWith('/')) {
        entry.url = entry.url.slice(0, -1);
      }

      return entry;
    });

    yield put(searchByVectorSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(
      searchByVectorFailure({
        message: i18next.t('adminPanel.searchByVector.errors.failedSearch'),
      }),
    );
  }
}
export function* getScrapedUrlData() {
  try {
    const scrapeUrlState: ScrapeUrlState = yield select(getSearchByVector);

    const request: ScrapeUrlRequest = {
      url: scrapeUrlState.url,
      render: scrapeUrlState.render,
    };
    const response: ScrapeUrlResponse = yield call(scrapeUrlApi.scrapeUrl, request);

    yield put(scrapeUrlSuccessful(response));
  } catch (e) {
    notifyError(e);

    yield put(scrapeUrlFailure({ message: i18next.t('adminPanel.scrapeUrl.errors.failed') }));
  }
}

export function* searchByVectorSagas() {
  yield all([
    takeLatest(searchByVectorStart.type, searchByVectorSaga),
    takeLatest(scrapeUrlStart.type, getScrapedUrlData),
  ]);
}
