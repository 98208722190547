import { ForgotPasswordFormFields } from 'components/ForgotPasswordForm';
import { ResetPasswordFormFields } from 'components/ResetPasswordForm';

import { api } from 'services/api';

import {
  AZURE_PROVIDER_OAUTH_EXCHANGE_URL,
  AZURE_PROVIDER_OAUTH_URL,
  LOGOUT_URL,
  PASSWORD_RESET_URL,
  REQUEST_PASSWORD_RESET_URL,
  SSO_LOGOUT,
  TABLEAU_TOKEN,
} from './constants';
import { LogoutResponse, SSOTokenResponse, SSOUrlResponse } from './types';

function ssoLogout(): Promise<void> {
  return api.get(SSO_LOGOUT);
}

function logout() {
  return api.post<LogoutResponse>(LOGOUT_URL);
}

function requestPasswordReset(data: ForgotPasswordFormFields) {
  return api.post(REQUEST_PASSWORD_RESET_URL, data);
}

function resetPassword(data: ResetPasswordFormFields, passwordToken: string) {
  return api.post(PASSWORD_RESET_URL, data, {
    // TODO: remove camelcase rule when we confirm that key is snake case
    // eslint-disable-next-line camelcase
    params: { reset_password_token: passwordToken },
  });
}

function getSSOUrl() {
  return api.get<SSOUrlResponse>(AZURE_PROVIDER_OAUTH_URL);
}

function exchangeSSOCode(code: string) {
  return api.post<SSOTokenResponse>(AZURE_PROVIDER_OAUTH_EXCHANGE_URL, { code });
}

function getTableauToken() {
  return api.get(TABLEAU_TOKEN);
}

export const authApi = {
  logout,
  ssoLogout,
  requestPasswordReset,
  resetPassword,
  getSSOUrl,
  exchangeSSOCode,
  getTableauToken,
};
