import { AxiosInstance } from 'axios';

import { calculateTokenExpirationDate, isTokenExpiresSoon } from 'modules/auth/authService';
import { authStorage } from 'modules/auth/authStorage';
import { REGENERATE_TOKEN_URL } from 'modules/auth/constants';
import { SSOTokenResponse } from 'modules/auth/types';

import { notifyError } from '../logService';
import { api } from './api';

let isTokenRefreshing = false;

export function addRequestInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use(
    async (config) => {
      const authToken = authStorage.getAuthToken();

      if (authToken) {
        if (config.headers) {
          config.headers['Authorization'] = `Bearer ${authToken}`;
        }

        const authTokenExpirationDate = authStorage.getTokenExpirationDate();
        const isTokenRefreshNeeded = isTokenExpiresSoon(authTokenExpirationDate);

        if (isTokenRefreshNeeded && !isTokenRefreshing) {
          isTokenRefreshing = true;
          try {
            const { token: newToken, token_duration: tokenTTL } =
              await api.post<SSOTokenResponse>(REGENERATE_TOKEN_URL);

            const expiredAt = calculateTokenExpirationDate(tokenTTL);

            authStorage.saveAuthInfo(newToken, expiredAt);
          } catch (error) {
            notifyError(error);
          }

          isTokenRefreshing = false;
        }
      }

      return config;
    },
    (err) => Promise.reject(err),
  );
}
