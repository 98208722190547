import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GroupingMode, GroupingModes } from '../types';
import { UserListItem } from '../userApiTypes';
import { filterByCompanyNames, groupByField } from './groupUsersByUtils';

const GROUP_BY_KEY = 'groupBy';

export const useUsersGrouping = () => {
  const [params, setParams] = useSearchParams();
  const initialGroupingMode = (params.get(GROUP_BY_KEY) as GroupingMode) || GroupingModes.UNGROUPED;
  const [groupingMode, setGroupingMode] = useState<GroupingMode>(initialGroupingMode);
  const [selectedCompany, setSelectedCompany] = useState<string[]>([]);

  useEffect(() => {
    const groupingMode = params.get(GROUP_BY_KEY) || GroupingModes.UNGROUPED;

    setGroupingMode(groupingMode as GroupingMode);
  }, [params]);

  useEffect(() => {
    switch (groupingMode) {
      case GroupingModes.UNGROUPED: {
        setSelectedCompany([]);
        break;
      }
    }
  }, [groupingMode]);

  const onGroupingModeChange = useCallback(
    (mode: GroupingMode) => {
      setGroupingMode(mode);
      setParams(mode === GroupingModes.UNGROUPED ? undefined : { groupBy: mode });
    },
    [setParams],
  );

  const setGroupByCompany = (companies: string[]) => {
    setSelectedCompany(companies);
  };

  const groupUsers = (users: UserListItem[] = [], groupingMode: GroupingMode) => {
    return groupByField(users, groupingMode);
  };

  const filterByGroupingMode = (users: UserListItem[]) => {
    if (groupingMode === GroupingModes.COMPANY) {
      if (!selectedCompany.length) {
        return users;
      }

      return filterByCompanyNames(users, selectedCompany);
    }

    return users;
  };

  return {
    groupingMode,
    selectedCompany,
    setGroupByCompany,
    onGroupingModeChange,
    groupUsers,
    filterByGroupingMode,
    isTableUngrouped: groupingMode === GroupingModes.UNGROUPED,
    isGroupingByCompanyApplied: groupingMode === GroupingModes.COMPANY,
    isCompanyFilteringApplied: selectedCompany.length > 0,
  };
};
