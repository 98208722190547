import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { customCategoriesApi } from './customCategoriesApi';
import { getCustomCategory } from './customCategorySelectors';
import {
  deleteCustomCategoryStart,
  fetchCustomCategoryDetails,
  fetchCustomCategoryDetailsFailure,
  fetchCustomCategorySuccess,
  initCustomCategoryDetails,
  saveCustomCategory,
  updateCustomCategory,
  updateValues,
} from './customCategorySlice';
import { handleErrorSaga } from './handleErrorSaga';
import { customCategorySaveSaga } from './sagas/customCategorySaveSaga';
import { customCategoryUpdateSaga } from './sagas/customCategoryUpdateSaga';
import { deleteCustomCategorySaga } from './sagas/deleteCustomCategorySaga';
import { CustomCategoryResponse, CustomCategoryState } from './types';

function* getCustomCategoryDetailsSaga({
  payload: { id, navPath },
}: PayloadAction<{ id: string; navPath: string }>) {
  try {
    const customCategoryDetails: CustomCategoryResponse = yield call(
      customCategoriesApi.getCustomCategoryById,
      id,
    );

    customCategoryDetails.keywordGroups = customCategoryDetails.keywordGroups ?? [];
    yield put(updateValues(customCategoryDetails));
    yield put(fetchCustomCategorySuccess({ navPath, id }));
  } catch (error) {
    yield handleErrorSaga(
      error,
      fetchCustomCategoryDetailsFailure,
      i18next.t('adminPanel.customCategories.errors.customCategoryRecurseNotFound'),
    );
  }
}

function* customCategoryDetailsInitSaga({ payload }: PayloadAction<{ id: string }>) {
  const customCategory: CustomCategoryState = yield select(getCustomCategory);
  const error = customCategory.error;
  const errorStatus = customCategory.errorStatus;

  if (payload.id !== customCategory.lastLoadedId && !(errorStatus || error)) {
    yield put(fetchCustomCategoryDetails(payload));
  }
}

export function* customCategoriesSagas() {
  yield all([
    takeLatest(deleteCustomCategoryStart.type, deleteCustomCategorySaga),
    takeLatest(saveCustomCategory.type, customCategorySaveSaga),
    takeLatest(updateCustomCategory.type, customCategoryUpdateSaga),
    takeEvery(fetchCustomCategoryDetails.type, getCustomCategoryDetailsSaga),
    takeEvery(initCustomCategoryDetails.type, customCategoryDetailsInitSaga),
  ]);
}
