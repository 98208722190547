import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Text } from 'components/common';

import {
  getCategoriesDataObject,
  getCategoriesSearchString,
} from 'modules/segment/segmentSelectors';
import { toggleCategoryNodeCheck, toggleCategoryNodeExpand } from 'modules/segment/segmentSlice';

import { CheckBoxExpandIcon } from './CheckBoxExpandIcon';
import { MatchedCategoryPartHighlighter } from './MatchedCategoryPartHighlighter';
import { countChildren } from './utils';

type NodeProps = {
  id: string;
  checked: boolean;
};

export const TreeNode = ({ id, checked }: NodeProps) => {
  const dispatch = useDispatch();
  const allCategories = useSelector(getCategoriesDataObject);
  const searchString = useSelector(getCategoriesSearchString);
  const category = allCategories[id];

  const hasChildren = useMemo(() => category.children.length > 0, [category.children]);
  const someChildrenAreDisplayed = useMemo(
    () => category.children.some((childId) => allCategories[childId].displayed),
    [allCategories, category.children],
  );

  const handelExpand = useCallback(() => {
    dispatch(toggleCategoryNodeExpand(category.id));
  }, [category.id, dispatch]);

  const handleChange = useCallback(() => {
    dispatch(toggleCategoryNodeCheck(category.id));
  }, [category.id, dispatch]);

  const childrenCount = useMemo(
    () => countChildren(category, allCategories),
    [allCategories, category],
  );

  if (!category.displayed) {
    return null;
  }

  return (
    <>
      <div className="flex">
        {hasChildren && someChildrenAreDisplayed ? (
          <CheckBoxExpandIcon expanded={category.expanded} handleExpand={handelExpand} />
        ) : (
          <div className="mr-2 w-[24px]" />
        )}
        <Checkbox checked={category.checked || checked} onChange={handleChange}>
          <MatchedCategoryPartHighlighter name={category.name} searchString={searchString} />
          <Text large className="ml-2 text-primary-dark-purple-40">
            {childrenCount || null}
          </Text>
        </Checkbox>
      </div>
      {hasChildren && category.expanded && (
        <ol className="flex flex-col gap-y-4 pl-6">
          {category.children.map((id: string) => (
            <TreeNode key={id} id={id} checked={category.checked || checked} />
          ))}
        </ol>
      )}
    </>
  );
};
