import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { notificationService } from '../../notifications';
import { contextaApi } from './contextaApi';
import {
  ContextaRequestData,
  ContextaResponse,
  ContextaState,
  EmotionOutput,
  Keywords,
} from './contextaForm/types';
import { getContexta } from './contextaSelectors';
import {
  contextaFailure,
  contextaSuccess,
  getContextaStart,
  getContextaSuccess,
  insertContextaStart,
} from './contextaSlice';

function* getContextaByUrlSaga() {
  try {
    const contexta: ContextaState = yield select(getContexta);
    const response: ContextaResponse = yield call(contextaApi.getContexta, contexta.url!);

    yield put(getContextaSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(
      contextaFailure({
        message: i18next.t('adminPanel.contexta.errors.failedGetContexta'),
      }),
    );
  }
}

function* insertContextaByUrlSaga() {
  try {
    const contexta: ContextaState = yield select(getContexta);
    const weightedKeywords: { [key: string]: number } = {};
    const categories: { [key: string]: number } = {};
    const subcategories: { [key: string]: number } = {};
    const emotion: EmotionOutput = { Emotions: [] };
    let keywordsJSON: Keywords = {};
    let keywordsVector: number[] = [];

    if (contexta.emotionObj) {
      for (const [key, value] of Object.entries(contexta.emotionObj)) {
        emotion.Emotions.push([key, value]);
      }
    }

    contexta.weightedKeywords?.forEach((item) => {
      weightedKeywords[item.name] = item.weight;
    });

    contexta.categories?.forEach((item) => {
      categories[item.name] = item.weight;
    });

    contexta.subcategories?.forEach((item) => {
      subcategories[item.name] = item.weight;
    });

    if (contexta.keywords) {
      try {
        keywordsJSON = JSON.parse(contexta.keywords);
      } catch (error) {
        notifyError('Invalid JSON format for keywords');

        return;
      }
    }

    if (contexta.keywordsVector.length === 1 && contexta.keywordsVector[0] === '') {
      keywordsVector = [];
    } else {
      if (typeof contexta.keywordsVector !== 'string') {
        keywordsVector = contexta.keywordsVector.map(Number);
      }
    }

    const contextaData: ContextaRequestData = {
      url: contexta.url,
      accuracy: +contexta.accuracy!,
      adClutter: +contexta.adClutter!,
      contentValidDays: +contexta.contentValidDays!,
      sentiment: +contexta.sentiment!,
      matchedTime: contexta.matchedTime,
      status: contexta.status,
      numberOfWords: +contexta.numberOfWords!,
      keywordsVector: keywordsVector,
      categories: categories,
      subcategories: subcategories,
      contentLength: +contexta.contentLength!,
      ttl: +contexta.ttl!,
      h1: contexta.h1,
      h2: contexta.h2,
      language: contexta.language,
      metaDescription: contexta.metaDescription,
      useBaseUrl: contexta.useBaseUrl,
      matchedSeg: contexta.matchedSeg
        ?.split('\n')
        .map((seg) => seg.trim())
        .filter((seg) => seg !== ''),
      metadataTags: contexta.metadataTags
        ?.split('\n')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== ''),
      weightedKeywords: weightedKeywords,
      emotion: emotion,
      keywords: keywordsJSON,
    };
    const response: ContextaState = yield call(contextaApi.insertContexta, contextaData);

    notificationService.showSuccess({
      key: 'userFormSuccess',
      message: i18next.t('adminPanel.contexta.success.insertSuccess'),
    });
    yield put(contextaSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(
      contextaFailure({
        message: i18next.t('adminPanel.contexta.errors.failedInsert'),
      }),
    );
  }
}

export function* contextaSagas() {
  yield all([
    takeLatest(getContextaStart.type, getContextaByUrlSaga),
    takeLatest(insertContextaStart.type, insertContextaByUrlSaga),
  ]);
}
