import { api } from 'services/api';

import { CONTEXTA_URL } from './constants';
import { ContextaRequestData, ContextaState } from './contextaForm/types';

const insertContexta = (data: ContextaRequestData) => {
  return api.post(`${CONTEXTA_URL}/create`, data);
};

const getContexta = (url: string) => {
  return api.get<ContextaState>(`${CONTEXTA_URL}`, { params: { url: url } });
};

export const contextaApi = {
  insertContexta,
  getContexta,
};
