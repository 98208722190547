import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SegmentListItem } from '../segment/segmentApiTypes';
import { LabelsState, SegmentLabel } from './types';
import { getLabelColorByIndex } from './utils';

const initialState: LabelsState = {
  isLoading: false,
  labels: [],
};

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    getLabels: (state: LabelsState) => {
      state.isLoading = true;
    },
    getLabelsSuccess: (state: LabelsState, { payload }: PayloadAction<SegmentLabel[]>) => {
      state.labels = payload.map((label, index) => ({
        ...label,
        color: getLabelColorByIndex(index),
      }));
      state.isLoading = false;
    },
    getLabelsFailure: (state: LabelsState) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createNewLabel: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: LabelsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ label: string; segmentId: string }>,
    ) => {},
    addLabelToSegment: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: LabelsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      payload: PayloadAction<{ label: string; segmentId: string }>,
    ) => {},
    removeLabelFromSegment: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: LabelsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      payload: PayloadAction<{ label: string; segment: SegmentListItem }>,
    ) => {},
    updateSegmentLabels: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: LabelsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ labels: string[]; segmentId: string }>,
    ) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteLabel: (state: LabelsState, { payload }: PayloadAction<string>) => {},
  },
});

export const labels = labelsSlice.reducer;
