import { DetailedHTMLProps, HTMLAttributes, memo } from 'react';

import { nanoTwMerge } from 'services/twMerge';

export const Card = memo(
  ({
    children,
    className,
    ...rest
  }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
    <div
      className={nanoTwMerge(
        'rounded-xl bg-secondary-white px-6 py-6 shadow-card-shadow',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  ),
);
Card.displayName = 'Card';

export const CardHeader = memo(
  ({
    children,
    className,
    ...rest
  }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
    <div className={nanoTwMerge('mb-6 flex items-center justify-between', className)} {...rest}>
      {children}
    </div>
  ),
);
CardHeader.displayName = 'CardHeader';

export const CardContent = memo(
  ({
    children,
    className,
    ...rest
  }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
    <div className={className} {...rest}>
      {children}
    </div>
  ),
);
CardContent.displayName = 'CardContent';
