import { IconProps } from 'feather-icons-react';

export const CodeIcon = ({ className, fill = 'currentColor', size = 24 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.11279 17.4925L14.8728 1.17248L12.9868 0.506836L7.22681 16.8268L9.11279 17.4925ZM0.234224 8.35657L4.40398 3.39145L5.93554 4.67766L2.30506 9.00064L5.93648 13.3377L4.40304 14.6217L0.233277 9.64165C-0.078116 9.26975 -0.0777169 8.72801 0.234224 8.35657ZM17.6966 3.39273L21.8519 8.35785C22.1624 8.72879 22.1628 9.26876 21.853 9.64021L17.7053 14.6125L16.1695 13.3314L19.782 9.00071L16.1628 4.67633L17.6966 3.39273Z"
      fill={fill}
    />
  </svg>
);
