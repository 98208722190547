import {
  Categories,
  Emotions,
  Entity,
  Keywords,
  Language,
  Personas,
  SensitiveSubjects,
  Sentiment,
} from './components';

export const TargetingOptionIds = {
  categories: 'categories',
  sentiments: 'sentiments',
  languages: 'languages',
  entity: 'entity',
  emotions: 'emotions',
  personas: 'personas',
  sensitiveSubjects: 'sensitiveSubjects',
  keywords: 'keywords',
} as const;

export const TargetingPaths = {
  [TargetingOptionIds.categories]: 'categories',
  [TargetingOptionIds.sentiments]: 'sentiments',
  [TargetingOptionIds.languages]: 'languages',
  [TargetingOptionIds.entity]: 'entity',
  [TargetingOptionIds.emotions]: 'emotions',
  [TargetingOptionIds.personas]: 'personas',
  [TargetingOptionIds.sensitiveSubjects]: 'sensitive-subjects',
  [TargetingOptionIds.keywords]: 'keywords',
  intentPrompt: 'intentPrompt',
} as const;

export const targetingOptions = [
  {
    id: TargetingOptionIds.categories,
    title: 'segment.targetingOptions.intent',
    path: TargetingPaths.categories,
    component: Categories,
  },
  {
    id: TargetingOptionIds.personas,
    title: 'segment.targetingOptions.personas',
    path: TargetingPaths.personas,
    component: Personas,
  },
  {
    id: TargetingOptionIds.entity,
    title: 'segment.targetingOptions.entity',
    path: TargetingPaths.entity,
    component: Entity,
  },
  {
    id: TargetingOptionIds.sentiments,
    title: 'segment.targetingOptions.sentiment',
    path: TargetingPaths.sentiments,
    component: Sentiment,
  },
  {
    id: TargetingOptionIds.emotions,
    title: 'segment.targetingOptions.emotions',
    path: TargetingPaths.emotions,
    component: Emotions,
  },
  {
    id: TargetingOptionIds.languages,
    title: 'segment.targetingOptions.language',
    path: TargetingPaths.languages,
    component: Language,
  },
  {
    id: TargetingOptionIds.sensitiveSubjects,
    title: 'segment.targetingOptions.sensitiveSubjects',
    path: TargetingPaths.sensitiveSubjects,
    component: SensitiveSubjects,
    isDisabled: true,
  },
  {
    id: TargetingOptionIds.keywords,
    title: 'segment.targetingOptions.keywords',
    path: TargetingPaths.keywords,
    component: Keywords,
  },
  {
    id: TargetingOptionIds.keywords,
    title: 'segment.targetingOptions.intentPrompt',
    path: TargetingPaths.intentPrompt,
    component: Keywords,
  },
];
