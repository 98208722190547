import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notificationService } from 'modules/notifications';

import { notifyError } from 'services/logService';

import { usersListApiSlice } from '../list/usersListApiSlice';
import { RoleAssign, UserRequestValues, UserState } from '../types';
import { UserListItem } from '../userApiTypes';
import { getAssignRole, getUser } from '../userSelectors';
import { userApi } from '../usersApi';
import { saveUserFailure, saveUserSuccess } from '../usersSlice';

export function* saveUserSaga() {
  try {
    const user: UserState = yield select(getUser);
    const assignRole: RoleAssign = yield select(getAssignRole);
    const userData: UserRequestValues = {
      name: user.name,
      surname: user.surname,
      email: user.email,
      labels: user.labels.filter((label) => label.trim() !== ''),
      teamId: user.teamId,
      companyId: user.companyId,
    };

    const currentRole: RoleAssign = {
      companies: assignRole.companies,
      teams: assignRole.teams,
      roles: assignRole.roles,
    };

    const response: UserListItem = yield call(userApi.saveUser, userData);

    yield call(userApi.assignRole, response.id, currentRole);
    notificationService.showSuccess({
      key: 'userFormSuccess',
      message: i18next.t('adminPanel.user.success.userSaveSuccess'),
    });
    yield put(saveUserSuccess(response));
    yield put(usersListApiSlice.util.invalidateTags(['Users']));
  } catch (e) {
    notifyError(e);

    yield put(
      saveUserFailure({
        message: i18next.t('adminPanel.user.errors.save'),
      }),
    );
  }
}
