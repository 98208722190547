import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Button } from '../common';
import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface ConfirmationDialogProps extends DialogBaseProps {
  title: string;
  subTitle: string;
  onDisagree?: () => void;
  onAgree?: () => void;
}

export const ConfirmationDialog = ({
  open,
  title,
  subTitle,
  onClose,
  onAgree,
  onDisagree,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  const handleDeclineClick = useCallback(() => {
    onClose();

    if (onDisagree) {
      onDisagree();
    }
  }, [onClose, onDisagree]);

  const handleConfirmClick = useCallback(() => {
    onClose();

    if (onAgree) {
      onAgree();
    }
  }, [onClose, onAgree]);

  return (
    <NanoDialog
      open={open}
      title={title}
      footer={
        <div className="flex gap-4">
          <Button variant="outline" onClick={handleDeclineClick}>
            {t('common.no')}
          </Button>
          <Button onClick={handleConfirmClick}>{t('common.yes')}</Button>
        </div>
      }
      width={616}
    >
      <Text>{subTitle}</Text>
    </NanoDialog>
  );
};
