import classNames from 'classnames';
import { X } from 'feather-icons-react';
import { ReactNode } from 'react';

import { IconButton, Modal, ModalProps, Title } from 'components/common';

type OmitModalProps = Omit<
  ModalProps,
  | 'closeIcon'
  | 'footer'
  | 'onCancel'
  | 'onOk'
  | 'okType'
  | 'okText'
  | 'okButtonProps'
  | 'cancelButtonProps'
  | 'cancelText'
  | 'title'
>;

interface NanoDialogProps extends OmitModalProps {
  title?: string;
  footer?: ReactNode;
  onClose?: () => void;
}

export const NanoDialog = ({
  children,
  title,
  footer,
  onClose,
  className,
  ...rest
}: NanoDialogProps) => {
  return (
    <Modal closeIcon={false} footer={null} wrapClassName="nano-dialog" {...rest}>
      {Boolean(onClose) && (
        <IconButton
          className="absolute right-[8px] top-[8px]"
          size="small"
          variant="text"
          onClick={onClose}
        >
          <X size={16} />
        </IconButton>
      )}
      <div
        className={classNames(
          'px-10 pb-4',
          {
            'pb-6': !footer,
          },
          className,
        )}
      >
        <Title className="mb-8" level={3}>
          {title}
        </Title>
        {children}
      </div>
      {footer && (
        <div className="flex flex-row justify-end border-t-[1px] border-t-primary-light-purple-40 px-10 py-6">
          {footer}
        </div>
      )}
    </Modal>
  );
};
