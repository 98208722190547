import { Language, ManualStepValues, SspOptionTypes } from 'modules/segment/types';

import { TargetCombinationsTypes } from './segmentApiTypes';

export const splitBySeparator = (value: string = '', separator: string | RegExp): string[] => {
  return value
    .split(separator)
    .map((s) => s.trim())
    .filter(Boolean);
};

export const deduplicateLanguages = (languages: Language[]) => {
  const languagesTuple: [string, Language][] = languages.map((item) => [item['iso'], item]);

  return [...new Map(languagesTuple).values()];
};

export const transformBucketsToTargetCombinations = (buckets: ManualStepValues[]) =>
  buckets.map(({ keywords, categories, sentiments, languages, entity, personas, emotions }) => ({
    targets: [
      transformKeywords(keywords),
      {
        type: TargetCombinationsTypes.categories,
        exclude: !categories.isIncluded,
        categories: categories.categoriesValues,
      },
      {
        type: TargetCombinationsTypes.sentiment,
        exclude: !sentiments.isIncluded,
        sentiment: {
          min: sentiments.sentimentsValue.min,
          max: sentiments.sentimentsValue.max,
        },
      },
      {
        type: TargetCombinationsTypes.languages,
        exclude: !languages.isIncluded,
        languages: languages.languagesValues,
      },
      {
        type: TargetCombinationsTypes.keywordEntities,
        exclude: !entity.isIncluded,
        keywordEntities: entity.entity.map((specValue) => ({
          keyword: specValue.keyword,
          // eslint-disable-next-line camelcase
          entity_type: specValue.entity_type,
        })),
      },
      {
        type: TargetCombinationsTypes.customCategories,
        exclude: !personas.isIncluded,
        customCategories: personas.personasValues,
      },
      {
        type: TargetCombinationsTypes.emotions,
        exclude: !emotions.isIncluded,
        emotions: emotions.emotionsValues,
      },
    ],
  }));

export function transformKeywords(keywords: ManualStepValues['keywords']) {
  return {
    type: keywords.intentPrompt
      ? TargetCombinationsTypes.intentPrompt
      : TargetCombinationsTypes.keywords,
    exclude: !keywords.isIncluded,
    keywords: [...new Set(joinAllKeywords(keywords))],
  };
}

export function joinAllKeywords(keywords: ManualStepValues['keywords'] | undefined) {
  if (!keywords) {
    return [];
  }
  const { keywordsValues, uploadedValues } = keywords;

  const allKeywords = [
    ...keywordsValues,
    ...uploadedValues.reduce((acc: string[], { values }) => acc.concat(values), []),
  ];

  return [...new Set(allKeywords)];
}

export const getSspType = ({ async, realtime }: { async: boolean; realtime: boolean }) => {
  if (async === true && realtime === false) {
    return SspOptionTypes.rulesBased;
  }

  if (async === false && realtime === true) {
    return SspOptionTypes.liveBid;
  }

  return null;
};

export function reduceIntentId(id: string): string {
  return `${id.slice(0, 4)}....${id.slice(-4)}`;
}

export function splitIntentId(id: string): string {
  const results = id.match(/[A-Za-z0-9]{4}/g);

  return results?.join(' ') ?? '';
}
