import { useTranslation } from 'react-i18next';

import { Title } from 'components/common';

import { DistanceCheckerInnerPage } from '../../../modules/adminPanel/distanceChecker/DistanceCheckerInnerPage';

export const DistanceCheckerPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <header>
        <div className="flex flex-row items-center">
          <Title level={2}>{t('adminPanel.distanceChecker.mainPageHeading')}</Title>
        </div>
      </header>

      <DistanceCheckerInnerPage />
    </>
  );
};
