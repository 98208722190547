import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer } from 'redux/reducers';
import { nanoApi } from 'redux/rootApiSlice';
import { rootSaga } from 'redux/rootSaga';

import { openDialog } from 'components/dialog/dialogSlice';

import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';
import { segmentSaveMiddleware } from 'modules/segment/segmentSaveMiddleware';

import { notifyError } from 'services/logService';

const sagaMiddleware = createSagaMiddleware();

function createAppStore() {
  const reducer = createRootReducer();

  const middlewares = [
    sagaMiddleware,
    segmentSaveMiddleware,
    nanoApi.middleware,
    nanoAdminPanelApi.middleware,
  ];

  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [openDialog.type],
          ignoredPaths: [/^dialog\.list\./],
        },
      }).concat(middlewares),
  });
}

export const store = createAppStore();
sagaMiddleware
  .run(rootSaga)
  .toPromise()
  .catch((error) => {
    notifyError(error);
  });

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
