import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { NavigateFunction } from 'react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES } from 'routing/constants';

import { handleErrorSaga } from './handleErrorSaga';
import { keywordGroupApi } from './keywordGroupApi';
import { KeywordGroupPayload, KeywordGroupResponse } from './keywordGroupApiTypes';
import {
  fetchKeywordGroupStart,
  fetchKeywordGroupSuccess,
  fetchKeywordGroupFailure,
  updateKeywordGroupStart,
  postKeywordGroupSuccess,
  updateKeywordGroupSuccess,
  postKeywordGroupStart,
  deleteKeywordGroupSuccess,
  deleteKeywordGroupStart,
  setKeywordGroupDeleteId,
  copyKeywordGroupStart,
  updateKeywordGroupFailure,
  postKeywordGroupFailure,
  deleteKeywordGroupFailure,
} from './keywordGroupSlice';
import { keywordGroupListApiSlice } from './list/keywordGroupListApiSlice';
import { copyKeywordGroupSaga } from './sagas/copyKeywordGroupSaga';

function* handleFetchKeywordGroup(action: PayloadAction<{ id: string; isLoading: boolean }>) {
  try {
    const response: KeywordGroupResponse = yield call(
      keywordGroupApi.getKeywordGroupById,
      action.payload.id,
    );

    yield put(fetchKeywordGroupSuccess(response));
  } catch (error) {
    yield handleErrorSaga(
      error,
      fetchKeywordGroupFailure,
      i18next.t('adminPanel.keywordGroup.errors.keywordGroupDetailsFetchFailed'),
    );
  }
}

function* postKeywordGroupSaga(
  action: PayloadAction<{ id: string; data: KeywordGroupPayload; navigate: NavigateFunction }>,
) {
  try {
    const { data, navigate } = action.payload;
    const response: KeywordGroupResponse = yield call(keywordGroupApi.saveKeywordGroup, data);

    yield put(postKeywordGroupSuccess(response));
    yield put(keywordGroupListApiSlice.util.invalidateTags(['KeywordGroups']));

    const newPath = ROUTES.KEYWORD_GROUP;

    navigate(newPath, { replace: true, state: { noNavigation: true } });
  } catch (error) {
    yield handleErrorSaga(
      error,
      postKeywordGroupFailure,
      i18next.t('adminPanel.keywordGroup.errors.keywordGroupSaveFailed'),
    );
  }
}

function* handleUpdateKeywordGroup(
  action: PayloadAction<{ id: string; data: KeywordGroupPayload; navigate: NavigateFunction }>,
) {
  try {
    const { id, data, navigate } = action.payload;
    const response: KeywordGroupResponse = yield call(keywordGroupApi.updateKeywordGroup, id, data);

    yield put(updateKeywordGroupSuccess(response));
    yield put(keywordGroupListApiSlice.util.invalidateTags(['KeywordGroups']));

    const newPath = ROUTES.KEYWORD_GROUP;

    navigate(newPath, { replace: true, state: { noNavigation: true } });
  } catch (error) {
    yield handleErrorSaga(
      error,
      updateKeywordGroupFailure,
      i18next.t('adminPanel.keywordGroup.errors.keywordGroupSaveFailed'),
    );
  }
}

function* handleDeleteKeywordGroup(
  action: PayloadAction<{ id: string; data: KeywordGroupPayload }>,
) {
  const { id } = action.payload;

  yield put(setKeywordGroupDeleteId(id));
  try {
    yield call(keywordGroupApi.deleteKeywordGroup, id);
    yield put(keywordGroupListApiSlice.util.invalidateTags(['KeywordGroups']));
    yield put(deleteKeywordGroupSuccess());
  } catch (error) {
    yield handleErrorSaga(
      error,
      deleteKeywordGroupFailure,
      i18next.t('adminPanel.keywordGroup.errors.keywordGroupDeleteFailed'),
    );
  }
}

export function* keywordGroupSaga() {
  yield takeLatest(fetchKeywordGroupStart.type, handleFetchKeywordGroup);
  yield takeLatest(updateKeywordGroupStart.type, handleUpdateKeywordGroup);
  yield takeLatest(postKeywordGroupStart.type, postKeywordGroupSaga);
  yield takeLatest(deleteKeywordGroupStart.type, handleDeleteKeywordGroup);
  yield takeLatest(copyKeywordGroupStart.type, copyKeywordGroupSaga);
}
