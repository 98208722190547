import { Checkbox as CheckboxAntd, CheckboxProps } from 'antd';
import { CheckboxGroupProps as CheckboxGroupPropsAntd } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import { forwardRef, memo, useCallback } from 'react';

type CheckboxGroupProps = {
  onChange: (value: string[]) => void;
};

export const CheckboxGroup = memo(
  ({
    children,
    className,
    onChange,
    ...rest
  }: Omit<CheckboxGroupPropsAntd, 'onChange'> & CheckboxGroupProps) => {
    const handleChange = useCallback(
      (values: CheckboxValueType[]) => onChange(values as string[]),
      [onChange],
    );

    return (
      <CheckboxAntd.Group className={classNames(className)} onChange={handleChange} {...rest}>
        {children}
      </CheckboxAntd.Group>
    );
  },
);

CheckboxGroup.displayName = 'CheckboxGroup';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, className, ...rest }: CheckboxProps, ref) => (
    <div className="checkbox-wrapper">
      <CheckboxAntd className={classNames(className, 'nano-checkbox')} {...rest} ref={ref}>
        {children}
      </CheckboxAntd>
    </div>
  ),
);

Checkbox.displayName = 'Checkbox';
