import i18next from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';

import { formatDateWithTime } from 'services/dateUtils';
import { notifyError } from 'services/logService';

import { customCategoriesApi } from '../customCategoriesApi';
import { customCategoriesListApiSlice } from '../list/customCategoriesListApiSlice';
import { CustomCategoryResponse } from '../types';
import {
  createEmptyCustomCategory,
  createEmptyCustomCategoryFailure,
  createEmptyCustomCategorySuccess,
} from './emptyCustomCategorySlice';

function* createEmptyCustomCategorySaga() {
  const customCategoryData = {
    name: `New Custom Category ${formatDateWithTime(new Date())}`,
    description: 'description',
    keywordGroups: [],
  };

  try {
    const response: CustomCategoryResponse = yield call(
      customCategoriesApi.saveCustomCategory,
      customCategoryData,
    );

    yield put(createEmptyCustomCategorySuccess(response.id));
    yield put(customCategoriesListApiSlice.util.invalidateTags(['CustomCategories']));
  } catch (e) {
    notifyError(e);
    yield put(
      createEmptyCustomCategoryFailure({
        message: i18next.t('adminPanel.customCategories.errors.emptyCustomCategoryCreationFailed'),
      }),
    );
  }
}

export function* emptyCustomCategorySagas() {
  yield takeLatest(createEmptyCustomCategory.type, createEmptyCustomCategorySaga);
}
