import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DistanceCheckerRequest, DistanceCheckerResponse } from './distanceCheckerApiTypes';
import { DistanceCheckerState } from './types';

export const DISTANCE_CHECKER_SLICE_NAME = 'distance_checker';

const initialState: DistanceCheckerState = {
  url: '',
  segmentId: '',
  categories: [],
  subCategories: [],
  language: null,
  status: null,
  contentValidDays: null,
  accuracy: null,
  table: [],

  isLoading: false,
  failMessage: null,
};

export const distanceCheckerSlice = createSlice({
  name: DISTANCE_CHECKER_SLICE_NAME,
  initialState,
  reducers: {
    checkDistanceStart: (
      state: DistanceCheckerState,
      { payload }: PayloadAction<DistanceCheckerRequest>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: true },
      };
    },
    checkDistanceSuccess: (
      state: DistanceCheckerState,
      { payload }: PayloadAction<DistanceCheckerResponse>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: false },
      };
    },
    checkDistanceFailure: (
      state: DistanceCheckerState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.failMessage = payload.message;
    },
  },
});

export const { checkDistanceStart, checkDistanceSuccess, checkDistanceFailure } =
  distanceCheckerSlice.actions;

export const distanceCheckerReducer = distanceCheckerSlice.reducer;
