import { Plus } from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Title } from 'components/common';

import { ROUTES } from 'routing/constants';

export const NoData = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[300px] flex-col items-center justify-center">
      <Title level={3} className="text-primary-dark-purple-40">
        {t('adminPanel.common.noDataText')}
      </Title>
      <Link to={ROUTES.USER}>
        <Button variant="primary">
          <span>{t('adminPanel.user.newUser')}</span> <Plus className="ml-2" />
        </Button>
      </Link>
    </div>
  );
};
