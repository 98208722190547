import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DistanceCheckerGeneralInfo } from './DistanceCheckerGeneralInfo';
import { DistanceCheckerScrapeUrlInfo } from './DistanceCheckerScrapeUrlInfo';
import { DistanceCheckerTable } from './DistanceCheckerTable';
import { getDistanceCheckerState } from './distanceCheckerSelectors';
import { WeightedCategoriesChart } from './scoredCategoriesChart/ScoredCategoreisChart';

export const DistanceCheckerList = () => {
  const { t } = useTranslation();

  const { table, categories, subCategories, language, status, accuracy, contentValidDays } =
    useSelector(getDistanceCheckerState);

  return (
    <div className="bborder border-nano-dark-purple flex flex-col jjustify-between gap-6 mt-10">
      <DistanceCheckerTable dataSource={table} />

      <DistanceCheckerGeneralInfo
        language={language}
        status={status}
        contentValidDays={contentValidDays}
        accuracy={accuracy}
      />

      <div className="flex flex-row gap-6">
        <div className=" w-full flex-col">
          <WeightedCategoriesChart
            data={categories}
            title={t('adminPanel.distanceChecker.charts.scoredCategories.catTitle')}
          />
        </div>
        <div className="w-full">
          <WeightedCategoriesChart
            data={subCategories}
            title={t('adminPanel.distanceChecker.charts.scoredCategories.subCatTitle')}
          />
        </div>
      </div>

      <DistanceCheckerScrapeUrlInfo />
    </div>
  );
};
