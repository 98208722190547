import { api } from 'services/api';

import { CompanyListItem, CompanyRequestData } from './companyApiTypes';
import { COMPANIES_URL } from './constants';

const getCompanies = () => {
  return api.get(COMPANIES_URL);
};

const getCompanyById = (id: string) => {
  return api.get<CompanyListItem>(`${COMPANIES_URL}/${id}`);
};

const saveCompany = (data: CompanyRequestData) => {
  return api.post(COMPANIES_URL, data);
};

const updateCompany = (id: string, data: CompanyRequestData) => {
  return api.put(`${COMPANIES_URL}/${id}`, data);
};

const deleteCompany = (id: string) => {
  return api.delete(`${COMPANIES_URL}/${id}`);
};

export const companyApi = {
  getCompanies,
  getCompanyById,
  saveCompany,
  updateCompany,
  deleteCompany,
};
