export const TAG_WRAPPING_SINGLE_ROUTE = '/tag-wrap';
export const TAG_WRAPPING_BULK_ROUTE = '/tag-wrap/file';

export const usedDSPs = [
  {
    name: 'Xandr Invest',
    key: 'XandrInv',
  },
  {
    name: 'DV360',
    key: 'DV360',
  },
  {
    name: 'The Trade Desk',
    key: 'TD',
  },
];
