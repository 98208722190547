import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SegmentGroupCreateValues, SegmentGroupState } from './types';

export const SEGMENT_GROUP_SLICE_NAME = 'segmentGroup';

export const initialState: SegmentGroupState = {
  id: '',
  groupName: '',
  ssp: '',
  sspGroupId: '',
  price: null,
  creationDate: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  segmentGroupToDeleteId: '',
  success: null,
  error: null,
};

export const segmentGroupSlice = createSlice({
  name: SEGMENT_GROUP_SLICE_NAME,
  initialState,
  reducers: {
    saveSegmentGroup: (state: SegmentGroupState) => {
      state.isLoading = true;
    },
    updateValues: (
      state: SegmentGroupState,
      { payload }: PayloadAction<SegmentGroupCreateValues>,
    ) => {
      return {
        ...state,
        ...payload,
      };
    },
    saveSegmentGroupFailure: (
      state: SegmentGroupState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.error = payload.message;
    },
    saveSegmentGroupSuccess: (state: SegmentGroupState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
      state.creationDate = payload.creationDate;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSegmentGroup: (state: SegmentGroupState, { payload }: PayloadAction<{ id: string }>) => {
      state.isLoading = true;
    },
    deleteSegmentGroupSuccess: (state: SegmentGroupState) => {
      state.isLoading = false;
      state.segmentGroupToDeleteId = '';
      state.isCreated = false;
    },
    deleteSegmentGroupFailure: (
      state: SegmentGroupState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
      state.error = payload.message;
    },
    setSegmentGroupToDeleteId: (state: SegmentGroupState, { payload }: PayloadAction<string>) => {
      state.segmentGroupToDeleteId = payload;
    },
    clearSegmentGroup: () => initialState,
    updateSegmentGroup: (state: SegmentGroupState) => {
      state.isLoading = true;
      state.success = null;
      state.error = null;
    },
    updateSegmentGroupSuccess: (state: SegmentGroupState) => {
      state.isLoading = false;
      state.isCreated = true;
      state.success = 'success';
    },
    updateSegmentGroupFailure: (
      state: SegmentGroupState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
      state.error = payload.message;
    },
    setSegmentGroupToUpdateId: (state: SegmentGroupState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
  },
});

export const {
  updateValues,
  saveSegmentGroupFailure,
  saveSegmentGroup,
  saveSegmentGroupSuccess,
  deleteSegmentGroup,
  deleteSegmentGroupSuccess,
  deleteSegmentGroupFailure,
  setSegmentGroupToDeleteId,
  clearSegmentGroup,
  updateSegmentGroup,
  updateSegmentGroupSuccess,
  updateSegmentGroupFailure,
  setSegmentGroupToUpdateId,
} = segmentGroupSlice.actions;

export const segmentGroup = segmentGroupSlice.reducer;
