import { CompanyInfoForm } from 'modules/adminPanel/company/forms/companyInfoForm';

import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface CompanyFormDialogProps extends DialogBaseProps {
  title: string;
  id?: string;
  name?: string;
  address?: string;
}

export const EditCompanyFormDialog = ({
  open,
  title,
  onClose,
  id,
  address,
  name,
}: CompanyFormDialogProps) => {
  return (
    <NanoDialog open={open} title={title} width={616}>
      <CompanyInfoForm onClose={onClose} id={id} name={name} address={address} />
    </NanoDialog>
  );
};
