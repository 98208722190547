import { GroupingMode, GroupingModes } from '../types';
import { UserListItem } from '../userApiTypes';

export const NO_PARENT_ID = 'no_parent';

type Dictionary = Record<string, UserListItem[]>;

const MAX_USERS_COUNT_IN_GROUP = 10;

export const groupByField = function (users: UserListItem[], groupingMode: GroupingMode) {
  const groupByParentId = () => {
    const usersWithCompanyName: UserListItem[] = [];
    const usersWithoutCompanyName: UserListItem[] = [];

    users.forEach((user) => {
      if (user.companyId !== NO_PARENT_ID) {
        usersWithCompanyName.push(user);
      } else if (user.companyId === NO_PARENT_ID) {
        usersWithoutCompanyName.push(user);
      }
    });

    const recordWithCompany = groupUserByCompanyName(usersWithCompanyName);
    const recordWithoutCompany = groupUserByCompanyName(usersWithoutCompanyName);

    const preparedRecordsWithCompany = prepareRecords(recordWithCompany);
    const preparedRecordsWithoutCompany = prepareRecords(recordWithoutCompany);

    return [...preparedRecordsWithCompany, ...preparedRecordsWithoutCompany];
  };

  return groupingMode === GroupingModes.COMPANY ? groupByParentId() : [];
};

const groupUserByCompanyName = (users: UserListItem[]) => {
  return users.reduce(
    (acc, val) => {
      const { companyId } = val;

      if (!acc[companyId]) {
        acc[companyId] = [val];
      } else {
        acc[companyId].push(val);
      }

      return acc;
    },
    {} as Record<string, UserListItem[]>,
  );
};

export const filterByCompanyNames = (users: UserListItem[], companyIds: string[]) => {
  return users.filter((user) => {
    const segmentBelongsToOneOfTeams = companyIds.some((id) => {
      if (user.companyId === null && id === 'null') {
        return true;
      }

      return user.companyId === id;
    });

    return segmentBelongsToOneOfTeams && user;
  });
};

const prepareRecords = (record: Dictionary) => {
  return Object.entries(record).map(([companyName, childUsers], index) => {
    const id = `${companyName.toLowerCase()}-${index}`;

    return {
      id,
      name: companyName,
      showSeeAllLink: childUsers.length > MAX_USERS_COUNT_IN_GROUP,
      description: '',
      companyName,
      key: id,
      children: childUsers.slice(0, MAX_USERS_COUNT_IN_GROUP).map((child, childIndex) => ({
        ...child,
        parentIndex: index,
        isFirst: childIndex === 0,
        isLast: childIndex === MAX_USERS_COUNT_IN_GROUP - 1,
      })),
    };
  });
};
