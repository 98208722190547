import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getCompareValues,
  getSingleLineLoading,
} from 'modules/adminPanel/auditLog/auditLogSelectors';

import { Spin } from '../common';
import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface AuditLogCompareDialogProps extends DialogBaseProps {
  title: string;
}

export const AuditLogCompareDialog = ({ open, title, onClose }: AuditLogCompareDialogProps) => {
  const { t } = useTranslation();
  const isLoading = useSelector(getSingleLineLoading);
  const compareValues = useSelector(getCompareValues);

  const previousData = compareValues.previous
    ? JSON.parse(compareValues.previous)
    : compareValues.previous;
  const newData = compareValues.new ? JSON.parse(compareValues.new) : compareValues.new;

  return (
    <NanoDialog
      open={open}
      title={title}
      width={1200}
      bodyStyle={{ height: '500px' }}
      onClose={onClose}
    >
      <div className="h-[400px] overflow-y-auto">
        {isLoading ? (
          <Spin className="w-full h-full flex justify-center items-center mr-2.5 mt-0.5" />
        ) : (
          <div style={{ width: '100%', overflowWrap: 'anywhere' }}>
            <ReactDiffViewer
              oldValue={JSON.stringify(previousData, null, 2)}
              newValue={JSON.stringify(newData, null, 2)}
              splitView={true}
              showDiffOnly={false}
              hideLineNumbers={false}
              disableWordDiff={true}
              useDarkTheme={false}
              leftTitle={t('adminPanel.auditLog.logCompare.oldData')}
              rightTitle={t('adminPanel.auditLog.logCompare.newData')}
            />
          </div>
        )}
      </div>
    </NanoDialog>
  );
};
