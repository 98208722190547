import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { CustomCategoriesListItem, KeywordGroupsItem } from '../customCategoriesApiTypes';

export const customCategoriesListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    customCategoriesList: build.query<CustomCategoriesListItem[], null>({
      query: () => ({ method: 'GET', url: 'custom-categories/getAll' }),
      providesTags: [{ type: 'CustomCategories', id: 'customCategoriesList' }],
    }),
    keywordGroupsList: build.query<KeywordGroupsItem[], null>({
      query: () => ({ method: 'GET', url: '/keyword-group/getAll' }),
      providesTags: [{ type: 'CustomCategories', id: 'keywordGroupsList' }],
    }),
  }),
});

export const {
  useCustomCategoriesListQuery,
  useKeywordGroupsListQuery,
  usePrefetch: useApiCustomCategoryDataSlicePrefetch,
} = customCategoriesListApiSlice;
