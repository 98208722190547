import { AppState } from 'redux/store/store';

import { labelsSlice } from './labelsSlice';

const labelsSelector = ({ labels }: AppState) => labels;

export const getLabelsData = (state: AppState) => labelsSelector(state).labels;

export const getLabelsLoading = (state: AppState) => labelsSelector(state).isLoading;

export const {
  addLabelToSegment,
  removeLabelFromSegment,
  updateSegmentLabels,
  getLabels,
  getLabelsSuccess,
  getLabelsFailure,
  createNewLabel,
  deleteLabel,
} = labelsSlice.actions;
