import classNames from 'classnames';
import { memo, useCallback, useRef, useState } from 'react';

import { IconButton } from '../button';
import { Carousel, CarouselProps, CarouselRef } from '../carousel';
import { LongArrowIcon } from '../icons';

interface NanoCarouselProps extends Omit<CarouselProps, 'dots'> {
  showArrows?: boolean;
}

export const NanoCarousel = memo(({ showArrows = true, children, ...rest }: NanoCarouselProps) => {
  const carouselRef = useRef<CarouselRef>(null);
  const [navState, setNavState] = useState<number>(0);

  const isDisabledPrev = navState === 0;
  const isDisabledNext = navState === (children as Array<Element>).length - 1;

  const handlePrevClick = useCallback(() => {
    carouselRef.current?.prev();
  }, [carouselRef]);

  const handleNextClick = useCallback(() => {
    carouselRef.current?.next();
  }, [carouselRef]);

  return (
    <>
      <Carousel
        className="nano-carousel"
        dots={false}
        ref={carouselRef}
        afterChange={(current: number) => setNavState(current)}
        {...rest}
      >
        {children}
      </Carousel>
      {showArrows && (
        <div className="flex flex-row justify-end pr-[81px]">
          <IconButton
            disabled={isDisabledPrev}
            className="mr-5"
            variant="text"
            onClick={isDisabledPrev ? () => null : handlePrevClick}
          >
            <LongArrowIcon
              className={classNames('rotate-180', {
                'text-text-40 cursor-default': isDisabledPrev,
              })}
            />
          </IconButton>
          <IconButton
            disabled={isDisabledNext}
            variant="text"
            onClick={isDisabledNext ? () => null : handleNextClick}
          >
            <LongArrowIcon
              className={classNames({ 'text-text-40 cursor-default': isDisabledNext })}
            />
          </IconButton>
        </div>
      )}
    </>
  );
});

NanoCarousel.displayName = 'NanoCarousel';
