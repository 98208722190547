const labelColors = [
  { background: 'processing', font: '#1677ff' },
  { background: 'success', font: '#52c41a' },
  { background: 'error', font: '#ff4d4f' },
  { background: 'warning', font: '#faad14' },
  { background: 'magenta', font: '#c41d7f' },
  { background: 'red', font: '#cf1322' },
  { background: 'volcano', font: '#d4380d' },
  { background: 'orange', font: '#d46b08' },
  { background: 'gold', font: '#d48806' },
  { background: 'lime', font: '#7cb305' },
  { background: 'green', font: '#389e0d' },
  { background: 'cyan', font: '#08979c' },
  { background: 'blue', font: '#0958d9' },
  { background: 'geekblue', font: '#1d39c4' },
  { background: 'purple', font: '#531dab' },
];

export const getLabelColorByIndex = (index: number) => labelColors[index % labelColors.length];
