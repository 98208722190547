import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import sadEmoji from 'assets/images/emojis/sadness.png';

import { Button, Title } from 'components/common';

export const ErrorPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className="page-background flex h-screen items-center justify-center">
      <div className="relative flex h-[250px] max-w-[620px] flex-col items-center rounded-3xl bg-secondary-white p-10 shadow-card-shadow">
        <img className="absolute inset-x-72 -top-[65px] w-[125px]" src={sadEmoji} />
        <Title level={3} className="mb-2 pt-12 font-semibold">
          {t('errorBoundaryTitle')}
        </Title>
        <Link to="/">
          <Button variant="primary" size="medium" className="mt-6">
            {t('links.homepage')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
