import { useCallback } from 'react';

import { DialogNames, useDialog, DialogBaseProps } from 'components/dialog';
import { ConfirmationDialogProps } from 'components/dialogs/ConfirmationDialog';

type UseConfirmationDetails = Omit<
  ConfirmationDialogProps,
  keyof DialogBaseProps | 'onAgree' | 'onDisagree'
>;

export const useConfirmationCallback = (
  onAgree: () => void,
  confirmDetails: UseConfirmationDetails,
  onDisagree?: () => void,
) => {
  const [showConfirmationDialog] = useDialog(DialogNames.CONFIRMATION_DIALOG);

  return useCallback(() => {
    showConfirmationDialog({
      ...confirmDetails,
      onAgree,
      onDisagree,
    });
  }, [confirmDetails, onAgree, onDisagree, showConfirmationDialog]);
};
