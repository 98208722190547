import classNames from 'classnames';
import { useTimeout, useToggle } from 'usehooks-ts';

import { Title } from 'components/common';

import { nanoTwMerge } from 'services/twMerge';

const SPLASH_SCREEN_TIMEOUT = 3500;

const isDev = process.env.NODE_ENV === 'development';

export const SplashScreen = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = window.location;
  const displaySplashScreen = !isDev && pathname === '/welcome';
  const [splashScreenVisible, toggleSplashScreen] = useToggle(displaySplashScreen);

  useTimeout(() => {
    if (displaySplashScreen) {
      toggleSplashScreen();
    }
  }, SPLASH_SCREEN_TIMEOUT);

  const splashWrapperClasses = nanoTwMerge(
    classNames('splash-screen-wrapper', {
      'opacity-0': !splashScreenVisible,
    }),
  );

  return (
    <>
      <div className={splashWrapperClasses}>
        <div className="text-container">
          <Title className="text-secondary-pink leading-9">Intent</Title>
          <Title className="text-primary-electric-cyan-100 leading-9">Over</Title>
          <Title className="text-secondary-white mt-2 leading-9">Identity</Title>
        </div>
      </div>
      <div className={classNames({ 'overflow-hidden h-screen': splashScreenVisible })}>
        {children}
      </div>
    </>
  );
};
