import { IconProps } from 'feather-icons-react';

export const PptxIcon = ({ className, fill = 'currentColor', size = 32 }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.33334C6.93913 1.33334 5.92172 1.75477 5.17157 2.50492C4.42143 3.25506 4 4.27248 4 5.33334V26.6667C4 27.7275 4.42143 28.745 5.17157 29.4951C5.92172 30.2452 6.93913 30.6667 8 30.6667H24C25.0609 30.6667 26.0783 30.2452 26.8284 29.4951C27.5786 28.745 28 27.7275 28 26.6667V10.6667C28 10.3131 27.8595 9.97392 27.6095 9.72387L19.6095 1.72387C19.3594 1.47382 19.0203 1.33334 18.6667 1.33334H8ZM7.05719 4.39053C7.30724 4.14049 7.64638 4.00001 8 4.00001H17.3333V10.6667C17.3333 11.4031 17.9303 12 18.6667 12H25.3333V26.6667C25.3333 27.0203 25.1929 27.3594 24.9428 27.6095C24.6928 27.8595 24.3536 28 24 28H8C7.64638 28 7.30724 27.8595 7.05719 27.6095C6.80714 27.3594 6.66667 27.0203 6.66667 26.6667V5.33334C6.66667 4.97972 6.80714 4.64058 7.05719 4.39053ZM23.4477 9.33335L20 5.88563V9.33335H23.4477Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2667 13.3333H16.5333C19.083 13.3333 21.0667 15.317 21.0667 17.8667C21.0667 20.4164 19.083 22.4 16.5333 22.4C15.8635 22.4 15.2327 22.2631 14.6667 22.015V25.3333H12V17.8667H14.6667C14.6667 18.9436 15.4564 19.7333 16.5333 19.7333C17.6103 19.7333 18.4 18.9436 18.4 17.8667C18.4 16.7897 17.6103 16 16.5333 16H12.2667V13.3333Z"
      fill={fill}
    />
  </svg>
);
