import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { DialogsListEntry, DialogState } from './types';

const STATE_NAME = 'dialog';

const initialState: DialogState = {
  list: [],
};

export const dialogSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    openDialog(state, { payload }: PayloadAction<DialogsListEntry>) {
      state.list = [...state.list, payload];
    },
    closeDialog(state, { payload }: PayloadAction<string>) {
      state.list = state.list.filter(({ id }) => id !== payload);
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;
export const dialog = dialogSlice.reducer;

export const getDialogs = (state: AppState) => state.dialog ?? initialState;
export const getDialogsList = (state: AppState) => getDialogs(state).list;
export const getDialogsListIds = createDraftSafeSelector(getDialogsList, (list) =>
  list.map(({ id }) => id),
);
export const getDialogById = (id: string) =>
  createDraftSafeSelector(getDialogsList, (list) =>
    list.find(({ id: dialogId }) => dialogId === id),
  );
export const getDialogName = (id: string) =>
  createDraftSafeSelector(getDialogById(id), (dialog) => dialog?.name);
export const getDialogProps = (id: string) =>
  createDraftSafeSelector(getDialogById(id), (dialog) => dialog?.props);
