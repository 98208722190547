import classNames from 'classnames';
import { ChangeEvent, ReactNode, SyntheticEvent, DragEvent, useRef } from 'react';

export interface FileUploadProps {
  accept?: string;
  className?: string;
  children?: ReactNode;
  multiple?: boolean;
  onChange: (files: FileList | null) => void;
}

export const FileUpload = ({
  accept,
  className,
  children,
  multiple,
  onChange,
}: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (inputRef) {
      inputRef.current?.click();
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.files);
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    onChange(e.dataTransfer.files);
  };
  const handleDragOver = (e: SyntheticEvent) => e.preventDefault();

  return (
    <div
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={classNames('nano-file-upload', className)}
    >
      <input
        ref={inputRef}
        type="file"
        onChange={handleChange}
        accept={accept}
        multiple={multiple}
      />
      {children}
    </div>
  );
};
