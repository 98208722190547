import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TagWrappingToolSingleRequest,
  TagWrappingToolSingleResponse,
} from './tagWrappingToolApiTypes';
import { TagWrappingToolState } from './types';

export const TAG_WRAPPING_TOOL_SLICE_NAME = 'tag_wrapping_tool_single';
export const TAG_WRAPPING_TOOL_BULK_SLICE_NAME = 'tag_wrapping_tool_bulk';

const initialState: TagWrappingToolState = {
  name: '',
  size: '',
  adContent: '',
  clickUrl: '',
  advertiserUrl: '',
  dsp: 'XandrInv',
  tagContent: null,

  isLoading: false,
  failMessage: null,
};

export const tagWrappingToolSlice = createSlice({
  name: TAG_WRAPPING_TOOL_SLICE_NAME,
  initialState,
  reducers: {
    wrapSingleTagStart: (
      state: TagWrappingToolState,
      { payload }: PayloadAction<TagWrappingToolSingleRequest>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: true },
      };
    },
    wrapSingleTagSuccess: (
      state: TagWrappingToolState,
      { payload }: PayloadAction<TagWrappingToolSingleResponse>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: false },
      };
    },
    wrapSingleTagFail: (
      state: TagWrappingToolState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.failMessage = payload.message;
    },
  },
});

export const { wrapSingleTagStart, wrapSingleTagSuccess, wrapSingleTagFail } =
  tagWrappingToolSlice.actions;

const tagWrappingToolSingleReducer = tagWrappingToolSlice.reducer;

export const tagWrappingToolReducers = {
  tagWrappingTool: tagWrappingToolSingleReducer,
};
