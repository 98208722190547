export enum NanoBotChatRole {
  User = 'user',
  Assistant = 'assistant',
}
export interface UserMessage {
  [key: string]: string;
}

export interface NanoBotData {
  userMessages: Array<ResponseMessage>;
  message: string;
}

export interface NanoBotState {
  isOpen: boolean;
  messagesHistory: Array<ResponseMessage>;
  currentMessage: string;
  isLoading: boolean;
}

export interface ResponseMessage {
  role: (typeof NanoBotChatRole)[keyof typeof NanoBotChatRole];
  content: string;
}

export interface MessageAction {
  type: string;
  payload: string;
}
