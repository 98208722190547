import { HelpCircle } from 'feather-icons-react';
import { ReactNode } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '../tooltip';

type FieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  error?: string;
  label?: string;
  isOptional?: boolean;
  tooltip?: string;
  className?: string;
};

export const Field = ({
  render,
  name,
  error,
  label,
  isOptional,
  tooltip,
  className,
  ...rest
}: Omit<ControllerProps, 'control'> & FieldProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {label || tooltip ? (
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center">
            {label ? <FieldLabel htmlFor={name}>{label}</FieldLabel> : null}
            {tooltip ? <FieldTooltip tooltip={tooltip} /> : null}
          </div>
          {isOptional ? (
            <FieldOptionalLabel>{`(${t('forms.labels.optional')})`}</FieldOptionalLabel>
          ) : null}
        </div>
      ) : null}
      <Controller render={render} name={name} {...rest} />
      {error ? <FieldError>{error}</FieldError> : null}
    </div>
  );
};

export const FieldLabel = ({ htmlFor, children }: { htmlFor: string; children: ReactNode }) => (
  <label className="text-base leading-6 text-text-80" htmlFor={htmlFor}>
    {children}
  </label>
);

export const FieldTooltip = ({ tooltip }: { tooltip: string }) => (
  <Tooltip placement="top" title={tooltip}>
    <HelpCircle className="ml-1 text-text-60" size={20} />
  </Tooltip>
);

export const FieldOptionalLabel = ({ children }: { children: ReactNode }) => (
  <span className="text-base leading-5 text-text-60">{children}</span>
);

export const FieldError = ({ children }: { children: ReactNode }) => (
  <span className="mt-2 text-base-s leading-4 text-system-error-100">{children}</span>
);
