import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Field, Input, Option, Select, SubmitButton } from 'components/common';

import { useSspQuery } from 'modules/adminPanel/adminPanelApiDataApiSlice';
import {
  saveSegmentGroup,
  setSegmentGroupToUpdateId,
  updateSegmentGroup,
  updateValues,
} from 'modules/adminPanel/segmentGroup';
import { getSegmentGroup } from 'modules/adminPanel/segmentGroup/segmentGroupSelectors';
import { notificationService } from 'modules/notifications';

type SegmentGroupFormProps = {
  id?: string;
  groupName?: string;
  ssp?: string;
  sspGroupId?: string;
  price?: number | null;
  onClose: () => void;
};

export const SegmentGroupForm = ({
  id,
  groupName,
  ssp,
  sspGroupId,
  price,
  onClose,
}: SegmentGroupFormProps) => {
  const { t } = useTranslation();
  const { isLoading, isCreated } = useSelector(getSegmentGroup);
  const dispatch = useDispatch();

  const currentSegmentGroup = useSelector(getSegmentGroup);
  const { data: ssps } = useSspQuery(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { groupName: groupName, ssp: ssp, sspGroupId: sspGroupId, price: price },
  });

  const onSubmit = handleSubmit((data) => {
    dispatch(updateValues(data));
    if (!id) {
      dispatch(saveSegmentGroup());
    } else {
      dispatch(setSegmentGroupToUpdateId(id));
      dispatch(updateSegmentGroup());
    }
  });

  const showSuccessNotification = useCallback(() => {
    notificationService.showSuccess({
      key: 'segmentGroupFormSuccess',
      message: t('adminPanel.segmentGroup.success.segmentGroupSaveSuccess'),
    });
  }, [t]);

  useEffect(() => {
    if (!isLoading && isCreated) {
      showSuccessNotification();
      onClose();
    }
  }, [
    id,
    currentSegmentGroup,
    groupName,
    ssp,
    sspGroupId,
    price,
    isLoading,
    isCreated,
    onClose,
    showSuccessNotification,
  ]);

  return (
    <form className="flex flex-col gap-y-9" onSubmit={onSubmit}>
      <Field
        name="groupName"
        label={t('adminPanel.segmentGroup.dialog.name')}
        control={control}
        className="h-[80px]"
        render={({ field, fieldState: { invalid } }) => (
          <Input
            placeholder={t('adminPanel.segmentGroup.dialog.name')}
            {...field}
            invalid={invalid}
          />
        )}
        rules={{
          required: t('adminPanel.forms.errors.required'),
        }}
        error={errors.groupName?.message}
      />
      <Field
        name="ssp"
        label={t('adminPanel.segmentGroup.dialog.ssp')}
        control={control}
        className="h-[80px]"
        render={({ field }) => (
          <Select placeholder={t('adminPanel.segmentGroup.dialog.ssp')} {...field}>
            {ssps?.map(({ id, key, name }) => (
              <Option key={id} value={key} name={name}>
                {name}
              </Option>
            ))}
          </Select>
        )}
        rules={{
          required: t('adminPanel.forms.errors.required'),
        }}
        error={errors.ssp?.message}
      />
      <Field
        name="sspGroupId"
        label={t('adminPanel.segmentGroup.dialog.sspGroupId')}
        control={control}
        className="h-[80px]"
        render={({ field, fieldState: { invalid } }) => (
          <Input
            placeholder={t('adminPanel.segmentGroup.dialog.sspGroupId')}
            {...field}
            invalid={invalid}
          />
        )}
        rules={{
          required: t('adminPanel.forms.errors.required'),
        }}
        error={errors.sspGroupId?.message}
      />
      <Field
        name="price"
        label={t('adminPanel.segmentGroup.dialog.price')}
        control={control}
        className="h-[80px]"
        render={({ field, fieldState: { invalid } }) => (
          <Input
            placeholder={t('adminPanel.segmentGroup.dialog.price')}
            {...field}
            invalid={invalid}
          />
        )}
        rules={{
          validate: (value) => {
            const numberedValue = Number(value);

            return !isNaN(numberedValue) || t('adminPanel.segmentGroup.dialog.priceValid');
          },
        }}
        error={errors.price?.message}
      />

      <div className="mt-2 flex items-center justify-end gap-x-4">
        <Button type="button" variant="outline" onClick={onClose}>
          {t('adminPanel.company.dialog.cancelButton')}
        </Button>
        <SubmitButton isLoading={isLoading}>
          {t('adminPanel.company.dialog.submitButton')}
        </SubmitButton>
      </div>
    </form>
  );
};
