import { memo } from 'react';

import { Spin } from 'components/common/spin';

import { ButtonProps } from './types';
import { getComputedStyles } from './utils';

export const Button = memo(
  ({
    children,
    className,
    disabled,
    size = 'medium',
    variant = 'primary',
    isLoading,
    ...rest
  }: ButtonProps) => {
    const isDisabled = disabled || isLoading;

    return (
      <button
        className={getComputedStyles({ className, disabled: isDisabled, size, variant })}
        disabled={isDisabled}
        {...rest}
      >
        <>
          {isLoading && <Spin className="mr-2.5 mt-0.5" />}
          {children}
        </>
      </button>
    );
  },
);
Button.displayName = 'Button';
