import { useDispatch, useSelector } from 'react-redux';

import { getCurrentBucket } from 'modules/segment/segmentSelectors';
import {
  addKeywords as addKeywordsAction,
  addUploadedKeywords as addUploadedKeywordsAction,
  clearAllKeywords,
  removeKeyword,
  removeUploadedKeywords as removeUploadedKeywordsAction,
} from 'modules/segment/segmentSlice';
import { joinAllKeywords } from 'modules/segment/utils';

import { FileDescription } from './components/UploadKeywords';

export const useKeywords = () => {
  const dispatch = useDispatch();
  const currentBucket = useSelector(getCurrentBucket);

  const addKeywords = (keywords: string[]) => {
    dispatch(addKeywordsAction(keywords));
  };

  const addUploadedKeywords = (keywords: FileDescription[]) => {
    dispatch(addUploadedKeywordsAction(keywords));
  };

  const removeUploadedKeywords = (key: string) => {
    dispatch(removeUploadedKeywordsAction(key));
  };

  const clearAll = () => {
    dispatch(clearAllKeywords());
  };

  const removeSingleKeyword = (keyword: string) => {
    dispatch(removeKeyword(keyword));
  };

  const uploadedValuesCount = currentBucket.keywords.uploadedValues.reduce(
    (acc, { values }) => acc + values.length,
    0,
  );

  const allKeywords = joinAllKeywords(currentBucket.keywords);

  const isIncluded = currentBucket.keywords?.isIncluded;

  return {
    allKeywords,
    keywordsValues: currentBucket.keywords.keywordsValues,
    manualKeywordsCount: currentBucket.keywords.keywordsValues.length,
    uploadedFiles: currentBucket.keywords.uploadedValues,
    uploadedValuesCount,
    isIncluded,
    addKeywords,
    addUploadedKeywords,
    removeUploadedKeywords,
    clearAll,
    removeSingleKeyword,
  };
};
