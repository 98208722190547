import { all, call, put, takeLatest } from 'redux-saga/effects';

import { localStorageService } from 'services/localStorage';
import { VISIT_KEY } from 'services/localStorage/constants';
import { notifyError } from 'services/logService';
import { getQueryParam, removeQueryParams } from 'services/urlHelpers';

import { authApi } from './authApi';
import { calculateTokenExpirationDate } from './authService';
import {
  exchangeCode,
  exchangeCodeSuccess,
  getTableauToken,
  getTableauTokenFailure,
  getTableauTokenSuccess,
  loginFailure,
  logoutAction,
  ssoRequest,
  ssoRequestFailure,
} from './authSlice';
import { authStorage } from './authStorage';
import { ReportResponse, SSOTokenResponse } from './types';

function* logoutFlow() {
  try {
    yield call(authApi.logout);
    yield call(authStorage.clearAuthInfo);
    window.location.replace('/');
    localStorageService.setItem(VISIT_KEY, true);
  } catch (err) {
    yield put(loginFailure());
  }
}

// this saga is called on app load and checks whether we have code in query params
// if we have it -> exchange it to token and save to localstorage
// token ttl = 24hrs
function* exchangeCodeSaga() {
  const code: string | null = yield call(getQueryParam, 'code');

  if (code) {
    try {
      yield put(exchangeCode());

      const { token, token_duration: tokenTTL }: SSOTokenResponse = yield call(
        authApi.exchangeSSOCode,
        code,
      );

      yield put(exchangeCodeSuccess(token));
      // ttl from backend is in nanoseconds, we convert it to milliseconds to be able to work with Date
      // ttl is 12 hours
      const expiredAt: Date = yield call(calculateTokenExpirationDate, tokenTTL);

      yield call(authStorage.saveAuthInfo, token, expiredAt);
      yield call(removeQueryParams);
    } catch (error) {
      notifyError(error);
    }
  }
}

function* ssoRequestSaga() {
  try {
    const { url }: Record<'url', string> = yield call(authApi.getSSOUrl);

    window.location.replace(url);
  } catch (error) {
    yield put(ssoRequestFailure());
    notifyError(error);
  }
}

function* tableauTokenSaga() {
  try {
    const payload: ReportResponse = yield call(authApi.getTableauToken);

    yield put(getTableauTokenSuccess(payload));
  } catch (error) {
    yield put(getTableauTokenFailure());
    notifyError(error);
  }
}

export function* authFlowSagas() {
  yield all([
    takeLatest(logoutAction.type, logoutFlow),
    exchangeCodeSaga(),
    takeLatest(ssoRequest.type, ssoRequestSaga),
    takeLatest(getTableauToken.type, tableauTokenSaga),
  ]);
}
