export const ScaleImpactArrow = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48L0 24.2158H12.3428C18.3967 24.2158 21.3043 18.708 21.3043 11.9137L24 0L26.6957 11.9137C26.6957 18.708 29.6033 24.2158 35.6572 24.2158H48L24 48Z"
      fill="url(#paint0_linear_5470_23093)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5470_23093"
        x1="12.3765"
        y1="14.4047"
        x2="13.0063"
        y2="63.4084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5F5F4" />
        <stop offset="1" stopColor="#D3BDE2" />
      </linearGradient>
    </defs>
  </svg>
);
