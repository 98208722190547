const ONE_MILLISECOND_IN_NANO_SECONDS = 1000000;
const AUTH_TOKEN_VALIDITY_DURATION = 30 * 60 * 1000; // 30 minutes

export const isTokenExpiresSoon = (expirationDate: string) => {
  return new Date(expirationDate).getTime() - Date.now() < AUTH_TOKEN_VALIDITY_DURATION;
};

export const calculateTokenExpirationDate = (tokenTTL: number) => {
  const ttlInMilliseconds = tokenTTL / ONE_MILLISECOND_IN_NANO_SECONDS;

  return new Date(new Date().getTime() + ttlInMilliseconds);
};
