import { api } from 'services/api';

import { TAG_WRAPPING_BULK_ROUTE, TAG_WRAPPING_SINGLE_ROUTE } from './constants';
import {
  TagWrappingToolSingleRequest,
  TagWrappingToolSingleResponse,
} from './tagWrappingToolApiTypes';

const getSingleWrappedTag = (requestData: TagWrappingToolSingleRequest) => {
  return api.post<TagWrappingToolSingleResponse>(`${TAG_WRAPPING_SINGLE_ROUTE}`, requestData);
};

const getBulkWrappedTags = (requestData: FormData) => {
  return api.post<Blob>(`${TAG_WRAPPING_BULK_ROUTE}`, requestData, { responseType: 'blob' });
};

export const tagWrappingToolApi = {
  getSingleWrappedTag,
  getBulkWrappedTags,
};
