import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Paragraph } from 'components/common';

import { getSentiments } from 'modules/segment/segmentSelectors';
import { toggleSentiment } from 'modules/segment/segmentSlice';
import { SentimentType, SentimentVariant } from 'modules/segment/types';

import { usePreboundCallback } from 'hooks/usePreboundCallback';

export const Sentiment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sentimentsValue } = useSelector(getSentiments);

  const handleChange = usePreboundCallback((sentimentName: SentimentVariant) => {
    dispatch(toggleSentiment(sentimentName));
  });

  return (
    <form>
      <Paragraph className="text-base-l font-medium">
        {t('segment.sentiment.chooseSentiment')}
      </Paragraph>
      <Paragraph className="my-4">{t('segment.sentiment.description')}</Paragraph>
      <Paragraph className="my-4">{t('segment.sentiment.disclaimer')}</Paragraph>
      <div className="flex gap-4">
        <div className="grow rounded-xl bg-primary-dark-purple-5 p-4">
          <Checkbox
            className="mb-3"
            defaultChecked={sentimentsValue.min !== 0}
            onChange={handleChange(SentimentType.negative)}
          >
            {t('segment.sentiment.negative')}
          </Checkbox>
          <Paragraph className="mb-0 w-full max-w-[320px] text-text-60">
            {t('segment.sentiment.negativeDescription')}
          </Paragraph>
        </div>
        <div className="grow rounded-xl bg-primary-dark-purple-5 p-4">
          <Checkbox
            className="mb-3"
            defaultChecked={sentimentsValue.max !== 0}
            onChange={handleChange(SentimentType.positive)}
          >
            {t('segment.sentiment.positive')}
          </Checkbox>
          <Paragraph className="mb-0 w-full max-w-[320px] text-text-60">
            {t('segment.sentiment.positiveDescription')}
          </Paragraph>
        </div>
      </div>
    </form>
  );
};
