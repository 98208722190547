import classNames from 'classnames';

import { Text } from 'components/common';

import { MIN_SEARCH_STRING_LENGTH } from './CheckboxTreeContainer';

export const MatchedCategoryPartHighlighter = ({
  name,
  searchString,
}: {
  name: string;
  searchString: string;
}) => {
  const parts = name.split(new RegExp(`(${searchString})`, 'gi'));

  if (searchString.length > MIN_SEARCH_STRING_LENGTH) {
    return (
      <>
        {parts.map((part, index) => {
          const match = part.toLowerCase() === searchString.toLowerCase();

          return (
            <Text
              large
              key={index}
              className={classNames({ 'bg-primary-electric-cyan-100': match })}
            >
              {part}
            </Text>
          );
        })}
      </>
    );
  }

  return (
    <Text large className="text-text-80">
      {name}
    </Text>
  );
};
