import { IconProps } from 'feather-icons-react';

export const SortIcon = ({ className, fill = 'currentColor', size = 24 }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.74174 3.48117L1.83301 7.43353L3.13592 8.75099L5.47201 6.38881V18.2459H7.31461V6.38905L9.65047 8.75099L10.9534 7.43353L7.04465 3.48117C6.68486 3.11736 6.10153 3.11736 5.74174 3.48117ZM14.9545 18.5188L11.0458 14.5665L12.3487 13.249L14.6848 15.6112V3.75404L16.5274 3.75404V15.6109L18.8632 13.249L20.1661 14.5665L16.2574 18.5188C15.8976 18.8826 15.3143 18.8826 14.9545 18.5188Z"
      fill={fill}
    />
  </svg>
);
