import { Tag } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Option, Select, Spin, SubmitButton, Text } from 'components/common';

import { updateSegmentLabels } from 'modules/labels/labelsSelector';
import { useLabels } from 'modules/labels/useLabels';

import { CircleCloseIcon } from '../common/icons';
import { DialogNames, useDialog } from '../dialog';
import { LabelsContainer } from './LabelsContainer';

interface ManageLabelsFormProps {
  onClose: () => void;
  segmentId: string;
}

export const SegmentLabelsForm = ({ onClose, segmentId }: ManageLabelsFormProps) => {
  const dispatch = useDispatch();
  const [showConfirmationDialog] = useDialog(DialogNames.CONFIRMATION_DIALOG);
  const { t } = useTranslation();
  const { segmentLabels, labels, isLoading, addLabel, deleteLabel } = useLabels(segmentId);
  const [formLabels, setFormLabels] = useState<string[]>(segmentLabels);

  const handleChange = (label: string) => {
    if (!formLabels.includes(label)) {
      setFormLabels([...formLabels, label]);
    }
  };

  const handleAdd = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const label = (event.target as HTMLInputElement).value;
      const labelNames = segmentLabels.map((name) => name.toLowerCase());

      if (label.length > 0 && !labelNames.includes(label.toLowerCase())) {
        addLabel(label, segmentId);
        setFormLabels([...formLabels, label]);
      }
    }
  };

  const removeLabel = (label: string) => {
    setFormLabels(formLabels.filter((labelName) => labelName !== label));
  };

  const handleDelete = (id: string, name: string) =>
    showConfirmationDialog({
      title: t('pages.management.manageLabels.deleteDialogTitle'),
      subTitle: t('pages.management.manageLabels.deleteDialogSubTitle'),
      onAgree: () => deleteLabel(id, name),
      onDisagree(): void {},
    });

  const onSubmit = () => {
    dispatch(updateSegmentLabels({ labels: formLabels, segmentId }));
    onClose();
  };

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Select
          suffixIcon={null}
          notFoundContent={
            <div className="text-primary-dark-purple-40 p-[8px] text-base-l">
              {t('forms.segment.create.labels.notFoundLabel')}
            </div>
          }
          placeholder={t('forms.segment.create.labels.placeholder')}
          optionFilterProp="children"
          showSearch
          value={null}
          filterOption={(input, option) =>
            ((option?.value ?? '') as string).toLowerCase().includes(input.toLowerCase())
          }
          onKeyDown={handleAdd}
          onChange={handleChange}
        >
          {labels.map((label) => (
            <Option key={label.id} value={label.name}>
              <Tag
                className="h-[24px] mb-2 max-w-max flex align-middle whitespace-nowrap"
                bordered={false}
                onClose={() => handleDelete(label.id, label.name)}
                color={label.color.background}
                closeIcon={<CircleCloseIcon fill={label.color.font} className="mt-[3px]" />}
                closable
              >
                <span className="text-base-s overflow-ellipsis overflow-hidden">
                  {label.name.toUpperCase()}
                </span>
              </Tag>
            </Option>
          ))}
        </Select>
      </div>
      {isLoading ? (
        <div className="mt-10 flex justify-center">
          <Spin />
        </div>
      ) : (
        <div className="mt-6">
          <Text>
            {t('pages.management.manageLabels.segmentLabels')} {`(${formLabels.length})`}
          </Text>
          <LabelsContainer
            handleClose={removeLabel}
            labels={labels.filter(({ name }) => formLabels.includes(name))}
          />
        </div>
      )}
      <div className="flex justify-end mt-10">
        <Button type="button" onClick={onClose} variant="outline">
          {t('pages.segmentDetails.cancel')}
        </Button>
        <SubmitButton onClick={onSubmit} className="ml-4">
          {t('pages.segmentDetails.save')}
        </SubmitButton>
      </div>
    </div>
  );
};
