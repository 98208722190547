import { AxiosInstance } from 'axios';

import { authStorage } from 'modules/auth/authStorage';
import { notificationService } from 'modules/notifications';

import { ROUTES } from 'routing/constants';

import { localStorageService } from '../localStorage';
import { VISIT_KEY } from '../localStorage/constants';
import { Http500ErrorText, HttpStatus } from './HttpStatus';

export function addResponseInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (resp) => resp,
    async (error) => {
      if (error?.response?.status === HttpStatus.Unauthorized) {
        authStorage.removeAuthToken();
        localStorageService.setItem(VISIT_KEY, true);
        window.location.replace(ROUTES.WELCOME);
      } else if (error?.response?.status === HttpStatus.InternalServerError) {
        notificationService.showError({
          key: 'http500Error',
          message: Http500ErrorText,
        });
      }

      return Promise.reject(error);
    },
  );
}
