import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContextaResponse, ContextaState } from './contextaForm/types';

export const CONTEXTA_SLICE_NAME = 'contexta';

const initialState: ContextaState = {
  url: null,
  accuracy: null,
  adClutter: 0,
  contentValidDays: null,
  sentiment: null,
  matchedTime: null,
  status: null,
  numberOfWords: null,
  keywordsVector: [],
  categories: [],
  subcategories: [],
  contentLength: 0,
  h1: null,
  h2: null,
  ttl: 0,
  language: null,
  metaDescription: '',
  emotion: [],
  emotionObj: {},
  useBaseUrl: false,
  matchedSeg: '',
  metadataTags: '',
  weightedKeywords: [],
  isLoading: false,
  message: '',
  keywords: '',
  contexta: null,
};

export const contextaSlice = createSlice({
  name: CONTEXTA_SLICE_NAME,
  initialState,
  reducers: {
    getContextaStart: (state: ContextaState, { payload }: PayloadAction<string>) => {
      state.isLoading = true;
      state.url = payload;
    },
    insertContextaStart: (state: ContextaState, { payload }: PayloadAction<ContextaState>) => {
      state.isLoading = true;
      state.url = payload.url;
      state.accuracy = payload.accuracy;
      state.adClutter = payload.adClutter;
      state.contentValidDays = payload.contentValidDays;
      state.sentiment = payload.sentiment;
      state.matchedTime = payload.matchedTime;
      state.status = payload.status;
      state.numberOfWords = payload.numberOfWords;
      state.keywordsVector = payload.keywordsVector;
      state.categories = payload.categories;
      state.subcategories = payload.subcategories;
      state.contentLength = payload.contentLength;
      state.ttl = payload.ttl;
      state.h1 = payload.h1;
      state.h2 = payload.h2;
      state.language = payload.language;
      state.metaDescription = payload.metaDescription;
      state.useBaseUrl = payload.useBaseUrl;
      state.matchedSeg = payload.matchedSeg;
      state.metadataTags = payload.metadataTags;
      state.weightedKeywords = payload.weightedKeywords;
      state.emotion = payload.emotion;
      state.emotionObj = payload.emotionObj;
      state.keywords = payload.keywords;
    },
    getContextaSuccess: (state: ContextaState, { payload }: PayloadAction<ContextaResponse>) => {
      state.isLoading = false;
      state.contexta = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contextaSuccess: (state: ContextaState, { payload }: PayloadAction<ContextaState>) => {
      state.isLoading = false;
    },
    contextaFailure: (state: ContextaState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.message = payload.message;
    },
  },
});

export const {
  getContextaStart,
  insertContextaStart,
  getContextaSuccess,
  contextaSuccess,
  contextaFailure,
} = contextaSlice.actions;

export const contexta = contextaSlice.reducer;
