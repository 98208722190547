import { GroupingModes } from '../../types';

export const groupingOptions = [
  {
    id: GroupingModes.UNGROUPED,
    label: 'Ungrouped',
  },
  {
    id: GroupingModes.COMPANY,
    label: 'Company',
  },
];
