import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Card, CardContent, Text, Title } from '../../../components/common';
import { formatDateWithTime } from '../../../services/dateUtils';
import { getScrapeUrlState } from './scrapeUrl/scrapeUrlSelectors';

export const DistanceCheckerScrapeUrlInfo = () => {
  const { t } = useTranslation();
  const data = useSelector(getScrapeUrlState);

  if (data.isLoading) {
    return <Skeleton />;
  }

  if (!data.content) {
    return null;
  }

  return (
    <div className="w-full flex flex-row gap-6">
      <div className="w-1/2">
        <Card className="rounded-[20px]">
          <Title level={4}>{t('adminPanel.scrapeUrl.info.content')}</Title>
          <CardContent className="flex">
            <Text className="whitespace-pre-wrap">{data.content}</Text>
          </CardContent>
        </Card>
      </div>

      <div className="w-1/2 flex flex-col gap-6">
        <div className="w-full flex flex-row gap-6">
          <Card className="w-full rounded-[20px]">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.length')}</Title>
            <CardContent className="flex justify-center items-center bborder border-secondary-green">
              <Text className="uppercase text-heading-m font-extralight">{data.contentLength}</Text>
            </CardContent>
          </Card>

          <Card className="w-full rounded-[20px]">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.adClutter')}</Title>
            <CardContent className="flex justify-center items-center bborder border-secondary-green">
              <Text className="uppercase text-heading-m font-extralight">{data.adClutter}</Text>
            </CardContent>
          </Card>

          <Card className="w-full rounded-[20px]">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.tokenCount')}</Title>
            <CardContent className="flex justify-center items-center bborder border-secondary-green">
              <Text className="uppercase text-heading-m font-extralight">{data.tokenCount}</Text>
            </CardContent>
          </Card>

          <Card className="w-full rounded-[20px] ">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.language')}</Title>
            <CardContent className="flex justify-center items-center bborder border-secondary-green">
              <Text className="uppercase text-heading-m font-extralight">{data.language}</Text>
            </CardContent>
          </Card>
        </div>

        <div className="w-full flex flex-row gap-6">
          <Card className="w-full rounded-[20px] ">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.time')}</Title>
            <CardContent className="flex justify-center items-center bborder border-secondary-green">
              <Text className="uppercase text-heading-m font-extralight">
                {formatDateWithTime(new Date(data.timestamp))}
              </Text>
            </CardContent>
          </Card>

          <Card className="w-full rounded-[20px] ">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.traceId')}</Title>
            <CardContent className="flex justify-center items-center bborder border-secondary-green">
              <Text className="uppercase text-heading-m font-extralight">{data.traceId}</Text>
            </CardContent>
          </Card>
        </div>

        <div>
          <Card className="rounded-[20px]">
            <Title level={4}>{t('adminPanel.scrapeUrl.info.metadata')}</Title>

            <CardContent className="flex  flex-col bborder border-secondary-green gap-2">
              <div className="flex gap-2">
                <Text className="capitalize font-bold min-w-[100px]">
                  {t('adminPanel.scrapeUrl.info.title')}
                </Text>
                <Text className="">{data.metadata?.title}</Text>
              </div>

              <div className="flex gap-2">
                <Text className="capitalize font-bold min-w-[100px]">
                  {t('adminPanel.scrapeUrl.info.author')}
                </Text>
                <Text className="">{data.metadata?.author}</Text>
              </div>

              <div className="flex gap-2">
                <Text className="capitalize font-bold min-w-[100px]">
                  {t('adminPanel.scrapeUrl.info.description')}
                </Text>
                <Text className="">{data.metadata?.description}</Text>
              </div>

              <div className="flex gap-2">
                <Text className="capitalize font-bold min-w-[100px]">
                  {t('adminPanel.scrapeUrl.info.siteName')}
                </Text>
                <Text className="">{data.metadata?.sitename}</Text>
              </div>

              {data.metadata?.tags && (
                <div className="flex gap-2">
                  <Text className="capitalize font-bold min-w-[100px]">
                    {t('adminPanel.scrapeUrl.info.tags')}
                  </Text>
                  {data.metadata?.tags.map((tag, i) => (
                    <Text key={i} className="">
                      {tag}
                    </Text>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        {data.headings && (
          <div>
            <Card className="rounded-[20px]">
              <Title level={4}>{t('adminPanel.scrapeUrl.info.headings')}</Title>

              <CardContent className="flex  flex-col bborder border-secondary-green gap-2">
                {data.headings.map((heading, i) => (
                  <div key={i} className="flex gap-2">
                    <Text className="capitalize font-bold min-w-[100px]">{heading.tag}</Text>
                    <Text className="">{heading.content}</Text>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};
