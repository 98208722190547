import { useTranslation } from 'react-i18next';

import { Text } from 'components/common';
import { NanoBotImg } from 'components/common/nanoBot';

import { getCurrentDateAndTime } from 'services/dateUtils';

export const NanoBotWelcomeMessage = () => {
  const { t } = useTranslation();
  const currentDate = getCurrentDateAndTime();

  return (
    <div className="mb-[30px]">
      <div className="mb-[10px] text-center text-text-20">{currentDate}</div>
      <div className="flex">
        <NanoBotImg className="mr-[5px] h-[32px] w-[32px]" />
        <div className="w-4/5 rounded-bl-[24px] rounded-br-[24px] rounded-tr-[24px] bg-primary-dark-purple-80 p-[20px]">
          <Text style={{ color: '#fff' }}>{t('nanoBot.welcomeMessage')}</Text>
        </div>
      </div>
    </div>
  );
};
