import { api } from 'services/api';

import { EXCLUSION_LIST_URL } from './constants';
import {
  ExclusionListCreateManyItemsRequest,
  ExclusionListCreateOneItemRequest,
} from './exclusionListApiTypes';

const getExclusionList = () => {
  return api.get(`${EXCLUSION_LIST_URL}/all`);
};

const deleteExclusionListItem = (id: string) => {
  return api.delete(`${EXCLUSION_LIST_URL}/${id}`);
};

const deleteAllExclusionListItems = () => {
  return api.delete(`${EXCLUSION_LIST_URL}/all`);
};

const createExclusionListItem = (data: ExclusionListCreateOneItemRequest) => {
  return api.post(EXCLUSION_LIST_URL, data);
};

const createManyExclusionListItems = (data: ExclusionListCreateManyItemsRequest) => {
  return api.post(`${EXCLUSION_LIST_URL}/many`, data);
};

export const exclusionListApi = {
  getExclusionList,
  deleteExclusionListItem,
  deleteAllExclusionListItems,
  createExclusionListItem,
  createManyExclusionListItems,
};
