import { IconProps } from 'feather-icons-react';

export const UserIcon = ({ className, fill = 'currentColor', size = 24 }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8 7.20039C16.8 4.54919 14.6512 2.40039 12 2.40039C9.34875 2.40039 7.19995 4.54919 7.19995 7.20039C7.19995 7.33559 7.19995 7.86519 7.19995 8.00039C7.19995 10.6516 9.34875 12.8004 12 12.8004C14.6512 12.8004 16.8 10.6516 16.8 8.00039C16.8 7.86519 16.8 7.33559 16.8 7.20039Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 18.4722C19.3168 16.9346 15.2032 15.2002 11.9984 15.2002C8.79358 15.2002 4.68158 16.9338 3.49838 18.4722C2.76718 19.4234 3.46398 20.8002 4.66318 20.8002H19.3352C20.5344 20.8002 21.2312 19.4234 20.5 18.4722Z"
      fill={fill}
    />
  </svg>
);
