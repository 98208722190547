import { useTranslation } from 'react-i18next';

import { AdminPanelBackIcon } from 'components/AdminPanel/AdminPanelBackIcon';
import { Title } from 'components/common';

import { UsersList } from 'modules/adminPanel/users/list/UsersList';

export const UserListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <header>
        <div className="flex flex-row items-center">
          <AdminPanelBackIcon />
          <Title>{t('adminPanel.user.userListHeading')}</Title>
        </div>
      </header>
      <div>
        <UsersList />
      </div>
    </>
  );
};
