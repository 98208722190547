import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';

import { NotificationConfig } from './config';

notification.config(NotificationConfig);

function showInfo(props: Omit<ArgsProps, 'type'>) {
  notification.open({
    ...props,
    message: '',
    description: props.message,
    type: 'info',
  });
}

function showSuccess(props: Omit<ArgsProps, 'type'>) {
  notification.open({
    ...props,
    message: '',
    description: props.message,
    type: 'success',
  });
}

function showError(props: Omit<ArgsProps, 'type'>) {
  notification.open({
    ...props,
    message: '',
    description: props.message,
    type: 'error',
  });
}

export const notificationService = {
  showInfo,
  showSuccess,
  showError,
};
