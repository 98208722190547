import { CategoryMap } from 'modules/apiData/types';

import { MappedCategory } from './types';

export const mapCategoriesToObject = (
  categories: CategoryMap,
  ids: string[],
): Record<string, MappedCategory> => {
  return Object.values(categories)
    .sort((a, b) => {
      const levelA = a.fullName.split('/');
      const levelB = b.fullName.split('/');

      return levelA.length - levelB.length;
    })
    .reduce((acc: Record<string, MappedCategory>, curr) => {
      acc[curr.id] = {
        ...curr,
        displayed: true,
        checked: ids.includes(curr.id),
        children: [],
        expanded: false,
      };

      if (curr.parentId) {
        acc[curr.parentId].children.push(curr.id);
      }

      return acc;
    }, {});
};

export const countChildren = (node: MappedCategory, dataObject: Record<string, MappedCategory>) => {
  const displayedChildren = Object.values(node.children).filter(
    (childId) => dataObject[childId].displayed,
  );
  let count = displayedChildren.length;

  if (displayedChildren.length) {
    displayedChildren.forEach((childId) => {
      count += countChildren(dataObject[childId], dataObject);
    });
  }

  return count;
};

export const filterNodes = (dataTree: Record<string, MappedCategory>, searchString: string) =>
  Object.values(dataTree)
    .sort((a, b) => {
      const levelA = a.fullName.split('/');
      const levelB = b.fullName.split('/');

      return levelB.length - levelA.length;
    })
    .reduce((acc: Record<string, MappedCategory>, current) => {
      const nameMatches = current.name.toLowerCase().includes(searchString.toLowerCase());
      const hasChildren = current.children.length > 0;
      const someChildrenAreDisplayed = current.children.some((childId) => acc[childId].displayed);
      const displayed = nameMatches || (hasChildren && someChildrenAreDisplayed);

      acc[current.id] = {
        ...current,
        displayed,
        expanded: searchString.length === 0 ? false : displayed,
      };

      return acc;
    }, {});
