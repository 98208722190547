import { IconProps } from 'feather-icons-react';

export const CrookedArrowIcon = ({ className, fill = 'currentColor', size = 24 }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.2612 20C14.2612 12.2857 23.2612 11 23.2612 11C23.2612 11 14.2612 9.71429 14.2612 2"
      stroke={fill}
      strokeWidth="2.4"
      strokeLinecap="round"
    />
    <path d="M23.2619 11H1.40479" stroke={fill} strokeWidth="2.4" strokeLinecap="round" />
  </svg>
);
