import { Trash } from 'feather-icons-react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Text } from 'components/common';

import { useConfirmationCallback } from 'hooks/useConfirmationCallback';

import { FileDescription } from './UploadKeywords';

type FileComponentProps = {
  file: FileDescription;
  deleteFile: (file: FileDescription) => void;
};

export const FileComponent = ({ file, deleteFile }: FileComponentProps) => {
  const { t } = useTranslation();
  const { name, size, values } = file;
  const [fileName, extension] = name.split('.');
  const sizeInKB = size ? Math.ceil(size / 1024) : 0;

  const handleFileDelete = useCallback(() => {
    deleteFile(file);
  }, [file, deleteFile]);

  const handleFileDeleteConfirmation = useConfirmationCallback(handleFileDelete, {
    title: t('segment.keywords.fileDeleteConfirmationTitle'),
    subTitle: t('segment.keywords.fileDeleteConfirmationDesc'),
  });

  return (
    <div className="rounded-xl py-3.5 px-8 h-[75px] flex content-center items-center justify-between bg-uploaded-file-gradient hover:bg-uploaded-file-hover-gradient">
      <div className="flex flex-col">
        <Text className="text-base-l font-medium">{fileName}</Text>
        <Text className="text-base-s text-text-60 uppercase">
          {extension} {sizeInKB} kb
        </Text>
      </div>
      <span className="flex items-center gap-7">
        <span className="px-4 py-3 rounded-xl bg-secondary-white bg-opacity-40">
          {values.length}
        </span>
        <IconButton onClick={handleFileDeleteConfirmation}>
          <Trash size={16} />
        </IconButton>
      </span>
    </div>
  );
};
