import { ArrowRight } from 'feather-icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Text } from 'components/common';

import { ROUTES } from 'routing/constants';

import { useCompanyListQuery } from '../../company';
import { GroupingMode, GroupingModes, UsersListItem } from '../types';

interface GroupedUserNameRendererProps {
  text: string;
  record: UsersListItem;
  groupingMode: GroupingMode;
  setGroupByCompany: (company: string[]) => void;
}

export function GroupedUserNameRenderer({
  text,
  record,
  groupingMode,
  setGroupByCompany,
}: GroupedUserNameRendererProps) {
  const { t } = useTranslation();

  const { id, showSeeAllLink, children } = record;

  const { data: companies } = useCompanyListQuery(null);

  const findCompanyNameById = useMemo(
    () =>
      (companyId: string): string | null => {
        const company = companies?.find((c) => c.id === companyId);

        return company ? company.name : 'None';
      },
    [companies],
  );
  const isCompanyNode = children && groupingMode === GroupingModes.COMPANY;

  const handleCompanyClick = () => {
    if (text === 'null') {
      setGroupByCompany(['null']);
    }
    setGroupByCompany([text]);
  };

  return (
    <>
      {isCompanyNode ? (
        <div className="flex gap-3 items-center cursor-pointer" onClick={handleCompanyClick}>
          <Text className="text-base-l font-medium text-secondary-purple">
            {findCompanyNameById(text)}
          </Text>
          {showSeeAllLink ? (
            <span className="flex gap-2 items-center text-secondary-purple">
              {t('pages.management.seeAll')} <ArrowRight size={16} />
            </span>
          ) : null}
        </div>
      ) : (
        <Link
          className="segment-name-link text-base-l font-bold no-underline"
          to={ROUTES.USER_BY_ID.replace(':id', id)}
        >
          {text + ' ' + record.surname}
        </Link>
      )}
    </>
  );
}
