import { Radio as RadioAntd, RadioProps, RadioGroupProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

export const RadioGroup = memo(({ children, className, ...rest }: RadioGroupProps) => (
  <RadioAntd.Group className={classNames(className)} {...rest}>
    {children}
  </RadioAntd.Group>
));

RadioGroup.displayName = 'RadioGroup';

export const Radio = memo(({ children, className, ...rest }: Omit<RadioProps, 'size'>) => (
  <RadioAntd className={classNames(className, 'nano-radio')} {...rest}>
    {children}
  </RadioAntd>
));

Radio.displayName = 'Radio';
