import isEqual from 'fast-deep-equal';
import { call, select } from 'redux-saga/effects';

import { PerformanceMetrics, TargetCombinationsResponse } from 'modules/segment/segmentApiTypes';
import { getTargetCombinations } from 'modules/segment/segmentDataFormatters';
import {
  getFiltersSelector,
  getPerformanceMetricsSelector,
  getSegment,
} from 'modules/segment/segmentSelectors';
import { ManualStepValues, SegmentState } from 'modules/segment/types';

import { defaultBucketState } from '../../segment/defaultBucketState';

export function* formRequestPayloadSaga() {
  const segment: SegmentState = yield select(getSegment);
  const performance: PerformanceMetrics = yield select(getPerformanceMetricsSelector);
  const sensitiveSubjectsArray: string[] = segment.sensitiveSubjects.sensitiveSubjectsValues;
  const segmentBuckets: ManualStepValues[] = yield call(prepareLatestBuckets, segment);
  const targetCombinations: TargetCombinationsResponse = yield call(
    getTargetCombinations,
    segmentBuckets,
  );
  const filters: ReturnType<typeof getFiltersSelector> = yield select(getFiltersSelector);

  return {
    filters: {
      geo: segment.geo,
      timeRange: filters.interval,
    },
    ...performance,
    segmentId: segment.id,
    ssp: segment.ssp,
    targetCombinations: targetCombinations,
    sensitiveSubjects: sensitiveSubjectsArray,
  };
}

// this is needed in order to take the latest users selections from that bucket that haven't been added yet to 'buckets' in the store
// so we use the existing buckets and add options from currentBucket that will be added to buckets after we hit 'Proceed'
function prepareLatestBuckets({ currentBucket, buckets }: SegmentState) {
  const { id: currentBucketId } = currentBucket;

  const updatedBuckets = buckets.map((bucket) =>
    // if we have currentBucketId and it equals to one of the buckets that means that we are modifying existing bucket
    bucket.id === currentBucketId ? currentBucket : bucket,
  );

  // the first condition is to check if we should add the currentBucket data to the reach-estimate request
  // the second is to prevent adding the currentBucket on details page it's empty
  return currentBucketId === null && !isEqual(currentBucket, defaultBucketState)
    ? [...buckets, currentBucket]
    : [...updatedBuckets];
}
