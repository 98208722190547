import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { USERS_URL } from '../constants';
import { TeamListItem, UserListItem } from '../userApiTypes';

export const usersListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    usersList: build.query<UserListItem[], null>({
      query: () => ({ method: 'GET', url: USERS_URL }),
      providesTags: ['Users'],
    }),
    teamsList: build.query<TeamListItem[], null>({
      query: () => ({ method: 'GET', url: 'teams' }),
      providesTags: ['Team'],
    }),
    rolesList: build.query<string[], null>({
      query: () => ({ method: 'GET', url: 'roles' }),
      providesTags: ['Roles'],
    }),
  }),
});

export const { useUsersListQuery, useTeamsListQuery, useRolesListQuery } = usersListApiSlice;
