import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { TEAMS_URL } from '../constants';
import { TeamListItem } from '../teamApiTypes';

export const teamListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    teamList: build.query<TeamListItem[], null>({
      query: () => ({ method: 'GET', url: TEAMS_URL }),
      providesTags: ['Team'],
    }),
  }),
});

export const { useTeamListQuery } = teamListApiSlice;
