import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AuditLogResponseItem,
  AuditLogSingleLineParams,
  AuditLogSingleLineResponse,
  AuditLogState,
  SearchAuditLog,
} from './types';

export const AUDIT_LOG_SLICE_NAME = 'audit';

const initialState: AuditLogState = {
  userId: null,
  objectType: null,
  auditLogList: [],
  startDate: '',
  endDate: '',
  id: '',
  isLoadingSearch: false,
  isLoadingSingleLine: false,
  compareData: { previous: '', new: '' },
  message: '',
};

export const auditLogSlice = createSlice({
  name: AUDIT_LOG_SLICE_NAME,
  initialState,
  reducers: {
    setAuditLogSearch: (state: AuditLogState, { payload }: PayloadAction<SearchAuditLog>) => {
      state.isLoadingSearch = true;
      state.userId = payload.userId;
      state.objectType = payload.objectType;
      state.id = payload.id;
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setAuditLogSearchSuccess: (
      state: AuditLogState,
      { payload }: PayloadAction<AuditLogResponseItem[]>,
    ) => {
      state.auditLogList = payload;
      state.isLoadingSearch = false;
    },
    setAuditLogSearchFailure: (
      state: AuditLogState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoadingSearch = false;
    },
    resetAuditLogSearch: () => {
      return {
        ...initialState,
      };
    },
    setAuditLogSingleLine: (
      state: AuditLogState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<AuditLogSingleLineParams>,
    ) => {
      state.isLoadingSingleLine = true;
    },
    setAuditLogSingleLineSuccess: (
      state: AuditLogState,
      { payload }: PayloadAction<AuditLogSingleLineResponse>,
    ) => {
      state.compareData.new = payload.newData;
      state.compareData.previous = payload.previousData;
      state.isLoadingSingleLine = false;
    },
    setAuditLogSingleLineFailure: (
      state: AuditLogState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoadingSingleLine = false;
      state.message = payload.message;
    },
  },
});

export const {
  setAuditLogSearch,
  resetAuditLogSearch,
  setAuditLogSearchSuccess,
  setAuditLogSearchFailure,
  setAuditLogSingleLine,
  setAuditLogSingleLineSuccess,
  setAuditLogSingleLineFailure,
} = auditLogSlice.actions;

export const auditLog = auditLogSlice.reducer;
