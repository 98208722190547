import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ROUTES } from 'routing/constants';

export const AdminPanelPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 px-4 pb-8">
        <h2 className="text-primary-dark-purple-100 text-heading-l font-bold">
          {t('adminPanel.title')}
        </h2>
        <p className="max-w-2xl text-base-l font-light">{t('adminPanel.home.info')}</p>
      </section>

      <div className="items-start gap-6 grid lg:grid-cols-2 xl:grid-cols-3 ">
        <div className="col-span-2 grid items-start gap-6 lg:col-span-1 ">
          <div className="rounded-xl border border-nano-light-purple border-opacity-25 shadow-card-shadow">
            <div className="flex flex-col space-y-1.5 p-6 pb-3">
              <h3 className="font-semibold leading-none tracking-tight">
                {t('adminPanel.home.dataUtilitiesCardGroup.title')}
              </h3>
              <p className="text-sm font-light">
                {t('adminPanel.home.dataUtilitiesCardGroup.description')}
              </p>
            </div>

            <div className="p-6 pt-0 grid gap-1">
              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.SEARCH_BY_VECTOR)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chart-dots-3"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                  <path d="M16 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                  <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M6 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M9 17l5 -1.5" />
                  <path d="M6.5 8.5l7.81 5.37" />
                  <path d="M7 7l8 -1" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.searchByVector.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.searchByVector.description')}
                  </p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md bg-accent p-2 hover:bg-primary-light-purple-20 transition-all cursor-pointer"
                onClick={() => navigate(ROUTES.DISTANCE_CHECKER)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-cloud-check"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M11 18.004h-4.343c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99c1.388 0 2.585 .82 3.138 2.007" />
                  <path d="M15 19l2 2l4 -4" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.urlDistanceChecker.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.urlDistanceChecker.description')}
                  </p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.CONTEXTA)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-report-analytics"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                  <path d="M9 17v-5" />
                  <path d="M12 17v-1" />
                  <path d="M15 17v-3" />
                </svg>
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.contextaAnalysisByPublisher.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.contextaAnalysisByPublisher.description')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-xl border border-nano-light-purple border-opacity-25 shadow-card-shadow">
            <div className="flex flex-col space-y-1.5 p-6 pb-3">
              <h3 className="font-semibold leading-none tracking-tight">
                {t('adminPanel.home.usersCardGroup.title')}
              </h3>
              <p className="font-light">{t('adminPanel.home.usersCardGroup.description')}</p>
            </div>

            <div className="p-6 pt-0 grid gap-1">
              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.COMPANY)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-building-skyscraper"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 21l18 0" />
                  <path d="M5 21v-14l8 -4v18" />
                  <path d="M19 21v-10l-6 -4" />
                  <path d="M9 9l0 .01" />
                  <path d="M9 12l0 .01" />
                  <path d="M9 15l0 .01" />
                  <path d="M9 18l0 .01" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.company.title')}
                  </p>
                  <p className="font-light">{t('adminPanel.home.company.description')}</p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md bg-accent p-2 hover:bg-primary-light-purple-20 transition-all cursor-pointer"
                onClick={() => navigate(ROUTES.TEAM)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-users"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.team.title')}
                  </p>
                  <p className="text-sm font-light">{t('adminPanel.home.team.description')}</p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.USERS)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-user"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.user.title')}
                  </p>
                  <p className="text-sm font-light">{t('adminPanel.home.user.description')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-xl border border-nano-light-purple border-opacity-25 shadow-card-shadow">
            <div className="flex flex-col space-y-1.5 p-6 pb-3">
              <h3 className="font-semibold leading-none tracking-tight">
                {t('adminPanel.home.logsCardGroup.title')}
              </h3>
              <p className="text-sm font-light">{t('adminPanel.home.logsCardGroup.description')}</p>
            </div>

            <div className="p-6 pt-0 grid gap-1">
              <div
                className="-mx-2 flex items-start space-x-4 rounded-md bg-accent p-2 hover:bg-primary-light-purple-20 transition-all cursor-pointer"
                onClick={() => navigate(ROUTES.AUDIT)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-file-search"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                  <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                  <path d="M18.5 19.5l2.5 2.5" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.auditLog.title')}
                  </p>
                  <p className="text-sm font-light">{t('adminPanel.home.auditLog.description')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-2 grid items-start gap-6 lg:col-span-1 ">
          <div className="rounded-xl border border-nano-light-purple border-opacity-25 shadow-card-shadow">
            <div className="flex flex-col space-y-1.5 p-6 pb-3">
              <h3 className="font-semibold leading-none tracking-tight">
                {t('adminPanel.home.segmentsCardGroup.title')}
              </h3>
              <p className="text-sm font-light">
                {t('adminPanel.home.segmentsCardGroup.description')}
              </p>
            </div>

            <div className="p-6 pt-0 grid gap-1">
              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.DOMAIN_LIST)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-playlist-add"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 8h-14" />
                  <path d="M5 12h9" />
                  <path d="M11 16h-6" />
                  <path d="M15 16h6" />
                  <path d="M18 13v6" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.domainList.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.domainList.description')}
                  </p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md bg-accent p-2 hover:bg-primary-light-purple-20 transition-all cursor-pointer"
                onClick={() => navigate(ROUTES.SEGMENT_GROUP)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-box-multiple"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
                  <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.segmentGroups.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.segmentGroups.description')}
                  </p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.EXCLUSION_LIST)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-playlist-x"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 8h-14" />
                  <path d="M5 12h7" />
                  <path d="M12 16h-7" />
                  <path d="M16 14l4 4" />
                  <path d="M20 14l-4 4" />
                </svg>
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.exclusionList.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.exclusionList.description')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-xl border border-nano-light-purple border-opacity-25 shadow-card-shadow">
            <div className="flex flex-col space-y-1.5 p-6 pb-3">
              <h3 className="font-semibold leading-none tracking-tight">
                {t('adminPanel.home.personasCardGroup.title')}
              </h3>
              <p className="text-sm font-light">
                {t('adminPanel.home.personasCardGroup.description')}
              </p>
            </div>

            <div className="p-6 pt-0 grid gap-1">
              <div
                className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-primary-light-purple-20 cursor-pointer"
                onClick={() => navigate(ROUTES.KEYWORD_GROUP)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-square-rounded-letter-k"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10 8v8" />
                  <path d="M14 8l-2.5 4l2.5 4" />
                  <path d="M10 12h1.5" />
                  <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.keywordGroup.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.keywordGroup.description')}
                  </p>
                </div>
              </div>

              <div
                className="-mx-2 flex items-start space-x-4 rounded-md bg-accent p-2 hover:bg-primary-light-purple-20 transition-all cursor-pointer"
                onClick={() => navigate(ROUTES.CUSTOM_CATEGORY_LIST)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-square-rounded-letter-c"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 10a2 2 0 1 0 -4 0v4a2 2 0 1 0 4 0" />
                  <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                </svg>

                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {t('adminPanel.home.customCategory.title')}
                  </p>
                  <p className="text-sm font-light">
                    {t('adminPanel.home.customCategory.description')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
