import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainListRequestValues, DomainListResponse } from './domainListApiTypes';
import { DomainListState } from './types';

export const EXCLUSION_LIST_SLICE_NAME = 'exclusion_list';

export const initialState: DomainListState = {
  id: '',
  name: '',
  list: [],
  isLoading: false,
  itemToDeleteId: '',
  errorMessage: '',
  createdDate: '',
};

export const domainListSlice = createSlice({
  name: EXCLUSION_LIST_SLICE_NAME,
  initialState,
  reducers: {
    deleteDomainListItem: (state: DomainListState) => {
      state.isLoading = true;
    },
    deleteDomainListSuccess: (state: DomainListState) => {
      state.isLoading = false;
      state.itemToDeleteId = '';
    },
    deleteDomainListFailure: (
      state: DomainListState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
    setDomainListItemToDeleteId: (state: DomainListState, { payload }: PayloadAction<string>) => {
      state.itemToDeleteId = payload;
    },
    saveDomainList: {
      reducer: (
        state: DomainListState,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        action: PayloadAction<void, string, { refetch?: () => void }>,
      ) => {
        state.isLoading = true;
        // You can handle `meta` in a saga or elsewhere
      },
      prepare: (refetch?: () => void) => ({
        payload: undefined,
        meta: { refetch },
      }),
    },
    saveDomainListSuccess: (state: DomainListState) => {
      state.isLoading = false;
    },
    saveDomainListFailure: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
    updateValues: (state, { payload }: PayloadAction<DomainListRequestValues>) => {
      return {
        ...state,
        ...payload,
      };
    },
    setDomainListToUpdateId: (state, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
    updateDomainList: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    updateDomainListSuccess: (state: DomainListState) => {
      state.isLoading = false;
    },
    updateDomainListFailure: (
      state: DomainListState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
    clearDomainList: () => initialState,
    getDomainListById: (state, { payload }: PayloadAction<string | undefined>) => {
      state.isLoading = true;
      state.id = payload;
    },
    getDomainListByIdSuccess: (state, { payload }: PayloadAction<DomainListResponse>) => {
      state.isLoading = false;
      state.name = payload.name;
      state.list = [...payload.list];
    },
    getDomainListByIdFailure: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
  },
});

export const {
  deleteDomainListItem,
  deleteDomainListSuccess,
  deleteDomainListFailure,
  setDomainListItemToDeleteId,
  saveDomainList,
  saveDomainListSuccess,
  saveDomainListFailure,
  updateValues,
  setDomainListToUpdateId,
  updateDomainList,
  updateDomainListSuccess,
  updateDomainListFailure,
  clearDomainList,
  getDomainListById,
  getDomainListByIdSuccess,
  getDomainListByIdFailure,
} = domainListSlice.actions;

export const domainList = domainListSlice.reducer;
