import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useConfirmationCallback } from 'hooks/useConfirmationCallback';

import { getUserToDeleteId } from '../userSelectors';
import { deleteUser } from '../usersSlice';

export const useUserDelete = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userToDeleteId = useSelector(getUserToDeleteId);

  return useConfirmationCallback(
    () => {
      dispatch(deleteUser({ id: userToDeleteId }));
    },
    {
      title: t('adminPanel.user.dialog.deleteUserConfirmationTitle'),
      subTitle: t('adminPanel.user.dialog.deleteUserConfirmationSubTitle'),
    },
  );
};
