import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TeamCreateValues, TeamState } from './types';

export const TEAMS_SLICE_NAME = 'team';

export const initialState: TeamState = {
  id: '',
  companyId: '',
  name: '',
  createdAt: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  users: [],
  teamToDeleteId: '',
  success: null,
  error: null,
};

export const teamSlice = createSlice({
  name: TEAMS_SLICE_NAME,
  initialState,
  reducers: {
    saveTeam: (state: TeamState) => {
      state.isLoading = true;
    },
    updateValues: (state: TeamState, { payload }: PayloadAction<TeamCreateValues>) => {
      return {
        ...state,
        ...payload,
      };
    },
    saveTeamFailure: (state: TeamState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.error = payload.message;
    },
    saveTeamSuccess: (state: TeamState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
      state.createdAt = payload.createdAt;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteTeam: (state: TeamState, { payload }: PayloadAction<{ id: string }>) => {
      state.isLoading = true;
    },
    deleteTeamSuccess: (state: TeamState) => {
      state.isLoading = false;
      state.teamToDeleteId = '';
      state.isCreated = false;
    },
    deleteTeamFailure: (state: TeamState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.isCreated = false;
      state.error = payload.message;
    },
    setTeamToDeleteId: (state: TeamState, { payload }: PayloadAction<string>) => {
      state.teamToDeleteId = payload;
    },
    clearTeam: () => initialState,
    updateTeam: (state: TeamState) => {
      state.isLoading = true;
      state.success = null;
      state.error = null;
    },
    updateTeamSuccess: (state: TeamState) => {
      state.isLoading = false;
      state.isCreated = true;
      state.success = 'success';
    },
    updateTeamFailure: (state: TeamState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.isCreated = false;
      state.error = payload.message;
    },
    setTeamToUpdateId: (state: TeamState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
  },
});

export const {
  updateValues,
  saveTeamFailure,
  saveTeam,
  saveTeamSuccess,
  deleteTeam,
  deleteTeamSuccess,
  deleteTeamFailure,
  setTeamToDeleteId,
  clearTeam,
  updateTeam,
  updateTeamSuccess,
  updateTeamFailure,
  setTeamToUpdateId,
} = teamSlice.actions;

export const team = teamSlice.reducer;
