import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Field, Input, Option, Select, SubmitButton } from 'components/common';

import { useCompanyListQuery } from 'modules/adminPanel/company';
import { updateValues, saveTeam, updateTeam, setTeamToUpdateId } from 'modules/adminPanel/team';
import { getTeam } from 'modules/adminPanel/team/teamSelectors';
import { useUsersListQuery } from 'modules/adminPanel/users';
import { notificationService } from 'modules/notifications';

type TeamInfoFormProps = {
  id?: string;
  name?: string;
  companyId?: string;
  users?: string[];
  onClose: () => void;
};

export const TeamsInfoForm = ({ id, name, companyId, onClose, users }: TeamInfoFormProps) => {
  const { t } = useTranslation();
  const { isLoading, isCreated } = useSelector(getTeam);
  const dispatch = useDispatch();

  const currentTeam = useSelector(getTeam);
  const { data: usersAll } = useUsersListQuery(null);
  const { data: companies } = useCompanyListQuery(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { name: name, companyId: companyId, users: users } });

  const onSubmit = handleSubmit((data) => {
    dispatch(updateValues(data));
    if (!id) {
      dispatch(saveTeam());
    } else {
      dispatch(setTeamToUpdateId(id));
      dispatch(updateTeam());
    }
  });

  const showSuccessNotification = useCallback(() => {
    notificationService.showSuccess({
      key: 'teamFormSuccess',
      message: t('adminPanel.team.success.teamSaveSuccess'),
    });
  }, [t]);

  useEffect(() => {
    if (!isLoading && isCreated) {
      showSuccessNotification();
      onClose();
    }
  }, [id, currentTeam, name, companyId, isLoading, isCreated, onClose, showSuccessNotification]);

  return (
    <form className="flex flex-col gap-y-9" onSubmit={onSubmit}>
      <Field
        name="name"
        label={t('adminPanel.team.dialog.name')}
        control={control}
        className="h-[80px]"
        render={({ field, fieldState: { invalid } }) => (
          <Input placeholder={t('adminPanel.team.dialog.name')} {...field} invalid={invalid} />
        )}
        rules={{
          required: 'Required',
        }}
        error={errors.name?.message}
      />
      <Field
        name="companyId"
        label={t('adminPanel.team.dialog.company')}
        control={control}
        className="h-[80px]"
        render={({ field }) => (
          <Select placeholder={t('adminPanel.team.dialog.company')} {...field}>
            {companies?.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        )}
        rules={{
          required: t('adminPanel.forms.errors.required'),
        }}
        error={errors.companyId?.message}
      />
      <Field
        name="users"
        label={t('adminPanel.team.dialog.usersLabel')}
        control={control}
        render={({ field }) => (
          <Select
            placeholder={t('adminPanel.team.dialog.selectUsersText')}
            mode="multiple"
            optionFilterProp="children"
            {...field}
          >
            {usersAll?.map((user) => (
              <Option key={user.id} value={user.id}>
                {`${user.name} ${user.surname}`}
              </Option>
            ))}
          </Select>
        )}
      />
      <div className="mt-2 flex items-center justify-end gap-x-4">
        <Button type="button" variant="outline" onClick={onClose}>
          {t('adminPanel.company.dialog.cancelButton')}
        </Button>
        <SubmitButton isLoading={isLoading}>
          {t('adminPanel.company.dialog.submitButton')}
        </SubmitButton>
      </div>
    </form>
  );
};
