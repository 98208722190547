import { useTranslation } from 'react-i18next';

import { SegmentLabelsForm } from 'components/SegmentLabelsForm';

import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

type ManageLabelsDialogProps = DialogBaseProps & {
  segmentId: string;
};

export const SegmentLabelsDialog = ({ open, onClose, segmentId }: ManageLabelsDialogProps) => {
  const { t } = useTranslation();

  return (
    <NanoDialog
      wrapClassName="flex items-center justify-center h-[70vh] rounded-lg"
      className="px-8 py-6"
      open={open}
      onClose={onClose}
      title={t('pages.management.manageLabels.title')}
      width={600}
    >
      <SegmentLabelsForm onClose={onClose} segmentId={segmentId} />
    </NanoDialog>
  );
};
