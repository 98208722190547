import { AxiosInstance } from 'axios';

import { notificationService } from 'modules/notifications';

enum AdminHttpStatus {
  NotFound = 404,
}

const Http404ErrorText =
  'The requested resource could not be found on the server. Please check the URL or try again later.';
const GenericError =
  'An unexpected error occurred. Please try again. If the problem persists, contact our support team.';

export function addAdminPanelSpecificErrorInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      switch (error?.response?.status) {
        case AdminHttpStatus.NotFound:
          notificationService.showError({
            key: 'http404Error',
            message: Http404ErrorText,
          });
          break;
        default:
          notificationService.showError({
            key: 'genericError',
            message: error?.response?.data?.message || GenericError,
          });
          break;
      }

      return Promise.reject(error);
    },
  );
}
