import { v4 as uuid } from 'uuid';

import { defaultBucketState } from './defaultBucketState';
import { SegmentDetailsResponse, TargetCombinationsTypes } from './segmentApiTypes';

export const extractBuckets = ({ targetCombinations }: SegmentDetailsResponse) => {
  return targetCombinations.map((combination) => {
    return combination.targets.reduce(
      (acc, val) => {
        if (
          val.type === TargetCombinationsTypes.keywords ||
          val.type === TargetCombinationsTypes.intentPrompt
        ) {
          acc['keywords'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            keywordsText: val.keywords.join(', '),
            keywordsValues: val.keywords,
            uploadedValues: [],
            intentPrompt: val.type !== TargetCombinationsTypes.keywords,
          };
        } else if (val.type === TargetCombinationsTypes.sentiment) {
          acc['sentiments'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            sentimentsValue: val.sentiment,
          };
        } else if (val.type === TargetCombinationsTypes.categories) {
          acc['categories'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            categoriesValues: val.categories,
          };
        } else if (val.type === TargetCombinationsTypes.languages) {
          acc['languages'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            languagesValues: val.languages,
          };
        } else if (val.type === TargetCombinationsTypes.emotions) {
          acc['emotions'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            emotionsValues: val.emotions,
          };
        } else if (val.type === TargetCombinationsTypes.keywordEntities) {
          // TODO: this is not clear where to get on a new API
          acc['entity'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            entity: val.keywordEntities.map((item) => ({
              keyword: item.keyword,
              // eslint-disable-next-line camelcase
              entity_type: item.entity_type,
            })),
          };
        } else if (val.type === TargetCombinationsTypes.customCategories) {
          acc['personas'] = {
            isTouched: false,
            isIncluded: !val.exclude,
            personasValues: val.customCategories,
          };
        }

        acc.id = uuid();

        return acc;
      },
      { ...defaultBucketState },
    );
  });
};
