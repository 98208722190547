import { Download as DownloadIcon } from 'feather-icons-react';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

import { Button, Field, Input, Tag, Text } from 'components/common';

interface TagsListProps {
  tagsItems: string[];
  isIncluded: boolean;
  onClear: (tag: string) => void;
  onClearAll?: () => void;
}

export const TagsList = ({ tagsItems, isIncluded, onClear, onClearAll }: TagsListProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col rounded-lg bg-secondary-mint px-5 pb-6 pt-4">
      <div className="flex justify-between">
        <Text className="mb-3 text-base-l font-medium">{t('segment.tagList.yourSelection')}</Text>
        {Boolean(tagsItems.length) && (
          <Button
            size="small"
            variant="text"
            onClick={onClearAll}
            className="cursor-pointer text-base-s font-semibold text-secondary-purple"
          >
            {t('common.clearAll')}
          </Button>
        )}
      </div>
      <div className="gap flex max-h-[475px] flex-wrap gap-[5px] overflow-y-auto">
        {tagsItems.length ? (
          tagsItems.map((tagItem, index) => (
            <TagsListItem
              key={`${tagItem}${index}`}
              text={tagItem}
              isIncluded={isIncluded}
              onClose={onClear}
            />
          ))
        ) : (
          <Text className="text-base-l text-text-60">{t('segment.tagList.empty')}</Text>
        )}
      </div>

      <div className="absolute bottom-0 right-0 h-[37px] rounded-lg bg-text-80/[0.12] px-3 leading-10">
        {tagsItems.length}
      </div>
    </div>
  );
};

interface TagsListItemProps {
  text: string;
  isIncluded: boolean;
  onClose: (item: string) => void;
}

const TagsListItem = ({ text, isIncluded, onClose }: TagsListItemProps) => {
  const handleClose = useCallback(() => {
    onClose(text);
  }, [onClose, text]);

  return (
    <Tag variant={isIncluded ? 'green' : 'red'} onClose={handleClose}>
      {text}
    </Tag>
  );
};

interface EditableTagsListProps extends TagsListProps {
  title: string;
  inputPlaceholder: string;
  isIncluded: boolean;
  onFormSubmit: (item: string) => void;
  showExport: boolean;
}

export const EditableTagsList = ({
  title,
  inputPlaceholder,
  tagsItems,
  isIncluded,
  onClear,
  onClearAll,
  onFormSubmit,
  showExport,
}: EditableTagsListProps) => {
  const { t } = useTranslation();

  const { control, reset, handleSubmit, formState } = useForm({
    defaultValues: { keyword: '' },
  });

  const { isSubmitSuccessful } = formState;

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    const dataSeparatedByNewLine = pastedData.split('\n'); // Separate data based on tabs

    dataSeparatedByNewLine.forEach((tag) => onFormSubmit(tag));
    reset();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data.keyword);
  });

  const exportToXLSX = (data: string[]) => {
    const worksheet = XLSX.utils.aoa_to_sheet(data.map((value) => [value]));
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Keywords');
    XLSX.writeFile(workbook, 'keywordList.xlsx');
  };

  return (
    <div className="relative flex flex-col rounded-lg bg-text-5 px-5 pb-6 pt-4 grow">
      <div className="flex justify-between content-center">
        <Text className="text-base-l font-medium mb-3">{title}</Text>
        {Boolean(tagsItems.length) && (
          <div>
            {showExport && (
              <Button
                variant="text"
                size="small"
                className="mb-4 cursor-pointer text-base-s font-semibold text-secondary-purple"
                onClick={() => exportToXLSX(tagsItems)}
              >
                {t('segment.keywords.exportKeywords')}
                <DownloadIcon size="16" className="ml-1.5 inline" />
              </Button>
            )}
            <Button
              size="small"
              variant="text"
              onClick={onClearAll}
              className="mb-4 cursor-pointer text-base-s font-semibold text-secondary-purple"
            >
              {t('common.clearAll')}
            </Button>
          </div>
        )}
      </div>
      <div className="gap flex max-h-[355px] flex-wrap gap-[5px] overflow-y-auto mb-5">
        {tagsItems.map((tagItem, index) => (
          <TagsListItem
            key={`${tagItem}${index}`}
            text={tagItem}
            isIncluded={isIncluded}
            onClose={onClear}
          />
        ))}
      </div>
      <form onSubmit={onSubmit}>
        <Field
          className="border-none w-full"
          name="keyword"
          control={control}
          render={({ field }) => (
            <Input
              className="border-none outline-none shadow-transparent"
              placeholder={inputPlaceholder}
              onPaste={handlePaste}
              {...field}
            />
          )}
        />
      </form>

      <div className="absolute bottom-0 right-0 h-[37px] rounded-lg bg-text-80/[0.12] px-3 leading-10">
        {tagsItems.length}
      </div>
    </div>
  );
};
