import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { NanoBotChatRole, NanoBotState, ResponseMessage } from 'modules/nanoBot/types';

import { nanoBotStorage } from './nanoBotStorage';

const initialState: NanoBotState = {
  isOpen: false,
  messagesHistory: nanoBotStorage.getFromNanoBotStorage(),
  currentMessage: '',
  isLoading: false,
};

export const nanoBotSlice = createSlice({
  name: 'nanoBot',
  initialState,
  reducers: {
    toggleNanoBotVisibility: (state: NanoBotState) => {
      state.isOpen = !state.isOpen;
    },
    sendMessage: (state: NanoBotState, { payload }: PayloadAction<string>) => {
      state.isLoading = true;
      state.currentMessage = payload;
    },
    sendMessageHistoryUpdate: (state: NanoBotState, { payload }: PayloadAction<string>) => {
      state.messagesHistory = [
        ...state.messagesHistory,
        { role: NanoBotChatRole.User, content: payload },
      ];
    },
    clearMessageHistory: (state: NanoBotState) => {
      state.messagesHistory = [];
    },
    sendMessageSuccess: (state: NanoBotState, { payload }: PayloadAction<ResponseMessage[]>) => {
      state.isLoading = false;
      const trimmedMessages: ResponseMessage[] = payload.map((message) => {
        const newContent = message.content.replace(/###/g, '').trim();

        return {
          content: newContent,
          role: message.role,
        };
      });

      state.messagesHistory = [...trimmedMessages];

      nanoBotStorage.clearNanoBotStorage();
      nanoBotStorage.saveToNanoBotStorage([...trimmedMessages]);
    },
    sendMessageFailure: (state: NanoBotState) => {
      state.isLoading = false;
    },
  },
});

const getNanoBot = ({ nanoBot }: AppState) => nanoBot;

export const getIsNanoBotOpen = (state: AppState) => getNanoBot(state).isOpen;
export const getNanoBotMessagesHistory = (state: AppState) => getNanoBot(state).messagesHistory;
export const getNanoBotLoading = (state: AppState) => getNanoBot(state).isLoading;

export const {
  toggleNanoBotVisibility,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
  sendMessageHistoryUpdate,
  clearMessageHistory,
} = nanoBotSlice.actions;

export const nanoBot = nanoBotSlice.reducer;
