import { DistanceCheckerCategory } from '../distanceCheckerApiTypes';

export const getWeightedCategoriesChartOptions = (data: Array<DistanceCheckerCategory> | null) => {
  if (!data) return;

  return {
    grid: {
      bottom: 0,
      top: 0,
      containLabel: true,
    },
    xAxis: {
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      max: 1,
    },
    yAxis: {
      data: data.map((item) => item.name),
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        align: 'right',
        fontSize: '12px',
      },
    },
    series: [
      {
        name: 'Score',
        type: 'bar',
        data: data.map((item) => item.score),
        itemStyle: {
          borderRadius: [0, 6, 6, 0],
          color: '#99FFFD',
        },
        label: {
          show: true,
          position: 'insideRight',
          fontSize: '12px',
          fontWeight: 'bold',
          formatter: ({ data }: { data: number }) => `${data.toFixed(2)}`,
        },
      },
    ],
  };
};

export const formatScoredCategoriesData = (
  scoredCategories: Array<DistanceCheckerCategory>,
): Array<DistanceCheckerCategory> => {
  return scoredCategories.sort((a, b) => a.score - b.score);
};
