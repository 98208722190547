import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxGroup, Paragraph, Title } from 'components/common';

import { useEmotionsQuery } from 'modules/apiData/apiDataApiSlice';
import { getEmotionsIcons } from 'modules/segment/segmentForms/ManualStepForm/utils';
import { getBucketEmotions } from 'modules/segment/segmentSelectors';
import { setEmotions } from 'modules/segment/segmentSlice';

import { EmotionNode } from './EmotionNode';

export const Emotions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: emotions } = useEmotionsQuery(null);
  const { emotionsValues } = useSelector(getBucketEmotions);

  const emotionsList = useMemo(() => getEmotionsIcons(emotions ?? []), [emotions]);

  const onChange = (checkedValues: string[]) => {
    dispatch(setEmotions(checkedValues));
  };

  return (
    <form className="flex h-full w-full flex-col gap-8">
      <div className="h-full gap-6">
        <Paragraph className="text-base-l font-medium">{t('segment.emotions.emotions')}</Paragraph>
        <Paragraph className="my-4">{t('segment.emotions.description')}</Paragraph>
        <Paragraph className="my-4">{t('segment.emotions.disclaimer')}</Paragraph>
        {emotionsList?.length ? (
          <CheckboxGroup className="flex gap-4" onChange={onChange} value={emotionsValues}>
            {emotionsList.map((emotion) => (
              <EmotionNode key={emotion.key} emotion={emotion} />
            ))}
          </CheckboxGroup>
        ) : (
          <div className="flex items-center justify-center">
            <Title level={3} className="mt-20 text-primary-dark-purple-40">
              {t('segment.emotions.noDataToShow')}
            </Title>
          </div>
        )}
      </div>
    </form>
  );
};
