import i18next from 'i18next';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authApiSlice } from 'modules/auth/authApiSlice';

import { formatDateWithTime } from 'services/dateUtils';
import { notifyError } from 'services/logService';

import { segmentsListApiSlice } from '../list/segmentsListApiSlice';
import { segmentsApi } from '../segmentApi';
import { SegmentResponse, SegmentStatuses } from '../types';
import {
  createEmptySegment,
  createEmptySegmentFailure,
  createEmptySegmentSuccess,
} from './emptySegmentSlice';

function* createEmptySegmentSaga() {
  const { data: user } = yield select(authApiSlice.endpoints.userProfile.select(null));

  const segmentData = {
    name: `New Segment ${formatDateWithTime(new Date())}`,
    status: SegmentStatuses.planning,
    userId: user.id,
    companyId: user.companyId,
    teamId: user.teamId,
    ssp: 'EQ', // need to determine what should be the default ssp in order not to make additional requests for the list of SSPs and select the first item
    async: false,
    realtime: true,
    accuracyThreshold: 'low',
  };

  try {
    const response: SegmentResponse = yield call(segmentsApi.saveSegment, segmentData);

    yield put(createEmptySegmentSuccess(response.id));
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
  } catch (e) {
    notifyError(e);
    yield put(
      createEmptySegmentFailure({
        message: i18next.t('errors.emptySegmentCreationFailed'),
      }),
    );
  }
}

export function* emptySegmentSagas() {
  yield takeLatest(createEmptySegment.type, createEmptySegmentSaga);
}
