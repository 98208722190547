import { api } from 'services/api';

import { DISTANCE_CHECKER_URL } from './constants';
import { DistanceCheckerRequest, DistanceCheckerResponse } from './distanceCheckerApiTypes';

const getDistanceCheckerData = (data: DistanceCheckerRequest) => {
  return api.post<DistanceCheckerResponse>(`${DISTANCE_CHECKER_URL}/submit`, data);
};

export const distanceCheckerApi = {
  getDistanceCheckerData,
};
