import { AxiosError } from 'axios';
import { nanoApi } from 'redux/rootApiSlice';
import { arrayToMap } from 'utils';

import { Emotion } from 'modules/segment/segmentForms/ManualStepForm/types';
import {
  DomainList,
  Dsp,
  EntitiesSuggestion,
  Geo,
  Language,
  Persona,
  SegmentGroup,
  SSP,
} from 'modules/segment/types';
import { deduplicateLanguages } from 'modules/segment/utils';

import { normalizePersonasResponse } from './transformResponseUtils';
import { Category, PersonaMap } from './types';

const apiDataApiSlice = nanoApi.injectEndpoints({
  endpoints: (build) => ({
    categories: build.query<Record<string, Category>, null>({
      query: () => ({ method: 'GET', url: 'categories/getAll' }),
      transformResponse: (categories: Category[]) => arrayToMap<Category, 'id'>(categories, 'id'),
      providesTags: [{ type: 'SegmentApiData', id: 'Categories' }],
    }),
    languages: build.query<Record<string, Language>, null>({
      query: () => ({ method: 'GET', url: 'language/getAll' }),
      transformResponse: (languages: Language[]) => {
        const uniqueLanguages: Language[] = deduplicateLanguages(languages);

        return arrayToMap<Language, 'iso'>(uniqueLanguages, 'iso');
      },
      providesTags: [{ type: 'SegmentApiData', id: 'Languages' }],
    }),
    ssp: build.query<SSP[], null>({
      query: () => ({ method: 'GET', url: 'ssp/getAll' }),
      providesTags: [{ type: 'SegmentApiData', id: 'Ssp' }],
    }),
    dsp: build.query<Dsp[], null>({
      query: () => ({ method: 'GET', url: 'dsp/getAll' }),
      providesTags: [{ type: 'SegmentApiData', id: 'Dsp' }],
    }),
    emotions: build.query<Emotion[], null>({
      query: () => ({ method: 'GET', url: 'emotion/getAll' }),
      providesTags: [{ type: 'SegmentApiData', id: 'Emotions' }],
    }),
    geo: build.query<Geo[], null>({
      query: () => ({ method: 'GET', url: 'geo/getAll' }),
      transformResponse: (geoArray: Geo[]) => [
        ...geoArray.sort((a, b) => a.location.localeCompare(b.location)),
      ],
      providesTags: [{ type: 'SegmentApiData', id: 'Geo' }],
    }),
    sensitiveSubjects: build.query<Record<string, Category>, null>({
      query: () => ({ method: 'GET', url: 'categories/sensitive-subject' }),
      transformResponse: (sensitiveSubjects: Category[]) =>
        arrayToMap<Category, 'id'>(sensitiveSubjects, 'id'),
      providesTags: [{ type: 'SegmentApiData', id: 'SensitiveSubjects' }],
    }),
    personas: build.query<PersonaMap, null>({
      query: () => ({ method: 'GET', url: 'personas/getAll' }),
      transformResponse: (personas: Persona[]) => normalizePersonasResponse(personas),
      providesTags: [{ type: 'SegmentApiData', id: 'Personas' }],
    }),
    entities: build.query<Record<'types', string>[], null>({
      query: () => ({ method: 'GET', url: 'keyword-entity-type/getAll' }),
      transformResponse: (entities: Record<'types', string>[]) =>
        entities.map((el) => ({
          ...el,
          types:
            el.types.charAt(0).toUpperCase() + el.types.slice(1).toLowerCase().replaceAll('_', ' '),
        })),
      providesTags: [{ type: 'SegmentApiData', id: 'Entities' }],
    }),
    segmentGroups: build.query<SegmentGroup[], null>({
      query: () => ({ method: 'GET', url: 'segmentGroups/' }),
      providesTags: [{ type: 'SegmentApiData', id: 'SegmentGroups' }],
    }),
    domainList: build.query<DomainList[], null>({
      query: () => ({ method: 'GET', url: 'domainlist/all' }),
      providesTags: [{ type: 'SegmentApiData', id: 'DomainList' }],
    }),
    getEntitiesSuggest: build.query<EntitiesSuggestion, string>({
      query: (term: string) => ({
        method: 'GET',
        url: 'entities/suggest',
        params: { term },
      }),
      providesTags: [{ type: 'SegmentApiData', id: 'EntitiesSuggest' }],
      transformErrorResponse: (error: AxiosError) => {
        return {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
        };
      },
    }),
  }),
});

export const {
  useCategoriesQuery,
  useLanguagesQuery,
  useSspQuery,
  useDspQuery,
  useEmotionsQuery,
  useGeoQuery,
  useSensitiveSubjectsQuery,
  usePersonasQuery,
  useEntitiesQuery,
  useSegmentGroupsQuery,
  usePrefetch: useApiDataSlicePrefetch,
  useDomainListQuery,
  useGetEntitiesSuggestQuery,
} = apiDataApiSlice;
