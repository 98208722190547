import { useTranslation } from 'react-i18next';

import SegmentTrackingTagsForm from 'modules/segment/segmentForms/SegmentTrackingTagsForm/SegmentTrackingTagsForm';

import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';
import { DialogBaseProps } from '../dialog/types';

export const TrackingTagsDialog = ({ open, onClose }: DialogBaseProps) => {
  const { t } = useTranslation();

  return (
    <NanoDialog className="pr-0" open={open} title={t('trackingTags.title')} width={1100}>
      <SegmentTrackingTagsForm onClose={onClose} />
    </NanoDialog>
  );
};
