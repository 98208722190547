import { useEffect } from 'react';

export const usePendo = (userId: string | undefined) => {
  useEffect(() => {
    if (userId) {
      window?.pendo.initialize({
        visitor: {
          id: userId,
        },
      });
    }
  }, [userId]);
};
