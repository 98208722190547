import { AppState } from 'redux/store/store';

export const getSegment = ({ segment }: AppState) => segment;

export const getIsSegmentLoading = (state: AppState) => getSegment(state).isLoading;

export const getSegmentStatus = (state: AppState) => getSegment(state).status;

export const getSegmentToCopyId = (state: AppState) => getSegment(state).segmentToCopyId;

export const getDeletedSegmentId = (state: AppState) => getSegment(state).deletedSegmentId;

export const getIsSegmentCreated = (state: AppState) => getSegment(state).isCreated;

export const getSegmentCreatedId = (state: AppState) => getSegment(state).id;

export const getCurrentBucket = (state: AppState) => {
  return getSegment(state).currentBucket;
};

export const getSentiments = (state: AppState) => getCurrentBucket(state).sentiments;

export const getPersonas = (state: AppState) => getCurrentBucket(state).personas;

export const getSegmentSensitiveSubjects = (state: AppState) => getSegment(state).sensitiveSubjects;

export const getBucketLanguages = (state: AppState) => getCurrentBucket(state).languages;

export const getBucketEntities = (state: AppState) => getCurrentBucket(state).entity;

export const getBucketEmotions = (state: AppState) => getCurrentBucket(state).emotions;

export const getSegmentCategories = (state: AppState) => getCurrentBucket(state).categories;

export const isSegmentCategoriesIncluded = (state: AppState) =>
  getCurrentBucket(state).categories?.isIncluded;

export const getCategoriesDataObject = (state: AppState) =>
  getCurrentBucket(state).categoriesDataObject;

export const getCategoriesValues = (state: AppState) =>
  getSegmentCategories(state).categoriesValues;

export const getCategoriesSearchString = (state: AppState) =>
  getCurrentBucket(state).categoriesSearchString;

export const getSegmentBuckets = (state: AppState) => getSegment(state).buckets;

export const getSegmentBucketsLength = (state: AppState) => getSegmentBuckets(state).length;

export const getPerformanceMetricsSelector = (state: AppState) => getSegment(state).performance;

export const getFiltersSelector = (state: AppState) => {
  return getSegment(state).filters;
};

export const getSegmentAsyncValue = (state: AppState) => getSegment(state).async;

export const getSegmentAccuracyThreshold = (state: AppState) => getSegment(state).accuracyThreshold;
