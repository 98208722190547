import { Select as SelectAntd, SelectProps as SelectAntdProps } from 'antd';
import classNames from 'classnames';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef } from 'react';

import { nanoTwMerge } from 'services/twMerge';

interface SelectProps extends SelectAntdProps {
  large?: boolean;
  invalid?: boolean;
}

export const Select = forwardRef<BaseSelectRef, SelectProps>(
  ({ className, invalid, large, ...rest }: SelectProps, ref) => (
    <SelectAntd
      ref={ref}
      maxTagCount="responsive"
      defaultActiveFirstOption={false}
      popupClassName="nano-select-dropdown"
      className={nanoTwMerge(
        classNames('nano-select', {
          'nano-select__invalid': invalid,
          'nano-select__large': large,
        }),
        className,
      )}
      {...rest}
    />
  ),
);
Select.displayName = 'Select';

export const Option = SelectAntd.Option;
