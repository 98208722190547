import { api } from 'services/api';

import { ALL_LABELS_URL, LABEL_URL } from './constants';
import { LabelRequestPayload } from './types';

const getAllLabelsByCompanyId = (companyId: string) => {
  return api.get(`${ALL_LABELS_URL}/${companyId}`);
};

const createLabel = (labelData: LabelRequestPayload) => {
  return api.post(LABEL_URL, labelData);
};

const deleteLabel = (labelId: string) => {
  return api.delete(`${LABEL_URL}${labelId}`);
};

export const labelsApi = {
  getAllLabelsByCompanyId,
  createLabel,
  deleteLabel,
};
