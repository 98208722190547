import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultGeoLanguages } from '../constants';
import { GeoLanguagesState, Geo } from './types';

const initialState: GeoLanguagesState = {
  allGeoLanguages: defaultGeoLanguages,
  segmentGeoLanguages: defaultGeoLanguages,
  languagesByGeo: [],
  selectedLanguage: '',
  selectedCountry: '',
  isAllGeoLanguagesLoading: false,
  isSegmentGeoLanguagesLoading: false,
  isLanguagesByGeoLoading: false,
};

export const geoLanguagesSlice = createSlice({
  name: 'geoLanguages',
  initialState,
  reducers: {
    setSegmentGeoLanguages: (state: GeoLanguagesState) => {
      state.isSegmentGeoLanguagesLoading = true;
    },
    getSegmentGeoLanguagesSuccess: (state: GeoLanguagesState, { payload }: PayloadAction<Geo>) => {
      state.isSegmentGeoLanguagesLoading = false;
      state.segmentGeoLanguages = payload;
    },
    getSegmentGeoLanguagesFailure: (state: GeoLanguagesState) => {
      state.isSegmentGeoLanguagesLoading = false;
    },
    setSelectedCountry: (state: GeoLanguagesState, { payload }: PayloadAction<string>) => {
      state.selectedCountry = payload;
    },
    resetSelectedCountry: (state: GeoLanguagesState) => {
      state.selectedCountry = '';
    },
    setSelectedLanguage: (state: GeoLanguagesState, { payload }: PayloadAction<string>) => {
      state.selectedLanguage = payload;
    },
    resetSelectedLanguage: (state: GeoLanguagesState) => {
      state.selectedLanguage = '';
    },
  },
});

export const geoLanguages = geoLanguagesSlice.reducer;
