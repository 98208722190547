import { Table as TableAntd, TableProps as TablePropsAntd } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

export type TableProps<T> = TablePropsAntd<T> & {
  accentBorders?: boolean;
};

export const Table = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ className, accentBorders, ...rest }: TableProps<any>) => (
    <TableAntd
      className={classNames(
        'nano-table',
        {
          'nano-table__accent-borders': accentBorders,
        },
        className,
      )}
      {...rest}
    />
  ),
);
Table.displayName = 'Table';
