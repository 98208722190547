import { Search } from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'components/common';

export const EmptyResult = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-y-6 pb-7 pt-5">
      <div className="flex h-[56px] w-[56px] items-center justify-center rounded-full bg-primary-dark-purple-5">
        <Search className="text-primary-dark-purple-40" size={24} />
      </div>
      <Paragraph className="max-w-[252px] text-center text-text-60">
        {t('segment.intent.emptySearch')}
      </Paragraph>
    </div>
  );
};
