import { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Text } from '../../../components/common';
import { SortIcon } from '../../../components/common/icons';
import { DistanceCheckerList } from './distanceCheckerApiTypes';

export type DistanceCheckerTableProps = {
  dataSource: DistanceCheckerList[] | undefined;
};

export const DistanceCheckerTable = ({ dataSource }: DistanceCheckerTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnsType<DistanceCheckerList> = useMemo(
    () => [
      {
        title: t('adminPanel.distanceChecker.table.vectorIndex'),
        dataIndex: 'vectorIndex',
        render: (vectorIndex) => {
          return <Text> {vectorIndex}</Text>;
        },
        sorter: (a, b) => a.vectorIndex - b.vectorIndex,
        sortIcon: () => <SortIcon />,
        width: '10%',
      },
      {
        title: t('adminPanel.distanceChecker.table.distance'),
        dataIndex: 'distance',
        render: (distance) => {
          return <Text> {distance}</Text>;
        },
        sorter: (a, b) => a.distance - b.distance,
        sortIcon: () => <SortIcon />,
        width: '10%',
      },
      {
        title: t('adminPanel.distanceChecker.table.targetType'),
        dataIndex: 'targetType',
        render: (targetType) => {
          return <Text> {targetType}</Text>;
        },
        sorter: (a, b) => a.targetType.localeCompare(b.targetType),
        sortIcon: () => <SortIcon />,
        width: '10%',
      },
      {
        title: t('adminPanel.distanceChecker.table.targetId'),
        dataIndex: 'targetId',
        render: (targetId) => {
          return <Text> {targetId}</Text>;
        },
        sorter: (a, b) => a.targetId.localeCompare(b.targetId),
        sortIcon: () => <SortIcon />,
        width: '10%',
      },
      {
        title: t('adminPanel.distanceChecker.table.keywords'),
        dataIndex: 'keywords',
        render: (keywords) => {
          return <Text> {keywords}</Text>;
        },
        width: '60%',
      },
    ],
    [t],
  );

  return (
    <Table
      rowKey="vectorIndex"
      className="nano-segments-table"
      columns={columns}
      dataSource={dataSource}
    />
  );
};
