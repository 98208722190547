import { api } from 'services/api';

import { USERS_URL, USER_URL } from './constants';
import { RoleAssign, UserRequestValues } from './types';
import { RolesCurrent, UserListItem } from './userApiTypes';

const getUsers = () => {
  return api.get(USERS_URL);
};

const getUserById = (id: string) => {
  return api.get<UserListItem>(`${USER_URL}/${id}`);
};

const getCurrentRole = () => {
  return api.get<RolesCurrent>('roles/current');
};

const assignRole = (id: string, data: RoleAssign) => {
  return api.put(`roles/assign/${id}`, data);
};

const saveUser = (data: UserRequestValues) => {
  return api.post(USER_URL, data);
};

const updateUser = (id: string, data: UserRequestValues) => {
  return api.put(`${USER_URL}/${id}`, data);
};

const deleteUser = (id: string) => {
  return api.delete(`${USER_URL}/${id}`);
};

export const userApi = {
  getUsers,
  getUserById,
  getCurrentRole,
  saveUser,
  updateUser,
  deleteUser,
  assignRole,
};
