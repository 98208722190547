import { localStorageService } from 'services/localStorage';

import { useUserProfileQuery } from '../auth/authApiSlice';

const NANO_TOUR_VISITED_BY_KEY = 'tour_visited_by';

export const useNanoTour = () => {
  const { data: user } = useUserProfileQuery(null);

  const userEmail = user?.email;

  function setVisited() {
    const oldEmails: string | null = localStorageService.getItem(NANO_TOUR_VISITED_BY_KEY);

    const emailsArray = oldEmails ? oldEmails.split(',') : [];

    localStorageService.setItem(
      NANO_TOUR_VISITED_BY_KEY,
      Array.from(new Set([...emailsArray, userEmail])).join(','),
    );
  }

  function hasVisitedTour() {
    const tourVisitedByEmails: string | null =
      localStorageService.getItem(NANO_TOUR_VISITED_BY_KEY);

    if (!tourVisitedByEmails) {
      return false;
    }

    const emails = tourVisitedByEmails.split(',');

    return !(userEmail && !emails.includes(userEmail));
  }

  return {
    hasVisitedTour,
    setVisited,
  };
};
