import ReactECharts from 'echarts-for-react';
import { useMemo } from 'react';

import { Card, CardContent, Title } from 'components/common';

import { DistanceCheckerCategory } from '../distanceCheckerApiTypes';
import { getWeightedCategoriesChartOptions } from './utils';

export type WeightedCategoriesChartProps = {
  data: Array<DistanceCheckerCategory>;
  title: string;
};

export const WeightedCategoriesChart = ({ data, title }: WeightedCategoriesChartProps) => {
  const noData = useMemo(() => data !== null && data.length === 0, [data]);

  const formattedCategories = data;
  const chartOptions = useMemo(
    () => getWeightedCategoriesChartOptions(formattedCategories),
    [formattedCategories],
  );

  if (noData) {
    return null;
  }

  return (
    <Card className="h-full rounded-[20px]">
      <Title level={4}>{title}</Title>
      <CardContent className="flex flex-col items-center text-base overflow-hidden relative mt-auto mb-auto">
        <div className="w-full h-[140px] relative">
          <ReactECharts
            opts={{ renderer: 'svg' }}
            option={chartOptions}
            className="h-full w-full absolute"
          />
        </div>
      </CardContent>
    </Card>
  );
};
