import { localStorageService } from 'services/localStorage';

export const AUTH_TOKEN_KEY = 'auth_token';
export const AUTH_TOKEN_EXPIRATION_DATE = 'auth_token_expiration_date';
export const USER_PROFILE = 'user_profile';

function saveAuthInfo(token: string, expiredAt: Date) {
  localStorageService.setItem(AUTH_TOKEN_KEY, token);
  localStorageService.setItem(AUTH_TOKEN_EXPIRATION_DATE, expiredAt);
}

function getAuthToken() {
  return localStorageService.getItem<string>(AUTH_TOKEN_KEY);
}

function getTokenExpirationDate(): string {
  return localStorageService.getItem<string>(AUTH_TOKEN_EXPIRATION_DATE) as string;
}

function removeAuthToken() {
  localStorageService.removeItem(AUTH_TOKEN_KEY);
  localStorageService.removeItem(AUTH_TOKEN_EXPIRATION_DATE);
}

function clearAuthInfo() {
  removeAuthToken();
  removeUserProfile();
}

function removeUserProfile() {
  localStorageService.removeItem(USER_PROFILE);
}

export const authStorage = {
  getAuthToken,
  saveAuthInfo,
  removeAuthToken,
  getTokenExpirationDate,
  clearAuthInfo,
};
