import { AppState } from 'redux/store/store';

import { geoLanguagesSlice } from './GeoLanguagesSlice';

const geoLanguagesSelector = ({ geoLanguages }: AppState) => geoLanguages;

export const getGeoLanguagesData = (state: AppState) => geoLanguagesSelector(state).allGeoLanguages;

export const getSegmentGeoLanguagesData = (state: AppState) =>
  geoLanguagesSelector(state).segmentGeoLanguages;

export const getAllGeoLanguages = (state: AppState) => geoLanguagesSelector(state).allGeoLanguages;

export const getGeoLanguagesLoading = (state: AppState) =>
  geoLanguagesSelector(state).isAllGeoLanguagesLoading;

export const getSegmentLanguagesByGeo = (state: AppState) =>
  geoLanguagesSelector(state).languagesByGeo;

export const getSelectedLanguage = (state: AppState) =>
  geoLanguagesSelector(state).selectedLanguage;

export const getSelectedCountry = (state: AppState) => geoLanguagesSelector(state).selectedCountry;
export const {
  setSegmentGeoLanguages,
  getSegmentGeoLanguagesSuccess,
  getSegmentGeoLanguagesFailure,
  setSelectedLanguage,
  resetSelectedLanguage,
  setSelectedCountry,
  resetSelectedCountry,
} = geoLanguagesSlice.actions;
