import { Action, PayloadAction } from '@reduxjs/toolkit';
import { call, takeEvery } from 'redux-saga/effects';

import { notificationService } from './notificationService';

function* handleFailureActionsSaga({ type, payload }: PayloadAction<{ message: string }>) {
  if (payload?.message) {
    yield call(notificationService.showError, {
      key: type,
      message: payload.message,
    });
  }
}

export function* notificationSagas() {
  yield takeEvery((action: Action) => /Failure$/.test(action.type), handleFailureActionsSaga);
}
