import Icons from 'feather-icons-react';
import { ComponentProps, memo, ReactElement } from 'react';

import { ButtonProps } from './types';
import { getComputedStyles } from './utils';

type IconNames = keyof typeof Icons;

type IconComponentProps = ComponentProps<(typeof Icons)[IconNames]>;

type IconComponent<T extends IconNames> = ReactElement<IconComponentProps, T>;

interface IconButtonProps extends ButtonProps {
  children: IconComponent<IconNames>;
}

export const IconButton = memo(
  ({
    children,
    className,
    disabled,
    size = 'medium',
    variant = 'primary',
    ...rest
  }: IconButtonProps) => (
    <button
      className={getComputedStyles({ disabled, size, variant, isSquare: true, className })}
      {...rest}
    >
      {children}
    </button>
  ),
);
IconButton.displayName = 'IconButton';
