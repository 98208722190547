import { RefObject, useEffect, useRef, useState } from 'react';

import {
  calculateElementRelativeRect,
  convertAbsoluteToRelativeCoords,
  groupToPairs,
} from './utils';

const arrowIconOffset = {
  x: -25,
  y: -5,
};

export const useLeftScaleCutout = (parentRef: RefObject<HTMLDivElement>) => {
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [coords, setCoords] = useState<string[]>([]);
  const [arrowCoords, setArrowCoords] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (!parentRef.current) {
      return;
    }

    const htmlElements = refs.current.filter((el): el is HTMLDivElement => !!el);

    const [firstEl] = htmlElements;
    const lastEl = htmlElements[htmlElements.length - 1];

    const parentRect = parentRef?.current.getBoundingClientRect();

    const firstElRect = calculateElementRelativeRect(parentRect, firstEl.getBoundingClientRect());
    const lastElRect = calculateElementRelativeRect(parentRect, lastEl.getBoundingClientRect());

    const STEP_INCREMENT = firstElRect.width / htmlElements.length;

    let increment = STEP_INCREMENT;

    const elements = htmlElements
      .map((el, index) => {
        const childRect = el?.getBoundingClientRect();
        const relChildRect = convertAbsoluteToRelativeCoords(parentRect, childRect);

        const elDimensions = {
          top: relChildRect.top,
          left: relChildRect.left,
          width: childRect.width,
          height: childRect.height,
        };

        if (index > 0) {
          // start increasing the right shift from 2nd element
          elDimensions.left = Math.min(elDimensions.left + increment, firstElRect.width);
          increment += STEP_INCREMENT;
        }

        if (index === htmlElements.length - 1) {
          return [
            elDimensions.left,
            elDimensions.top,
            elDimensions.width,
            elDimensions.top + elDimensions.height,
          ];
        }

        return [
          elDimensions.left,
          elDimensions.top,
          elDimensions.left,
          elDimensions.top + elDimensions.height,
        ];
      })
      .flat(1);

    const res = [...elements, firstElRect.width, firstElRect.top];

    setCoords(groupToPairs(res));
    setArrowCoords([
      lastElRect.width + arrowIconOffset.x,
      lastElRect.top + lastElRect.height + arrowIconOffset.y,
    ]);
  }, [refs, parentRef]);

  return {
    coords,
    refs,
    parentRef,
    arrowCoords,
  };
};
