import { api } from 'services/api';

import { CUSTOM_CATEGORIES, CUSTOM_CATEGORIES_URL, KEYWORD_GROUPS } from './constants';

const getCustomCategories = () => {
  return api.get(CUSTOM_CATEGORIES_URL);
};

const getKeywordGroups = () => {
  return api.get(KEYWORD_GROUPS);
};

const getCustomCategoryById = (id: string) => {
  return api.get(`${CUSTOM_CATEGORIES}/${id}`);
};

const saveCustomCategory = (data: unknown) => api.post(`${CUSTOM_CATEGORIES}/`, data);

const updateCustomCategory = (id: string, data: unknown) => {
  return api.put(`${CUSTOM_CATEGORIES}/${id}`, data);
};

const deleteCustomCategory = (id: string) => {
  return api.delete(`${CUSTOM_CATEGORIES}/${id}`);
};

export const customCategoriesApi = {
  getCustomCategories,
  deleteCustomCategory,
  saveCustomCategory,
  updateCustomCategory,
  getCustomCategoryById,
  getKeywordGroups,
};
