import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { put } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

export function* handleErrorSaga(
  error: unknown,
  failureActionCreator: ActionCreatorWithPayload<{ message?: string | null; status?: number }>,
  message: string | null,
) {
  if (error instanceof AxiosError) {
    const axiosError = error;
    const status = axiosError.response?.status || axiosError.status;

    notifyError(error);
    yield put(failureActionCreator({ message, status }));
  }
}
