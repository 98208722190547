import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { segmentGroupListApiSlice } from './list/SegmentGroupListApiSlice';
import { segmentGroupApi } from './segmentGroupApi';
import { SegmentGroupRequestData, SegmentGroupResponseData } from './segmentGroupApiTypes';
import { getSegmentGroup, getSegmentGroupToDeleteId } from './segmentGroupSelectors';
import {
  deleteSegmentGroup,
  deleteSegmentGroupFailure,
  deleteSegmentGroupSuccess,
  saveSegmentGroup,
  saveSegmentGroupFailure,
  saveSegmentGroupSuccess,
  updateSegmentGroup,
  updateSegmentGroupFailure,
  updateSegmentGroupSuccess,
} from './segmentGroupSlice';
import { SegmentGroupState } from './types';

function* saveSegmentGroupSaga() {
  try {
    const segmentGroup: SegmentGroupState = yield select(getSegmentGroup);
    const segmentGroupData: SegmentGroupRequestData = {
      groupName: segmentGroup.groupName,
      ssp: segmentGroup.ssp,
      sspGroupId: segmentGroup.sspGroupId,
      price: Number(segmentGroup.price),
    };
    const response: SegmentGroupResponseData = yield call(
      segmentGroupApi.saveSegmentGroup,
      segmentGroupData,
    );

    yield put(saveSegmentGroupSuccess(response));
    yield put(segmentGroupListApiSlice.util.invalidateTags(['SegmentGroup']));
  } catch (e) {
    notifyError(e);

    yield put(
      saveSegmentGroupFailure({
        message: i18next.t('adminPanel.segmentGroup.errors.segmentGroupSaveFailed'),
      }),
    );
  }
}

function* deleteSegmentGroupSaga() {
  try {
    const segmentGroupToDeleteId: string = yield select(getSegmentGroupToDeleteId);

    yield call(segmentGroupApi.deleteSegmentGroup, segmentGroupToDeleteId);

    yield put(deleteSegmentGroupSuccess());
    yield put(segmentGroupListApiSlice.util.invalidateTags(['SegmentGroup']));
  } catch (e) {
    notifyError(e);

    yield put(
      deleteSegmentGroupFailure({
        message: i18next.t('adminPanel.segmentGroup.errors.segmentGroupDeleteFailed'),
      }),
    );
  }
}

function* updateSegmentGroupSaga() {
  try {
    const segmentGroup: SegmentGroupState = yield select(getSegmentGroup);

    const data: SegmentGroupRequestData = {
      groupName: segmentGroup.groupName,
      ssp: segmentGroup.ssp,
      sspGroupId: segmentGroup.sspGroupId,
      price: Number(segmentGroup.price),
    };

    yield call(segmentGroupApi.updateSegmentGroup, segmentGroup.id, data);

    yield put(segmentGroupListApiSlice.util.invalidateTags(['SegmentGroup']));
    yield put(updateSegmentGroupSuccess());
  } catch (e) {
    notifyError(e);

    yield put(
      updateSegmentGroupFailure({
        message: i18next.t('adminPanel.segmentGroup.errors.segmentGroupUpdateFailed'),
      }),
    );
  }
}

export function* segmentGroupSagas() {
  yield all([takeLatest(saveSegmentGroup.type, saveSegmentGroupSaga)]);
  yield all([takeLatest(deleteSegmentGroup.type, deleteSegmentGroupSaga)]);
  yield all([takeLatest(updateSegmentGroup.type, updateSegmentGroupSaga)]);
}
