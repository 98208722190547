import { api } from 'services/api';

import { SearchByVectorRequest, SearchByVectorResponse } from './types';

const searchByVector = (data: SearchByVectorRequest) => {
  return api.post<SearchByVectorResponse>('vector-search/', data);
};

export const searchByVectorApi = {
  searchByVector,
};
