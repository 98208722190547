import { nanoApi } from 'redux/rootApiSlice';

import { Role } from '../adminPanel/users/types';
import { CURRENT_ROLE, USER_PROFILE } from './constants';
import { User } from './types';

export const authApiSlice = nanoApi.injectEndpoints({
  endpoints: (build) => ({
    userProfile: build.query<User, null>({
      query: () => ({ method: 'GET', url: USER_PROFILE }),
    }),
    currentRole: build.query<Role, null>({
      query: () => ({ method: 'GET', url: CURRENT_ROLE }),
    }),
  }),
});

export const { useUserProfileQuery, useCurrentRoleQuery } = authApiSlice;
export const selectCurrentRole = authApiSlice.endpoints.currentRole.select(null);
