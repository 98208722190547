/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useCallback, useEffect } from 'react';

export const usePreboundCallback = (fn: any, dependencies: any[] = []) => {
  const fnRef = useRef(fn);

  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  return useCallback(
    (...args: any[]) => {
      return () => {
        if (fnRef.current) {
          return fnRef.current(...args);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies],
  );
};
