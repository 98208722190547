import { useDispatch, useSelector } from 'react-redux';

import { loginAction, logoutAction, getAuthToken } from './authSlice';
import { authStorage } from './authStorage';
import { Credentials } from './types';

export const useAuth = () => {
  const dispatch = useDispatch();
  const tokenExpirationDate = new Date(authStorage.getTokenExpirationDate());

  const token = useSelector(getAuthToken);

  function login({ email, password }: Credentials) {
    dispatch(loginAction({ email, password }));
  }

  function logout() {
    dispatch(logoutAction());
  }

  function isTokenExpired() {
    const currentTime = new Date();

    return currentTime > tokenExpirationDate;
  }

  const isLoggedIn = Boolean(token);

  return {
    login,
    logout,
    isLoggedIn,
    isTokenExpired,
  };
};
