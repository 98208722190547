import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { IntentConfirmationData, IntentConfirmationState } from './types';

const initialState: IntentConfirmationState = {
  buckets: [],
  isLoading: false,
};

export const intentConfirmationSlice = createSlice({
  name: 'intentConfirmation',
  initialState,
  reducers: {
    resetIntentConfirmationBuckets: (state: IntentConfirmationState) => {
      state.buckets = [];
    },
    setIntentConfirmationLoading: (state: IntentConfirmationState) => {
      state.isLoading = true;
    },
    getIntentConfirmationSuccess: (
      state: IntentConfirmationState,
      { payload: { buckets } }: PayloadAction<IntentConfirmationData>,
    ) => {
      state.isLoading = false;
      if (!buckets) {
        state.buckets = [];
      } else {
        state.buckets = buckets.filter((el) => +el.totalReach > 0);
      }
    },
    getIntentConfirmationFailure: (state: IntentConfirmationState) => {
      state.isLoading = false;
      state.buckets = [];
    },
  },
});

const intentConfirmationSelector = ({ intentConfirmation }: AppState) => intentConfirmation;

export const getIntentConfirmationBuckets = (state: AppState) =>
  intentConfirmationSelector(state).buckets;

export const getIntentConfirmationLoading = (state: AppState) =>
  intentConfirmationSelector(state).isLoading;

export const {
  resetIntentConfirmationBuckets,
  setIntentConfirmationLoading,
  getIntentConfirmationSuccess,
  getIntentConfirmationFailure,
} = intentConfirmationSlice.actions;

export const intentConfirmation = intentConfirmationSlice.reducer;
