import { SSP } from 'modules/segment/types';

import { Category } from '../apiData/types';
import { SegmentListItem } from '../segment/segmentApiTypes';
import { nanoAdminPanelApi } from './adminPanelApiSlice';

const adminPanelApiDataApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    ssp: build.query<SSP[], null>({
      query: () => ({ method: 'GET', url: 'ssp/getAll' }),
      providesTags: [{ type: 'AdminPanelApiData', id: 'Ssp' }],
    }),
    segmentsList: build.query<SegmentListItem[], null>({
      query: () => ({ method: 'GET', url: 'segment/getAll' }),
      providesTags: [{ type: 'AdminPanelApiData', id: 'Segments' }],
    }),
    categories: build.query<Category[], null>({
      query: () => ({ method: 'GET', url: 'categories/getAll' }),
      providesTags: [{ type: 'AdminPanelApiData', id: 'Categories' }],
    }),
  }),
});

export const { useSspQuery, useCategoriesQuery, useSegmentsListQuery } = adminPanelApiDataApiSlice;
