import { IconProps } from 'feather-icons-react';

export const DropdownArrowRight = ({ className, fill = 'currentColor', size = 16 }: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5927 8.51864L5.92601 13.1853L4.88892 12.1482L9.03704 8.00009L4.88892 3.85197L5.92601 2.81488L10.5927 7.48155L11.1112 8.00009L10.5927 8.51864Z"
        fill="#8C48BF"
      />
    </svg>
  );
};
