import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dialogs from './dialogComponents';
import { closeDialog, getDialogName, getDialogProps } from './dialogSlice';
import { DialogNames } from './types';

interface DialogWrapperProps {
  id: string;
}

export const DialogWrapper = ({ id }: DialogWrapperProps) => {
  const dispatch = useDispatch();
  const componentName = useSelector(getDialogName(id)) as DialogNames;
  const componentProps = useSelector(getDialogProps(id));
  const Component = dialogs[componentName];

  const handleClose = useCallback(() => {
    dispatch(closeDialog(id));
  }, [dispatch, id]);

  return <Component open onClose={handleClose} {...componentProps} />;
};
