import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'services/api';

export const nanoAdminPanelApi = createApi({
  reducerPath: 'nanoAdminPanelApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'AdminPanelApiData',
    'Company',
    'KeywordGroups',
    'Team',
    'CustomCategories',
    'Users',
    'Roles',
    'ExclusionList',
    'AuditLog',
    'ExpiredDate',
    'SearchByVector',
    'SegmentGroup',
    'DistanceChecker',
    'DomainList',
  ],
  endpoints: () => ({}),
});
