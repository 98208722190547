import { IconProps } from 'feather-icons-react';

export const LongArrowIcon = ({ className, fill = 'currentColor' }: Omit<IconProps, 'size'>) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="12"
    viewBox="0 0 47 12"
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M38 5V0L47 6L38 12V7H0V5H38Z" fill={fill} />
  </svg>
);
