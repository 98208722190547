import { Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Search } from 'feather-icons-react';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Input, Paragraph, Text } from 'components/common';

import { useLanguages } from './useLanguage';

export const Language = () => {
  const { t } = useTranslation();

  const {
    handleSelectAllChecked,
    searchQuery,
    setSearchQuery,
    filteredLanguages,
    defaultLanguages,
    languagesValues,
    handleLanguageChange,
  } = useLanguages();

  const allLanguagesCount = defaultLanguages.length + filteredLanguages.length;
  const isAllLanguagesSelected =
    Boolean(languagesValues.length) && languagesValues.length === allLanguagesCount;

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery],
  );

  const handleSelectAll = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent) => {
      handleSelectAllChecked(checked);
    },
    [handleSelectAllChecked],
  );

  return (
    <div className="w-1/2 h-[420px]">
      <Paragraph className="text-base-l font-medium">
        {t('segment.language.chooseLanguage')}
      </Paragraph>
      <Paragraph className="my-4">{t('segment.language.description')}</Paragraph>

      <Input
        value={searchQuery}
        addonBefore={<Search />}
        className="mb-6 max-w-[520px]"
        placeholder={t('segment.language.searchPlaceholder')}
        onChange={handleSearchChange}
      />

      <Checkbox checked={isAllLanguagesSelected} onChange={handleSelectAll}>
        {t(`segment.language.${isAllLanguagesSelected ? 'unselectAll' : 'selectAll'}`)}
        {languagesValues.length > 0 && <Text> ({languagesValues.length})</Text>}
      </Checkbox>
      <Divider className="my-4" />

      <Paragraph className="mb-3 font-medium">{t('segment.language.top5')}</Paragraph>
      <div className="max-h-[310px] overflow-y-auto">
        {defaultLanguages.map(({ id, iso, language }) => (
          <Checkbox
            key={id}
            className="py-2"
            value={iso}
            checked={languagesValues.includes(iso)}
            onChange={() => handleLanguageChange(iso)}
          >
            <Text>{language}</Text>
            <Text className=" ml-1 text-text-20">({iso.toUpperCase()})</Text>
          </Checkbox>
        ))}
        <Divider className="my-4" />
        {filteredLanguages.map(({ id, iso, language }) => (
          <Checkbox
            key={id}
            className="py-2"
            value={iso}
            checked={languagesValues.includes(iso)}
            onChange={() => handleLanguageChange(iso)}
          >
            <Text>{language}</Text>
            <Text className=" ml-1 text-text-20">({iso.toUpperCase()})</Text>
          </Checkbox>
        ))}
      </div>
    </div>
  );
};
