import { SegmentGroupForm } from 'modules/adminPanel/segmentGroup/forms/segmentGroupForm';

import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface SegmentGroupFormDialogProps extends DialogBaseProps {
  title: string;
  id?: string;
  groupName?: string;
  ssp?: string;
  sspGroupId?: string;
  price?: number | null;
}

export const EditSegmentGroupFormDialog = ({
  open,
  title,
  onClose,
  id,
  groupName,
  ssp,
  sspGroupId,
  price,
}: SegmentGroupFormDialogProps) => {
  return (
    <NanoDialog open={open} title={title} width={616}>
      <SegmentGroupForm
        onClose={onClose}
        id={id}
        groupName={groupName}
        ssp={ssp}
        sspGroupId={sspGroupId}
        price={price}
      />
    </NanoDialog>
  );
};
