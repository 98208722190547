import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { exclusionListApi } from './exclusionListApi';
import { ExclusionListCreateManyItemsRequest } from './exclusionListApiTypes';
import { getExclusionList, getExclusionListToDeleteId } from './exclusionListSelectors';
import {
  deleteAllExclusionListItems,
  deleteExclusionListFailure,
  deleteExclusionListItem,
  deleteExclusionListSuccess,
  saveExclusionList,
  saveExclusionListFailure,
  saveExclusionListSuccess,
} from './exclusionListSlice';
import { exclusionListApiSlice } from './list/exclusionListApiSlice';
import { ExclusionListState } from './types';

function* saveManyExclusionListItems() {
  try {
    const exclusionList: ExclusionListState = yield select(getExclusionList);
    const exclusionListItemData: ExclusionListCreateManyItemsRequest = {
      urls: exclusionList.urls,
    };

    yield call(exclusionListApi.createManyExclusionListItems, exclusionListItemData);

    yield put(saveExclusionListSuccess());
    yield put(exclusionListApiSlice.util.invalidateTags(['ExclusionList']));
  } catch (e) {
    notifyError(e);
    yield put(
      saveExclusionListFailure({
        message: i18next.t('adminPanel.exclusionList.errors.exclusionListSaveFailed'),
      }),
    );
  }
}

function* deleteOneExclusionListItem() {
  try {
    const itemToDelete: string = yield select(getExclusionListToDeleteId);

    yield call(exclusionListApi.deleteExclusionListItem, itemToDelete);

    yield put(deleteExclusionListSuccess());
    yield put(exclusionListApiSlice.util.invalidateTags(['ExclusionList']));
  } catch (e) {
    notifyError(e);
    yield put(
      deleteExclusionListFailure({
        message: i18next.t('adminPanel.exclusionList.errors.exclusionListItemDeleteFailed'),
      }),
    );
  }
}

function* clearExclusionList() {
  try {
    yield call(exclusionListApi.deleteAllExclusionListItems);

    yield put(deleteExclusionListSuccess());
    yield put(exclusionListApiSlice.util.invalidateTags(['ExclusionList']));
  } catch (e) {
    notifyError(e);
    yield put(
      deleteExclusionListFailure({
        message: i18next.t('adminPanel.exclusionList.errors.exclusionListItemDeleteFailed'),
      }),
    );
  }
}

export function* exclusionListSagas() {
  yield all([takeLatest(saveExclusionList.type, saveManyExclusionListItems)]);
  yield all([takeLatest(deleteExclusionListItem.type, deleteOneExclusionListItem)]);
  yield all([takeLatest(deleteAllExclusionListItems.type, clearExclusionList)]);
}
