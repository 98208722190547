import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { SentimentDistributionData, SentimentDistributionState } from './types';

const initialState: SentimentDistributionState = {
  sentimentDistributionData: {},
  isLoading: false,
};

export const sentimentDistributionSlice = createSlice({
  name: 'sentimentDistribution',
  initialState,
  reducers: {
    setSentimentDistributionLoading: (state: SentimentDistributionState) => {
      state.isLoading = true;
    },
    getSentimentDistributionSuccess: (
      state: SentimentDistributionState,
      { payload }: PayloadAction<SentimentDistributionData>,
    ) => {
      state.isLoading = false;
      state.sentimentDistributionData = payload;
    },
    getSentimentDistributionFailure: (state: SentimentDistributionState) => {
      state.isLoading = false;
    },
    resetSentimentDistributionData: (state: SentimentDistributionState) => {
      state.sentimentDistributionData = {};
      state.isLoading = false;
    },
  },
});

const sentimentDistributionSelector = ({ sentimentDistribution }: AppState) =>
  sentimentDistribution;

export const getSentimentDistributionData = (state: AppState) =>
  sentimentDistributionSelector(state).sentimentDistributionData;

export const getSentimentDistributionLoading = (state: AppState) =>
  sentimentDistributionSelector(state).isLoading;

export const {
  setSentimentDistributionLoading,
  getSentimentDistributionSuccess,
  getSentimentDistributionFailure,
  resetSentimentDistributionData,
} = sentimentDistributionSlice.actions;

export const sentimentDistribution = sentimentDistributionSlice.reducer;
