import { useTranslation } from 'react-i18next';

import { Text } from 'components/common';
import { NanoBotImg } from 'components/common/nanoBot';

export const NanoBotHeader = () => {
  const { t } = useTranslation();

  return (
    <header className="flex px-[24px] py-[32px]">
      <div className="mr-[10px]">
        <NanoBotImg className="h-[44px] w-[44px]" />
      </div>
      <div>
        <div className="text-text-5">{t('nanoBot.title')}</div>
        <div className="flex items-center justify-center">
          <div className="mr-[5px] h-[8px] w-[8px] rounded-full bg-secondary-teal" />
          <Text className="text-text-20">{t('nanoBot.status')}</Text>
        </div>
      </div>
    </header>
  );
};

export default NanoBotHeader;
