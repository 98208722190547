import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { DOMAIN_LIST_URL } from '../constants';
import { DomainListListItem } from '../domainListApiTypes';

export const domainListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    domainList: build.query<DomainListListItem[], null>({
      query: () => ({ method: 'GET', url: `${DOMAIN_LIST_URL}/all` }),
      providesTags: ['DomainList'],
    }),
  }),
});

export const { useDomainListQuery } = domainListApiSlice;
