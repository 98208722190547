import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyCreateValues, CompanyState } from './types';

export const COMPANY_SLICE_NAME = 'company';

export const initialState: CompanyState = {
  id: '',
  name: '',
  address: '',
  creationDate: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  companyToDeleteId: '',
};

export const companySlice = createSlice({
  name: COMPANY_SLICE_NAME,
  initialState,
  reducers: {
    saveCompany: (state: CompanyState) => {
      state.isLoading = true;
    },
    updateValues: (state: CompanyState, { payload }: PayloadAction<CompanyCreateValues>) => {
      return {
        ...state,
        ...payload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveCompanyFailure: (state: CompanyState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
    },
    saveCompanySuccess: (state: CompanyState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
      state.creationDate = payload.creationDate;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteCompany: (state: CompanyState, { payload }: PayloadAction<{ id: string }>) => {
      state.isLoading = true;
    },
    deleteCompanySuccess: (state: CompanyState) => {
      state.isLoading = false;
      state.companyToDeleteId = '';
      state.isCreated = false;
    },
    deleteCompanyFailure: (
      state: CompanyState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    setCompanyToDeleteId: (state: CompanyState, { payload }: PayloadAction<string>) => {
      state.companyToDeleteId = payload;
    },
    clearCompany: () => initialState,
    updateCompany: (state: CompanyState) => {
      state.isLoading = true;
    },
    updateCompanySuccess: (state: CompanyState) => {
      state.isLoading = false;
      state.isCreated = true;
    },
    updateCompanyFailure: (
      state: CompanyState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    setCompanyToUpdateId: (state: CompanyState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
  },
});

export const {
  updateValues,
  saveCompanyFailure,
  saveCompany,
  saveCompanySuccess,
  deleteCompany,
  deleteCompanySuccess,
  deleteCompanyFailure,
  setCompanyToDeleteId,
  clearCompany,
  updateCompany,
  updateCompanySuccess,
  updateCompanyFailure,
  setCompanyToUpdateId,
} = companySlice.actions;

export const company = companySlice.reducer;
