import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { updateValuesRole } from './assignRoleSlice';
import { deleteUserSaga } from './sagas/userDeleteSaga';
import { saveUserSaga } from './sagas/userSaveSaga';
import { updateUserSaga } from './sagas/userUpdateSaga';
import { UserRequestValues, UserResponse } from './types';
import { userApi } from './usersApi';
import {
  deleteUser,
  fetchUserDetails,
  fetchUserDetailsFailure,
  fetchUserSuccess,
  saveUser,
  updateUser,
  updateValues,
} from './usersSlice';

function* getUserDetailsSaga({
  payload: { id, navPath },
}: PayloadAction<{ id: string; navPath: string }>) {
  try {
    const userDetailsResponse: UserResponse = yield call(userApi.getUserById, id);
    const label = userDetailsResponse.labels.map((item) => item.name);
    const userDetails: UserRequestValues = { ...userDetailsResponse, labels: label };

    yield put(updateValues(userDetails));
    yield put(updateValuesRole(userDetailsResponse.role));
    yield put(fetchUserSuccess({ navPath }));
  } catch (error) {
    yield put(
      fetchUserDetailsFailure({
        message: i18next.t('adminPanel.user.errors.userDetailsFetchFailed'),
      }),
    );
    notifyError(error);
  }
}

export function* userSagas() {
  yield all([
    takeLatest(saveUser.type, saveUserSaga),
    takeLatest(deleteUser.type, deleteUserSaga),
    takeLatest(updateUser.type, updateUserSaga),
    takeEvery(fetchUserDetails.type, getUserDetailsSaga),
  ]);
}
