import AngerImg from 'assets/images/emojis/anger.png';
import FearImg from 'assets/images/emojis/fear.png';
import JoyImg from 'assets/images/emojis/joy.png';
import LoveImg from 'assets/images/emojis/love.png';
import SadnessImg from 'assets/images/emojis/sadness.png';
import SurpriseImg from 'assets/images/emojis/surprise.png';

import { Emotion, EmotionTargetOption } from './types';

const emotionsIcons: { [key: string]: string } = {
  love: LoveImg,
  joy: JoyImg,
  surprise: SurpriseImg,
  anger: AngerImg,
  fear: FearImg,
  sadness: SadnessImg,
};

export const getEmotionsIcons = (emotions: Emotion[]) => {
  return emotions.map(({ emotion }) => ({ key: emotion, icon: emotionsIcons[emotion] }));
};

export const getEmotionsTargetOptionsIcons = (emotions: EmotionTargetOption[]) => {
  return emotions.map((value) => ({ key: value, icon: emotionsIcons[value] }));
};
