import { Navigate } from 'react-router';

import { authStorage } from 'modules/auth/authStorage';
import { useAuth } from 'modules/auth/useAuth';

import { localStorageService } from 'services/localStorage';
import { VISIT_KEY } from 'services/localStorage/constants';

import { ROUTES } from '../constants';

type ProtectedRouteProps = {
  children: JSX.Element;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isLoggedIn, isTokenExpired } = useAuth();

  if (isLoggedIn && isTokenExpired()) {
    authStorage.clearAuthInfo();
    localStorageService.setItem(VISIT_KEY, true);
  }

  if (!isLoggedIn) {
    return <Navigate replace to={ROUTES.WELCOME} />;
  }

  return children;
};
