import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { EditableTagsList } from 'components/TagsList';

import { notificationService } from 'modules/notifications';
import { INTENT_PROMPT_LIMIT, KEYWORDS_LIMIT } from 'modules/segment/segmentForms/constants';

import { useKeywords } from '../../useKeywords';

type KeywordsEntryProps = {
  intentPrompt: boolean;
};

export const ManualKeywordsEntry = ({ intentPrompt }: KeywordsEntryProps) => {
  const { t } = useTranslation();
  const {
    uploadedValuesCount,
    manualKeywordsCount,
    keywordsValues,
    allKeywords,
    isIncluded,
    addKeywords,
    clearAll,
    removeSingleKeyword,
  } = useKeywords();

  const handleRemoveKeyword = useCallback(
    (keyword: string) => {
      removeSingleKeyword(keyword);
    },
    [removeSingleKeyword],
  );

  const handleFormSubmit = (keywordsString: string) => {
    const KEYWORDS_INTENT_PROMPT_LIMIT = intentPrompt ? INTENT_PROMPT_LIMIT : KEYWORDS_LIMIT;
    const exceededLimit = intentPrompt
      ? t('segment.intent.exceededLimit')
      : t('segment.keywords.exceededLimit');

    const keywords = keywordsString
      .split('\n')
      .filter(Boolean)
      .map((i) => i.trim());

    if (
      uploadedValuesCount + manualKeywordsCount + keywords.length >
      KEYWORDS_INTENT_PROMPT_LIMIT
    ) {
      notificationService.showError({
        message: t(exceededLimit, {
          limit: KEYWORDS_INTENT_PROMPT_LIMIT,
        }),
      });

      return;
    }

    const duplicates = keywords.filter((keyword) => allKeywords.includes(keyword));

    const duplicatesMessage =
      duplicates.length > 10
        ? `${duplicates.slice(0, 10).join(', ')} ${
            intentPrompt
              ? t('segment.intent.andMore', { more: duplicates.length - 10 })
              : t('segment.keywords.andMore', { more: duplicates.length - 10 })
          }`
        : duplicates.join(', ');

    if (duplicates.length) {
      notificationService.showError({
        message: t(intentPrompt ? 'segment.intent.duplicates' : 'segment.keywords.duplicates', {
          duplicates: duplicatesMessage,
        }),
      });
    }

    const deduplicated = keywords.filter((keyword) => !duplicates.includes(keyword));

    addKeywords(deduplicated);
  };

  return (
    <EditableTagsList
      title={
        intentPrompt
          ? t('segment.keywords.manuallyEnteredIntentPrompts')
          : t('segment.keywords.manuallyEnteredKeywords')
      }
      inputPlaceholder={
        intentPrompt
          ? t('segment.keywords.intentPromptInputPlaceholder')
          : t('segment.keywords.keywordInputPlaceholder')
      }
      onFormSubmit={handleFormSubmit}
      tagsItems={keywordsValues}
      showExport={true}
      isIncluded={isIncluded}
      onClear={handleRemoveKeyword}
      onClearAll={clearAll}
    />
  );
};
