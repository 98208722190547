import { Middleware } from '@reduxjs/toolkit';

import { NEW_SEGMENT_ROOT } from 'routing/constants';

import {
  addKeywords,
  addUploadedKeywords,
  changeSegmentMode,
  clearAllKeywords,
  deleteBucket,
  removeEntityTag,
  removeKeyword,
  removeUploadedKeywords,
  saveCurrentBucket,
  setCategories,
  setCurrentBucket,
  setEmotions,
  setEntity,
  setLanguages,
  setPersonas,
  setSensitiveSubjects,
  toggleCategoryNodeCheck,
  toggleIncluded,
  toggleSentiment,
  updateValues,
} from './segmentSlice';
import { segmentStorage } from './segmentStorage';

const SavingActionsTypesSet = new Set([
  changeSegmentMode.type,
  setCurrentBucket.type,
  saveCurrentBucket.type,
  removeUploadedKeywords.type,
  deleteBucket.type,
  updateValues.type,
  toggleIncluded.type,
  addKeywords.type,
  addUploadedKeywords.type,
  clearAllKeywords.type,
  toggleSentiment.type,
  setEntity.type,
  setEmotions.type,
  removeKeyword.type,
  setSensitiveSubjects.type,
  setPersonas.type,
  setLanguages.type,
  setCategories.type,
  removeEntityTag.type,
  toggleCategoryNodeCheck.type,
]);

export const segmentSaveMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  if (SavingActionsTypesSet.has(action.type)) {
    // It would be better to get the path from the state, but we use it only once and only here
    const { pathname } = window.location;
    const isNewSegmentPage = pathname.includes(NEW_SEGMENT_ROOT);

    if (isNewSegmentPage) {
      const { segment } = store.getState();

      segmentStorage.saveSegment(segment);
    }
  }

  return result;
};
