import { all } from 'redux-saga/effects';

import { auditLogSagas } from './auditLog/auditLogSagas';
import { companySagas } from './company/companySagas';
import { contextaSagas } from './contexta/contextaSagas';
import { customCategoriesSagas } from './customCategories/customCategorySagas';
import { emptyCustomCategorySagas } from './customCategories/emptyCustomCategories/emptyCustomCategoriesSagas';
import { distanceCheckerSagas } from './distanceChecker/distanceCheckerSagas';
import { scrapeUrlSagas } from './distanceChecker/scrapeUrl/scrapeUrlSagas';
import { domainListSagas } from './domainList/domainListSagas';
import { exclusionListSagas } from './exclusionList/exclusionListSagas';
import { keywordGroupSaga } from './keywordGroup/keywordGroupSagas';
import { searchByVectorSagas } from './searchByVector/searchByVectorSagas';
import { segmentGroupSagas } from './segmentGroup/segmentGroupSagas';
import { teamSagas } from './team/teamSagas';
import { userSagas } from './users/userSagas';

export function* adminPanelSaga() {
  yield all([
    companySagas(),
    keywordGroupSaga(),
    customCategoriesSagas(),
    emptyCustomCategorySagas(),
    userSagas(),
    teamSagas(),
    exclusionListSagas(),
    auditLogSagas(),
    searchByVectorSagas(),
    segmentGroupSagas(),
    distanceCheckerSagas(),
    scrapeUrlSagas(),
    contextaSagas(),
    domainListSagas(),
  ]);
}
