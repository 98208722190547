import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { segmentsListApiSlice } from '../list/segmentsListApiSlice';
import { segmentsApi } from '../segmentApi';
import { SegmentDetailsResponse } from '../segmentApiTypes';
import { setIsSegmentLoading, setSegmentToCopyId } from '../segmentSlice';
import { SegmentStatuses } from '../types';

export function* copySegmentSaga({
  payload: { id, copyText },
}: PayloadAction<{ id: string; copyText: string }>) {
  try {
    yield put(setIsSegmentLoading(true));
    yield put(setSegmentToCopyId(id));
    const segment: SegmentDetailsResponse = yield call(segmentsApi.getSegmentById, id);
    const name = `${segment.name} ${copyText}`;
    const newSegment = {
      ...segment,
      name,
      id: null,
      status: SegmentStatuses.planning,
      dspSeatId: null,
      sspDealId: null,
      sspSegmentId: null,
      sspSeatId: null,
      dsp: null,
      labels: segment.labels.map(({ name }) => name),
    };

    yield call(segmentsApi.saveSegment, newSegment);
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(setIsSegmentLoading(false));
    yield put(setSegmentToCopyId(null));
  } catch (error) {
    notifyError(error);
    yield put(setIsSegmentLoading(false));
    yield put(setSegmentToCopyId(null));
  }
}
