import { BaseQueryFn } from '@reduxjs/toolkit/query';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { addAdminPanelSpecificErrorInterceptor } from './adminPanelSpecificErrorInterceptor';
import { addRequestInterceptor } from './requestInterceptor';
import { addResponseInterceptor } from './responseInterceptor';

// FYI: the authorization is done via token in the cookies so we should not handle tokens at all
const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  withCredentials: true,
});

type AxiosConfig = AxiosRequestConfig;

async function get<T>(url: string, config?: AxiosConfig): Promise<T> {
  return axiosInstance.get<T>(url, { ...config }).then(extractData);
}

async function post<R>(url: string, data?: AxiosConfig['data'], config?: AxiosConfig): Promise<R> {
  return axiosInstance.post(url, data, config).then(extractData);
}

async function put<R>(url: string, data?: AxiosConfig['data'], config?: AxiosConfig): Promise<R> {
  return axiosInstance.put(url, data, config).then(extractData);
}

async function httpDelete<R>(url: string, config?: AxiosConfig): Promise<R> {
  return axiosInstance.delete(url, config).then(extractData);
}

async function patch<R>(url: string, config?: AxiosConfig): Promise<R> {
  return axiosInstance.patch(url, config).then(extractData);
}

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
      });

      return { data: result.data };
    } catch (axiosError) {
      return {
        error: axiosError,
      };
    }
  };

addResponseInterceptor(axiosInstance);
addRequestInterceptor(axiosInstance);
addAdminPanelSpecificErrorInterceptor(axiosInstance);
const extractData = (resp: AxiosResponse) => resp.data;

export const api = {
  get,
  post,
  put,
  delete: httpDelete,
  patch,
};
