import { AppState } from 'redux/store/store';
import { createSelector } from 'reselect';

export const getAudit = ({ auditLog }: AppState) => auditLog;

export const getAuditLogSearchValues = createSelector([getAudit], (audit) => {
  return {
    userId: audit.userId,
    objectType: audit.objectType,
    id: audit.id,
    startDate: audit.startDate,
    endDate: audit.endDate,
  };
});

export const getCompareValues = (state: AppState) => getAudit(state).compareData;

export const getSingleLineLoading = (state: AppState) => getAudit(state).isLoadingSingleLine;
