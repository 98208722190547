import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { usersListApiSlice } from '../list/usersListApiSlice';
import { getUserToDeleteId } from '../userSelectors';
import { userApi } from '../usersApi';
import { deleteUserFailure, deleteUserSuccess } from '../usersSlice';

export function* deleteUserSaga() {
  try {
    const userToDeleteId: string = yield select(getUserToDeleteId);

    yield call(userApi.deleteUser, userToDeleteId);

    yield put(deleteUserSuccess());
    yield put(usersListApiSlice.util.invalidateTags(['Users']));
  } catch (e) {
    notifyError(e);

    yield put(
      deleteUserFailure({
        message: i18next.t('adminPanel.user.errors.delete'),
      }),
    );
  }
}
