import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { ScaleImpactResponse, ScaleImpactState } from './types';

const initialState: ScaleImpactState = {
  data: [],
  isLoading: false,
};

export const scaleImpactSlice = createSlice({
  name: 'scaleImpact',
  initialState,
  reducers: {
    getScaleImpact: (state: ScaleImpactState) => {
      state.isLoading = true;
    },
    getScaleImpactSuccess: (
      state: ScaleImpactState,
      { payload }: PayloadAction<ScaleImpactResponse>,
    ) => {
      state.isLoading = false;
      state.data = payload;
    },
    getScaleImpactFailure: (state: ScaleImpactState) => {
      state.data = [];
      state.isLoading = false;
    },
  },
});

const scaleImpactSelector = ({ scaleImpact }: AppState) => scaleImpact;

export const getScaleImpactData = (state: AppState) => scaleImpactSelector(state).data;
export const getScaleImpactLoading = (state: AppState) => scaleImpactSelector(state).isLoading;

export const { getScaleImpact, getScaleImpactSuccess, getScaleImpactFailure } =
  scaleImpactSlice.actions;

export const scaleImpact = scaleImpactSlice.reducer;
