import { api } from 'services/api';

import { DISTANCE_CHECKER_URL } from '../constants';
import { ScrapeUrlRequest, ScrapeUrlResponse } from './scrapeUrlApiTypes';

const scrapeUrl = (data: ScrapeUrlRequest) => {
  return api.post<ScrapeUrlResponse>(`${DISTANCE_CHECKER_URL}/scrape-url`, data);
};

export const scrapeUrlApi = {
  scrapeUrl,
};
