import classNames from 'classnames';

import { nanoTwMerge } from 'services/twMerge';

import { ButtonSize, ButtonVariant } from './types';

type getComputedStylesProps = {
  className?: string;
  disabled: boolean | undefined;
  size: ButtonSize;
  variant: ButtonVariant;
  isSquare?: boolean;
};

export const getComputedStyles = ({
  className,
  disabled,
  size,
  variant,
  isSquare,
}: getComputedStylesProps) => {
  const baseStyles =
    'inline-flex items-center justify-center font-semibold transition-colors ease-in-out';
  const sizeStylesMap: Record<ButtonSize, string> = {
    small: `h-[32px] text-base-s px-4 rounded-md ${isSquare ? 'w-[32px] p-0' : 'w-auto'}`,
    medium: `h-[42px] text-base px-6 rounded-lg ${isSquare ? 'w-[42px] p-0' : 'w-auto'}`,
    large: `h-[52px] text-base-l px-7 rounded-lg ${isSquare ? 'w-[52px] p-0' : 'w-auto'}`,
  } as const;
  const variantStyles: Record<ButtonVariant, string> = {
    primary: classNames('bg-primary-dark-purple-100 text-secondary-white border-none', {
      'hover:bg-secondary-purple active:bg-primary-dark-purple-80': !disabled,
      'bg-text-10': disabled,
    }),
    outline: classNames('border-primary-dark-purple-100 border text-primary-dark-purple-100', {
      'hover:border-secondary-purple hover:text-secondary-purple active:border-primary-dark-purple-80 active:text-primary-dark-purple-80':
        !disabled,
      'border-text-10 text-text-10': disabled,
    }),
    text: classNames('text-primary-dark-purple-100', {
      'hover:text-secondary-purple active:text-primary-dark-purple-80': !disabled,
      'text-text-10': disabled,
    }),
  } as const;

  return nanoTwMerge(baseStyles, sizeStylesMap[size], variantStyles[variant], className);
};
