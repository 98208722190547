import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { teamListApiSlice } from './list/teamListApiSlice';
import { teamApi } from './teamApi';
import { TeamRequestData, TeamResponseData } from './teamApiTypes';
import { getTeam, getTeamToDeleteId } from './teamSelectors';
import {
  deleteTeam,
  deleteTeamFailure,
  deleteTeamSuccess,
  saveTeam,
  saveTeamFailure,
  saveTeamSuccess,
  updateTeam,
  updateTeamFailure,
  updateTeamSuccess,
} from './teamSlice';
import { TeamState } from './types';

function* saveTeamSaga() {
  try {
    const team: TeamState = yield select(getTeam);
    const teamData = {
      name: team.name,
      companyId: team.companyId,
      users: team.users,
    };
    const response: TeamResponseData = yield call(teamApi.saveTeam, teamData);

    yield put(saveTeamSuccess(response));
    yield put(teamListApiSlice.util.invalidateTags(['Team']));
  } catch (e) {
    notifyError(e);

    yield put(
      saveTeamFailure({
        message: i18next.t('adminPanel.team.errors.teamSaveFailed'),
      }),
    );
  }
}

function* deleteTeamSaga() {
  try {
    const teamToDeleteId: string = yield select(getTeamToDeleteId);

    yield call(teamApi.deleteTeam, teamToDeleteId);

    yield put(deleteTeamSuccess());
    yield put(teamListApiSlice.util.invalidateTags(['Team']));
  } catch (e) {
    notifyError(e);

    yield put(
      deleteTeamFailure({
        message: i18next.t('adminPanel.team.errors.teamDeleteFailed'),
      }),
    );
  }
}

function* updateTeamSaga() {
  try {
    const team: TeamState = yield select(getTeam);

    const data: TeamRequestData = {
      name: team.name,
      companyId: team.companyId,
      users: team.users,
    };

    yield call(teamApi.updateTeam, team.id, data);

    yield put(teamListApiSlice.util.invalidateTags(['Team']));
    yield put(updateTeamSuccess());
  } catch (e) {
    notifyError(e);

    yield put(
      updateTeamFailure({
        message: i18next.t('adminPanel.team.errors.teamUpdateFailed'),
      }),
    );
  }
}

export function* teamSagas() {
  yield all([takeLatest(saveTeam.type, saveTeamSaga)]);
  yield all([takeLatest(deleteTeam.type, deleteTeamSaga)]);
  yield all([takeLatest(updateTeam.type, updateTeamSaga)]);
}
