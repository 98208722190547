export const MIN_BARS_COUNT = 2;

export const convertAbsoluteToRelativeCoords = (parent: DOMRect, child: DOMRect) => {
  return {
    top: child.top - parent.top,
    left: child.left - parent.left,
    width: 0,
    height: 0,
    right: child.right,
  };
};

export const groupToPairs = (coords: number[]): string[] => {
  const res: string[] = [];

  for (let i = 0; i < coords.length; i += 2) {
    res.push(`${Math.ceil(coords[i])}px ${Math.ceil(coords[i + 1])}px`);
  }

  return res;
};

export const calculateElementRelativeRect = (parent: DOMRect, child: DOMRect) => {
  const relativeRect = convertAbsoluteToRelativeCoords(parent, child);

  return {
    ...relativeRect,
    width: child.width,
    height: child.height,
  };
};
