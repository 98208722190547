import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { customCategoriesApi } from '../customCategoriesApi';
import { getCustomCategory } from '../customCategorySelectors';
import { updateCustomCategoryFailure, updateCustomCategorySuccess } from '../customCategorySlice';
import { customCategoriesListApiSlice } from '../list/customCategoriesListApiSlice';
import { CustomCategoryState } from '../types';

export function* customCategoryUpdateSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    const customCategory: CustomCategoryState = yield select(getCustomCategory);
    const customCategoryData = {
      name: customCategory.name,
      description: customCategory.description,
      parentId: customCategory.parentId,
      keywordGroups: customCategory.keywordGroups,
    };

    yield call(customCategoriesApi.updateCustomCategory, payload.id, customCategoryData);
    yield put(customCategoriesListApiSlice.util.invalidateTags(['CustomCategories']));
    yield put(updateCustomCategorySuccess());
  } catch (e) {
    notifyError(e);
    yield put(
      updateCustomCategoryFailure({
        message: i18next.t('adminPanel.customCategories.errors.customCategorySaveFailed'),
      }),
    );
  }
}
