import { api } from 'services/api';

import {
  AuditLogResponseItem,
  AuditLogSingleLineParams,
  AuditLogSingleLineResponse,
  SearchAuditLogParams,
} from './types';

const getAuditLog = (queryParams: SearchAuditLogParams) => {
  return api.get<AuditLogResponseItem[]>('report/audit-log', { params: queryParams });
};

const getAuditLogSingleLine = (queryParams: AuditLogSingleLineParams) => {
  return api.get<AuditLogSingleLineResponse>('report/audit-log-single-line', {
    params: queryParams,
  });
};

export const auditLogApi = {
  getAuditLog,
  getAuditLogSingleLine,
};
