import { NanoBotImg } from 'components/common/nanoBot';

export const ChatLoadingMessage = () => {
  return (
    <div className="mb-[30px] flex">
      <NanoBotImg className="mr-[5px] h-[32px] w-[32px]" />
      <div className="typing">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
};
