export const deepCopy = (data: unknown) => JSON.parse(JSON.stringify(data));

export const arrayToMap = <T, K extends keyof T>(list: T[], key: K) => {
  return list.reduce(
    (acc, item) => {
      acc[String(item[key])] = item;

      return acc;
    },
    {} as Record<string, T>,
  );
};

export const copyToClipboard = (data: string) => {
  if (data.length > 0) {
    navigator.clipboard.writeText(data);
  }
};
