/* eslint-disable camelcase */
import { AI_ASSISTANT_URL } from 'modules/nanoBot/constants';
import { NanoBotData } from 'modules/nanoBot/types';

import { api } from 'services/api';

const sendMessage = (data: NanoBotData) => {
  const { userMessages: user_messages, message } = data;

  return api.post(AI_ASSISTANT_URL, { user_messages, message });
};

export const nanoBotApi = {
  sendMessage,
};
