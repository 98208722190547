import { RefObject, useEffect, useRef, useState } from 'react';

import {
  calculateElementRelativeRect,
  convertAbsoluteToRelativeCoords,
  groupToPairs,
} from './utils';

export const useRightScaleCutout = (parentRef: RefObject<HTMLDivElement>) => {
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [coords, setCoords] = useState<string[]>([]);

  const stepDecrement = useRef<number>(0);

  useEffect(() => {
    if (!parentRef.current) {
      return;
    }

    const htmlElements = refs.current.filter((el): el is HTMLDivElement => !!el);

    const [firstEl] = htmlElements;

    const parentRect = parentRef.current?.getBoundingClientRect();

    const firstElRect = calculateElementRelativeRect(parentRect, firstEl.getBoundingClientRect());

    stepDecrement.current = firstElRect.width / htmlElements.length;

    let currentDecrementValue = stepDecrement.current;

    const elements = htmlElements
      .map((el, index) => {
        const childRect = el?.getBoundingClientRect();
        const relChildRect = convertAbsoluteToRelativeCoords(parentRect, childRect);

        const elDimensions = {
          top: relChildRect.top,
          left: relChildRect.left,
          width: firstElRect.width,
          height: childRect.height,
        };

        if (index > 0) {
          elDimensions.width -= currentDecrementValue;
          currentDecrementValue += stepDecrement.current;
        }

        if (index === htmlElements.length - 1) {
          return [
            elDimensions.left + elDimensions.width,
            elDimensions.top,

            elDimensions.left + elDimensions.width,
            elDimensions.top + elDimensions.height,

            elDimensions.left,
            elDimensions.top + elDimensions.height,
          ];
        }

        return [
          elDimensions.left + elDimensions.width,
          elDimensions.top,

          elDimensions.left + elDimensions.width,
          elDimensions.top + elDimensions.height,
        ];
      })
      .flat(1);

    const res = [...elements, firstElRect.left, firstElRect.top];

    setCoords(groupToPairs(res));
  }, [refs, parentRef]);

  return {
    coords,
    refs,
    parentRef,
    stepDecrement: stepDecrement.current,
  };
};
