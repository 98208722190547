import { Switch as SwitchAntd, SwitchProps as SwitchAntdProps } from 'antd';
import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';

interface SwitchProps extends SwitchAntdProps, Omit<SwitchAntdProps, 'size'> {
  children?: ReactNode;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, children, disabled, ...rest }: SwitchProps, ref) => (
    <label
      className={classNames('inline-flex align-middle hover:cursor-pointer hover:text-text-100', {
        'text-text-40 hover:cursor-not-allowed hover:text-text-40': disabled,
      })}
    >
      <SwitchAntd
        className={classNames(className, 'nano-switch')}
        disabled={disabled}
        {...rest}
        ref={ref}
      />
      <span
        className={classNames('ml-3 text-base-l', {
          'hover:cursor-not-allowed': disabled,
        })}
      >
        {children}
      </span>
    </label>
  ),
);
Switch.displayName = 'Switch';
