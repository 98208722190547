import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import {
  addKeywords,
  addUploadedKeywords,
  clearAllKeywords,
  deleteBucket,
  fetchSegmentDetailsSuccess,
  removeAllCategoriesValues,
  removeCategoryValue,
  removeEntityTag,
  removeKeyword,
  removeUploadedKeywords,
  saveCurrentBucket,
  setEmotions,
  setEntity,
  setIntervalFilter,
  setLanguages,
  setPerformance,
  setPersonas,
  setSensitiveSubjects,
  toggleCategoryNodeCheck,
  toggleSentiment,
  updateSegmentSuccess,
  updateValues,
  refetchSegmentDetails,
} from '../segment/segmentSlice';
import {
  getEmotionDistributionFailure,
  getEmotionDistributionSuccess,
  setEmotionDistributionLoading,
} from './EmotionDistribution/EmotionDistributionSlice';
import { EmotionDistributionMap } from './EmotionDistribution/types';
import {
  getSegmentGeoLanguagesFailure,
  getSegmentGeoLanguagesSuccess,
  setSegmentGeoLanguages,
} from './GeoLanguages/GeoLanguagesSelector';
import { Geo } from './GeoLanguages/types';
import {
  getIntentConfirmationFailure,
  getIntentConfirmationSuccess,
  setIntentConfirmationLoading,
} from './IntentConfirmation/IntentConfirmationSlice';
import { IntentConfirmationData } from './IntentConfirmation/types';
import {
  getIntentSeasonalityFailure,
  getIntentSeasonalitySuccess,
  setIntentSeasonalityLoading,
} from './IntentSeasonality/IntentSeasonalitySlice';
import { IntentSeasonalityData } from './IntentSeasonality/types';
import { transformData } from './IntentSeasonality/utils';
import { scaleImpactSaga } from './ScaleImpact';
import {
  getSentimentDistributionFailure,
  getSentimentDistributionSuccess,
  setSentimentDistributionLoading,
} from './SentimentDistribution/SentimentDistributionSlice';
import { SentimentDistributionData } from './SentimentDistribution/types';
import {
  getTotalReachEstimation,
  getTotalReachEstimationFailure,
  getTotalReachEstimationSuccess,
  setTotalReachEstimationLoading,
} from './TotalReachEstimation/totalReachEstimationSlice';
import { chartsApi } from './chartsApi';
import { formRequestPayloadSaga } from './sagas/formRequestPayloadSaga';
import { ChartsPayload, TransformedIntentSeasonalityData } from './types';

function* getEmotionDistributionSaga() {
  yield put(setEmotionDistributionLoading());
  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);

    const emotionDistribution: EmotionDistributionMap = yield call(
      chartsApi.getEmotionDistribution,
      requestPayload,
    );

    yield put(getEmotionDistributionSuccess(emotionDistribution));
  } catch (error) {
    yield put(getEmotionDistributionFailure());
    notifyError(error);
  }
}

function* getSentimentDistributionSaga() {
  yield put(setSentimentDistributionLoading());
  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);

    const sentimentDistribution: SentimentDistributionData = yield call(
      chartsApi.getSentimentDistribution,
      requestPayload,
    );

    yield put(getSentimentDistributionSuccess(sentimentDistribution));
  } catch (error) {
    yield put(getSentimentDistributionFailure());
    notifyError(error);
  }
}

function* getTotalReachSaga() {
  yield put(setTotalReachEstimationLoading());
  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);

    const totalReachResp: { total: number } = yield call(chartsApi.getTotalReach, requestPayload);

    yield put(getTotalReachEstimationSuccess(totalReachResp));
  } catch (error) {
    yield put(getTotalReachEstimationFailure());
    notifyError(error);
  }
}

function* getIntentSeasonalitySaga() {
  yield put(setIntentSeasonalityLoading());
  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);

    const intentSeasonality: IntentSeasonalityData = yield call(
      chartsApi.getIntentSeasonality,
      requestPayload,
    );
    const transformedData: TransformedIntentSeasonalityData = yield call(
      transformData,
      intentSeasonality,
    );

    yield put(getIntentSeasonalitySuccess(transformedData));
  } catch (error) {
    yield put(getIntentSeasonalityFailure());
    notifyError(error);
  }
}

function* getIntentConfirmationSaga() {
  yield put(setIntentConfirmationLoading());
  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);

    const intentConfirmation: IntentConfirmationData = yield call(
      chartsApi.getIntentConfirmation,
      requestPayload,
    );

    yield put(getIntentConfirmationSuccess(intentConfirmation));
  } catch (error) {
    yield put(getIntentConfirmationFailure());
    notifyError(error);
  }
}

function* getSegmentGeoLanguagesSaga() {
  yield put(setSegmentGeoLanguages());

  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);

    const segmentGeoLanguages: Geo = yield call(chartsApi.getSegmentGeoLanguages, requestPayload);

    yield put(getSegmentGeoLanguagesSuccess(segmentGeoLanguages));
  } catch (error) {
    yield put(getSegmentGeoLanguagesFailure());
    notifyError(error);
  }
}

export function* chartsSagas() {
  yield all([
    takeLatest(
      [
        fetchSegmentDetailsSuccess.type,
        setPerformance.type,
        setIntervalFilter.type,
        saveCurrentBucket.type,
        deleteBucket.type,
        getTotalReachEstimation.type,
        setEmotions.type,
        removeCategoryValue.type,
        toggleCategoryNodeCheck.type,
        removeAllCategoriesValues.type,
        setPersonas.type,
        toggleSentiment.type,
        setLanguages.type,
        setSensitiveSubjects.type,
        addKeywords.type,
        addUploadedKeywords.type,
        removeUploadedKeywords.type,
        removeKeyword.type,
        clearAllKeywords.type,
        setEntity.type,
        removeEntityTag.type,
        updateSegmentSuccess.type,
        updateValues.type,
        refetchSegmentDetails.type,
      ],
      function* (action: PayloadAction<{ id?: string; navPath: string }>) {
        yield delay(500);

        const callTotalReachSaga =
          action.payload?.navPath === '/general-info' ||
          action.payload?.navPath === '/manual' ||
          action.type === 'segment/updateValues' ||
          action.type === 'segment/setEmotions' ||
          action.type === 'segment/toggleCategoryNodeCheck' ||
          action.type === 'segment/removeCategoryValue' ||
          action.type === 'segment/removeAllCategoriesValues' ||
          action.type === 'segment/setPersonas' ||
          action.type === 'segment/toggleSentiment' ||
          action.type === 'segment/setLanguages' ||
          action.type === 'segment/setSensitiveSubjects' ||
          action.type === 'segment/removeKeyword' ||
          action.type === 'segment/clearAllKeywords' ||
          action.type === 'segment/removeEntityTag' ||
          action.type === 'segment/addKeywords' ||
          action.type === 'segment/setEntity';

        const callAllChartsSagas =
          action.payload?.navPath !== '/general-info' &&
          action.payload?.navPath !== '/management' &&
          action.payload?.navPath !== '../buckets' &&
          action.payload?.navPath !== '/manual' &&
          action.type !== 'segment/updateValues' &&
          action.type !== 'segment/setEmotions' &&
          action.type !== 'segment/toggleCategoryNodeCheck' &&
          action.type !== 'segment/removeCategoryValue' &&
          action.type !== 'segment/removeAllCategoriesValues' &&
          action.type !== 'segment/setPersonas' &&
          action.type !== 'segment/toggleSentiment' &&
          action.type !== 'segment/setLanguages' &&
          action.type !== 'segment/setSensitiveSubjects' &&
          action.type !== 'segment/addKeywords' &&
          action.type !== 'segment/removeKeyword' &&
          action.type !== 'segment/clearAllKeywords' &&
          action.type !== 'segment/setEntity' &&
          action.type !== 'segment/removeEntityTag' &&
          action.type !== 'geoLanguages/fetchSegmentGeoLanguages' &&
          action.type !== 'geoLanguages/updateSegmentGeoLanguages';

        if (callTotalReachSaga) {
          yield call(getTotalReachSaga);
        }

        if (action.type === 'geoLanguages/fetchSegmentGeoLanguages') {
          yield call(getSegmentGeoLanguagesSaga);
        }

        if (callAllChartsSagas) {
          yield all([
            getTotalReachSaga(),
            getSentimentDistributionSaga(),
            getEmotionDistributionSaga(),
            getIntentSeasonalitySaga(),
            getIntentConfirmationSaga(),
            getSegmentGeoLanguagesSaga(),
            scaleImpactSaga(),
          ]);
        }
      },
    ),
  ]);
}
