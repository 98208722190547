import { nanoApi } from 'redux/rootApiSlice';

import { SegmentListItem } from '../segmentApiTypes';

export const segmentsListApiSlice = nanoApi.injectEndpoints({
  endpoints: (build) => ({
    segmentsList: build.query<SegmentListItem[], null>({
      query: () => ({ method: 'GET', url: 'segment/getAll' }),
      providesTags: ['Segments'],
    }),
  }),
});

export const { useSegmentsListQuery } = segmentsListApiSlice;
