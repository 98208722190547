import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { authApiSlice } from 'modules/auth/authApiSlice';

import { notifyError } from 'services/logService';

import { segmentsListApiSlice } from '../segment/list/segmentsListApiSlice';
import { segmentsApi } from '../segment/segmentApi';
import { SegmentDetailsResponse } from '../segment/segmentApiTypes';
import { updateValues } from '../segment/segmentSlice';
import { SEGMENT_ENTITY_TYPE } from './constants';
import { labelsApi } from './labelsApi';
import {
  addLabelToSegment,
  createNewLabel,
  deleteLabel,
  getLabels,
  getLabelsData,
  getLabelsFailure,
  getLabelsSuccess,
  removeLabelFromSegment,
  updateSegmentLabels,
} from './labelsSelector';
import { AllLabelsResponse, SegmentLabel } from './types';

function* getLabelsSaga() {
  yield delay(1000);
  try {
    const { data: user } = yield select(authApiSlice.endpoints.userProfile.select(null));

    const labelsData: AllLabelsResponse = yield call(
      labelsApi.getAllLabelsByCompanyId,
      user.companyId,
    );
    const segmentLabels = labelsData.Segment ?? [];

    yield put(getLabelsSuccess(segmentLabels));
  } catch (error) {
    yield put(getLabelsFailure());
    notifyError(error);
  }
}

function* addNewSegmentLabelSaga({ payload }: ReturnType<typeof createNewLabel>) {
  try {
    const { label, segmentId } = payload;
    const { data: user } = yield select(authApiSlice.endpoints.userProfile.select(null));
    const labels: SegmentLabel[] = yield select(getLabelsData);

    const labelNames = labels.map(({ name }) => name.toLowerCase());

    // prevent adding the same labels twice
    if (labelNames.includes(label.toLowerCase())) {
      return;
    }

    yield call(labelsApi.createLabel, {
      companyId: user.companyId,
      entityType: SEGMENT_ENTITY_TYPE,
      name: label,
    });
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(addLabelToSegment({ label, segmentId }));
    yield call(getLabelsSaga);
  } catch (error) {
    yield put(getLabelsFailure());
    notifyError(error);
  }
}

function* removeSegmentLabelSaga(action: ReturnType<typeof deleteLabel>) {
  try {
    const payload = action.payload;

    yield call(labelsApi.deleteLabel, payload);
    yield call(getLabelsSaga);
  } catch (error) {
    yield put(getLabelsFailure());
    notifyError(error);
  }
}

function* addLabelToSegmentSaga({ payload }: ReturnType<typeof addLabelToSegment>) {
  try {
    const { label, segmentId } = payload;

    const segmentDetails: SegmentDetailsResponse = yield call(
      segmentsApi.getSegmentById,
      segmentId,
    );

    const { labels } = segmentDetails;

    const labelNames = labels.map(({ name }) => name);

    if (labelNames.includes(label)) {
      return;
    }

    yield call(segmentsApi.updateSegment, segmentId, {
      ...segmentDetails,
      labels: [...labelNames, label],
    });
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(
      updateValues({
        labels: [...labelNames, label],
      }),
    );
  } catch (error) {
    notifyError(error);
  }
}

function* removeLabelFromSegmentSaga({ payload }: ReturnType<typeof removeLabelFromSegment>) {
  try {
    const { label, segment } = payload;

    const segmentDetails: SegmentDetailsResponse = yield call(
      segmentsApi.getSegmentById,
      segment.id,
    );

    const { labels } = segmentDetails;

    const labelNames = labels.map(({ name }) => name);

    yield call(segmentsApi.updateSegment, segment.id, {
      ...segmentDetails,
      labels: [...labelNames.filter((name) => name !== label)],
    });
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(
      updateValues({
        labels: [...labelNames.filter((name) => name !== label)],
      }),
    );
  } catch (error) {
    notifyError(error);
  }
}

function* updateSegmentLabelsSaga({
  payload,
}: PayloadAction<{ labels: string[]; segmentId: string }>) {
  try {
    const { labels, segmentId } = payload;
    const segment: SegmentDetailsResponse = yield call(segmentsApi.getSegmentById, segmentId);
    const segmentLabels = Array.from(new Set([...labels]));

    yield call(segmentsApi.updateSegment, segmentId, { ...segment, labels: segmentLabels });
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
  } catch (error) {
    notifyError(error);
  }
}

export function* labelsSagas() {
  yield all([
    takeLatest(getLabels.type, getLabelsSaga),
    takeLatest(createNewLabel.type, addNewSegmentLabelSaga),
    takeLatest(deleteLabel.type, removeSegmentLabelSaga),
    takeLatest(addLabelToSegment.type, addLabelToSegmentSaga),
    takeLatest(removeLabelFromSegment.type, removeLabelFromSegmentSaga),
    takeLatest(updateSegmentLabels.type, updateSegmentLabelsSaga),
  ]);
}
