import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './en';

i18next.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: 'en',
  supportedLngs: ['en'],
  resources: {
    en,
  },
});
