import { Tooltip as TooltipAntd, TooltipProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

export const Tooltip = memo(({ className, children, ...rest }: TooltipProps) => (
  <TooltipAntd className={classNames(className)} {...rest}>
    {children}
  </TooltipAntd>
));
Tooltip.displayName = 'Tooltip';
