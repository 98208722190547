import { ExclusionListForm } from 'modules/adminPanel/exclusionList/forms/exclusionListForm';

import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

type ExclusionListFormDialogProps = DialogBaseProps & {
  title: string;
};

export const ExclusionListFormDialog = ({ open, title, onClose }: ExclusionListFormDialogProps) => {
  return (
    <NanoDialog open={open} title={title} width={616}>
      <ExclusionListForm onClose={onClose} />
    </NanoDialog>
  );
};
