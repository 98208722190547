import { useNavigate } from 'react-router';

import { Button } from '../common';

interface AdminPanelButtonProps {
  buttonText: string;
  onClick?: () => void;
  className?: string;
}

enum Routes {
  AdminPanel = '/admin-panel',
}

export const AdminPanelButton = ({ onClick, buttonText, className }: AdminPanelButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(Routes.AdminPanel);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button className={className} variant="outline" onClick={handleClick}>
      {buttonText}
    </Button>
  );
};
