import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Field, Input, SubmitButton, TextArea } from 'components/common';

import { getDomainList } from '../../domainListSelectors';
import {
  saveDomainList,
  setDomainListToUpdateId,
  updateDomainList,
  updateValues,
} from '../../domainListSlice';

export type domainListFormProps = {
  id?: string;
  name?: string;
  onClose: () => void;
  refetch?: () => void;
};

export const DomainListForm = ({ id, name, onClose, refetch }: domainListFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, list: list } = useSelector(getDomainList);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { name: name, listArea: '' } });

  useEffect(() => {
    if (list && id) {
      setValue('listArea', list.join('\n'));
    }
  }, [id, list, setValue]);

  const onSubmit = handleSubmit((data) => {
    const urlsFromForm = data.listArea
      .split('\n')
      .filter(Boolean)
      .map((i) => i.trim());

    dispatch(updateValues({ name: data.name, list: urlsFromForm }));

    if (!id) {
      dispatch(saveDomainList(refetch));
    } else {
      dispatch(setDomainListToUpdateId(id));
      dispatch(updateDomainList());
    }
    onClose();
  });

  return (
    <form className="flex flex-col gap-y-9" onSubmit={onSubmit}>
      <Field
        name="name"
        control={control}
        render={({ field, fieldState: { invalid } }) => (
          <Input
            placeholder={t('adminPanel.domainList.dialog.name')}
            {...field}
            invalid={invalid}
          />
        )}
        rules={{
          required: t('forms.errors.required'),
        }}
        error={errors.name?.message}
      />
      <Field
        name="listArea"
        control={control}
        render={({ field, fieldState: { invalid } }) => (
          <TextArea
            className="mb-4 h-[164px] w-full"
            {...field}
            placeholder={t('adminPanel.domainList.dialog.placeholder')}
            invalid={invalid}
          />
        )}
        rules={{
          required: t('adminPanel.domainList.validators.required'),
        }}
        error={errors.listArea?.message}
      />
      <div className="mt-2 flex items-center justify-end gap-x-4">
        <Button type="button" variant="outline" onClick={onClose}>
          {t('adminPanel.domainList.dialog.cancelButton')}
        </Button>
        <SubmitButton isLoading={isLoading}>
          {t('adminPanel.domainList.dialog.submitButton')}
        </SubmitButton>
      </div>
    </form>
  );
};
