import { Skeleton } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, CheckboxGroup, Text } from 'components/common';

import { useSensitiveSubjectsQuery } from 'modules/apiData/apiDataApiSlice';
import { getSegmentSensitiveSubjects } from 'modules/segment/segmentSelectors';
import { setSensitiveSubjects } from 'modules/segment/segmentSlice';

export const SensitiveSubjects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: subjects, isLoading } = useSensitiveSubjectsQuery(null);
  const { sensitiveSubjectsValues, isTouched } = useSelector(getSegmentSensitiveSubjects);

  const subjectsList = useMemo(() => Object.values(subjects ?? {}), [subjects]);

  const isAllSensitiveSubjectsSelected =
    Boolean(sensitiveSubjectsValues.length) &&
    sensitiveSubjectsValues.length === subjectsList.length;

  const handleChange = useCallback(
    (checkedValues: string[]) => {
      dispatch(setSensitiveSubjects(checkedValues));
    },
    [dispatch],
  );

  const handleChangeAll = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent) => {
      const newSubjects = checked ? subjectsList.map(({ id }) => id) : [];

      handleChange(newSubjects);
    },
    [subjectsList, handleChange],
  );

  useEffect(() => {
    if (subjectsList.length && !isTouched) {
      const newSubjects = subjectsList.map(({ id }) => id);

      handleChange(newSubjects);
    }
  }, [subjectsList.length, isTouched, handleChange, subjectsList]);

  return (
    <div className="flex h-full max-w-[360px] flex-col gap-4 ">
      <Text large className="font-medium">
        {t('segment.targetingOptions.sensitiveSubjects')}
      </Text>
      <p className="text-text-100">{t('segment.sensitiveSubjects.description')}</p>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div className="flex flex-1 flex-col gap-y-3">
          <div className="border-b-[1px] border-b-text-20 pb-3">
            <Checkbox checked={isAllSensitiveSubjectsSelected} onChange={handleChangeAll}>
              {t(
                `segment.sensitiveSubjects.${
                  isAllSensitiveSubjectsSelected ? 'unselectAll' : 'selectAll'
                }`,
              )}
            </Checkbox>
          </div>
          <CheckboxGroup
            className="flex flex-col gap-y-3"
            onChange={handleChange}
            value={sensitiveSubjectsValues}
          >
            {subjectsList.map(({ name, id }) => (
              <Checkbox key={id} value={id}>
                {name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </div>
      )}
    </div>
  );
};
