import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { closeExpiredDateDialog, updateExpiredDate } from '../list/expiredDateSlice';
import { segmentsListApiSlice } from '../list/segmentsListApiSlice';
import { segmentsApi } from '../segmentApi';
import { SegmentDetailsResponse } from '../segmentApiTypes';
import { updateSegmentFailure } from '../segmentSlice';

function* updateExpiredDateSaga({
  payload,
}: PayloadAction<{ expiredDate: string; segmentId: string }>) {
  try {
    const { expiredDate, segmentId } = payload;
    const segment: SegmentDetailsResponse = yield call(segmentsApi.getSegmentById, segmentId);
    const segmentLabels = segment.labels.map((label) => label.name);

    yield call(segmentsApi.updateSegment, segmentId, {
      ...segment,
      expiredDate,
      labels: segmentLabels,
    });
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(closeExpiredDateDialog());
  } catch (error) {
    notifyError(error);
    yield put(
      updateSegmentFailure({
        message: i18next.t('errors.segmentSaveFailed'),
      }),
    );
  }
}

export function* expiredDateSagas() {
  yield all([takeLatest(updateExpiredDate.type, updateExpiredDateSaga)]);
}
