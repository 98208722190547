import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { CompanyListItem } from '../companyApiTypes';
import { COMPANIES_URL } from '../constants';

export const companyListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    companyList: build.query<CompanyListItem[], null>({
      query: () => ({ method: 'GET', url: COMPANIES_URL }),
      providesTags: ['Company'],
    }),
  }),
});

export const { useCompanyListQuery } = companyListApiSlice;
