import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

type EmptySegmentState = {
  isLoading: boolean;
  segmentId: string | null;
};

const initialState: EmptySegmentState = {
  isLoading: false,
  segmentId: null,
};

export const emptySegmentSlice = createSlice({
  name: 'emptySegment',
  initialState,
  reducers: {
    createEmptySegment: (state) => {
      state.isLoading = true;
      state.segmentId = null;
    },
    createEmptySegmentSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.segmentId = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createEmptySegmentFailure: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
    },
    resetSegmentId: (state) => {
      state.segmentId = null;
    },
  },
});

export const getEmptySegmentDataSelector = (state: AppState) => state.emptySegment;

export const {
  createEmptySegment,
  createEmptySegmentSuccess,
  createEmptySegmentFailure,
  resetSegmentId,
} = emptySegmentSlice.actions;

export const emptySegment = emptySegmentSlice.reducer;
