import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useSegmentsListQuery } from 'modules/adminPanel/adminPanelApiDataApiSlice';

import { Field, Input, Option, Select, SubmitButton } from '../../../components/common';
import { checkDistanceStart } from './distanceCheckerSlice';
import { scrapeUrlStart } from './scrapeUrl/scrapeUrlSlice';

export const DistanceCheckerForm = () => {
  const { t } = useTranslation();
  const { data: segments, isLoading } = useSegmentsListQuery(null);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: '',
      segmentId: null,
    },
  });

  const onSubmit = handleSubmit((data) => {
    dispatch(checkDistanceStart(data));
    dispatch(scrapeUrlStart({ url: data.url, render: true }));
  });

  return (
    <>
      <div className="w-full flex justify-between">
        <form
          onSubmit={onSubmit}
          className="flex md:flex-row justify-between gap-4 iitems-center flex-col w-full"
        >
          <Field
            name="url"
            control={control}
            className="p-0.5 w-1/2 max-w-1/2 rounded-xl bg-secondary-white"
            render={({ field, fieldState: { invalid } }) => (
              <Input
                placeholder={t('adminPanel.distanceChecker.urlPlaceholder')}
                {...field}
                invalid={invalid}
              />
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.url?.message}
          />

          <Field
            name="segmentId"
            className="w-1/2 p-0.5 rounded-xl bg-secondary-white flex overflow-hidden"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t('adminPanel.distanceChecker.segmentPlaceholder')}
                {...field}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  ((option?.name ?? '') as string).toLowerCase().includes(input.toLowerCase()) ||
                  ((option?.key ?? '') as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                {segments?.map((segment) => (
                  <Option key={segment.id} value={segment.id} name={segment.name}>
                    {segment.name}
                  </Option>
                ))}
              </Select>
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.segmentId?.message}
          ></Field>

          <div className="flex items-center justify-end gap-x-4">
            <SubmitButton isLoading={isLoading} className="w-[170px]">
              {t('adminPanel.distanceChecker.checkButton')}
            </SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
};
