import { ChevronDown } from 'feather-icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Option, Select, Text } from 'components/common';

import { useCompanyListQuery } from 'modules/adminPanel/company';

type CompanyFilteringSelectProps = {
  onChange: (label: string[]) => void;
  selectedValues: string[];
};

export const CompanyFilteringSelect = ({
  onChange,
  selectedValues,
}: CompanyFilteringSelectProps) => {
  const { t } = useTranslation();
  const { data: companies } = useCompanyListQuery(null);
  const extendedCompanies = companies ? [{ id: 'null', name: 'None' }, ...companies] : [];

  return (
    <div className="flex gap-1 bg-secondary-white p-1 items-center rounded-xl w-[300px]">
      <Text className="text-base-l mx-3 font-light text-nowrap w-[85px]">
        {t('adminPanel.user.groupByCompany')}
      </Text>
      <Select
        mode="multiple"
        className="w-[200px]"
        suffixIcon={<ChevronDown />}
        onChange={onChange}
        value={selectedValues}
      >
        {extendedCompanies?.map((company) => (
          <Option key={company.id} value={company.id}>
            {company.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
