import { Skeleton } from 'antd';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Paragraph } from 'components/common';

import { TreeNodePersonas } from './TreeNode';
import { usePersonas } from './usePersonas';

type ExpandedNodesState = {
  [id: string]: boolean;
};
export const Personas = () => {
  const { t } = useTranslation();
  const { parentPersonasWithChildren, isLoading } = usePersonas();

  const [searchTerm, setSearchTerm] = useState('');
  const [expandedNodes, setExpandedNodes] = useState<ExpandedNodesState>({});

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleExpand = (id: string) => {
    setExpandedNodes((prev: ExpandedNodesState) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const filteredPersonas = useMemo(() => {
    if (!searchTerm) {
      return parentPersonasWithChildren.map((persona) => ({
        ...persona,
        children: [...persona.children].sort((a, b) => a.name.localeCompare(b.name)),
      }));
    }

    const lowerCasedSearchTerm = searchTerm.toLowerCase();

    return parentPersonasWithChildren.map((persona) => ({
      ...persona,
      children: persona.children
        .filter((child) => child.name.toLowerCase().includes(lowerCasedSearchTerm))
        .sort((a, b) => a.name.localeCompare(b.name)),
    }));
  }, [parentPersonasWithChildren, searchTerm]);

  return (
    <div>
      <div className="flex flex-row items-center">
        <Paragraph className="text-base-l font-medium">{t('segment.personas.personas')}</Paragraph>
      </div>
      <Paragraph className="my-4">{t('segment.personas.description')}</Paragraph>
      <div className="flex flex-col gap-y-4">
        <Input
          type="text"
          placeholder={t('forms.placeholders.search')}
          value={searchTerm}
          onChange={handleSearch}
          className="mb-4 p-2 border border-gray-300 w-[50%]"
        />
        {isLoading ? (
          <Skeleton active />
        ) : (
          filteredPersonas.map((persona) => (
            <TreeNodePersonas
              key={persona.id}
              persona={persona}
              expanded={expandedNodes[persona.id]}
              onExpand={handleExpand}
              personas={parentPersonasWithChildren}
            />
          ))
        )}
      </div>
    </div>
  );
};
