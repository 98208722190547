import { IconProps } from 'feather-icons-react';

export const CircleCloseIcon = ({ className, size = '16', fill = '#000' }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9301_8949)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 2.00033C4.68631 2.00033 2.00002 4.68662 2.00002 8.00033C2.00002 11.314 4.68631 14.0003 8.00002 14.0003C11.3137 14.0003 14 11.314 14 8.00033C14 4.68662 11.3137 2.00033 8.00002 2.00033ZM0.666687 8.00033C0.666687 3.95024 3.94993 0.666992 8.00002 0.666992C12.0501 0.666992 15.3334 3.95024 15.3334 8.00033C15.3334 12.0504 12.0501 15.3337 8.00002 15.3337C3.94993 15.3337 0.666687 12.0504 0.666687 8.00033ZM8.00012 8.94313L6.00012 10.9431L5.05731 10.0003L7.05731 8.00033L5.05731 6.00032L6.00012 5.05752L8.00012 7.05752L10.0001 5.05752L10.9429 6.00032L8.94293 8.00033L10.9429 10.0003L10.0001 10.9431L8.00012 8.94313Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_9301_8949">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
