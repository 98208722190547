import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role, RoleAssign } from './types';

export const ROLE_ASSIGN_SLICE_NAME = 'roleAssign';

export const initialRoleAssignState: RoleAssign = {
  companies: [],
  teams: [],
  roles: null,
};

export const roleAssignSlice = createSlice({
  name: ROLE_ASSIGN_SLICE_NAME,
  initialState: initialRoleAssignState,
  reducers: {
    setAssignRole: (state: RoleAssign, { payload }: PayloadAction<RoleAssign>) => {
      Object.assign(state, payload);
    },
    clearAssignRole: () => initialRoleAssignState,
    updateValuesRole: (state: RoleAssign, { payload }: PayloadAction<Role>) => {
      state.roles = payload.role_name;
      state.teams = payload.restrictions['Team'];
      state.companies = payload.restrictions['Company'];
    },
  },
});

export const { setAssignRole, clearAssignRole, updateValuesRole } = roleAssignSlice.actions;

export const roleAssign = roleAssignSlice.reducer;
