import classNames from 'classnames';
import { useMemo } from 'react';

import { Text } from 'components/common';
import { NanoBotImg } from 'components/common/nanoBot';

import { NanoBotChatRole } from 'modules/nanoBot/types';

type MessageProps = {
  messageContent?: {
    role: (typeof NanoBotChatRole)[keyof typeof NanoBotChatRole];
    content: string;
  };
};

export const ChatMessage = ({ messageContent }: MessageProps) => {
  const isAssistantMessage = messageContent?.role === NanoBotChatRole.Assistant;

  const classes = useMemo(() => {
    return {
      messageContainerClasses: classNames('mb-[30px]', 'flex', {
        'justify-end': !isAssistantMessage,
      }),
      messageContentClasses: classNames(
        'w-4/5',
        'rounded-bl-[24px]',
        'rounded-br-[24px]',
        'rounded-tr-[24px]',
        'bg-primary-dark-purple-80',
        'p-[20px]',
        {
          'rounded-tl-[24px]': !isAssistantMessage,
          'bg-secondary-green/50': !isAssistantMessage,
        },
      ),
    };
  }, [isAssistantMessage]);

  return (
    <div className={classes.messageContainerClasses}>
      {isAssistantMessage && <NanoBotImg className="mr-[5px] h-[32px] w-[32px]" />}
      <div className={classes.messageContentClasses}>
        <Text className="whitespace-pre-wrap" style={{ color: '#fff' }}>
          {messageContent?.content}
        </Text>
      </div>
    </div>
  );
};
