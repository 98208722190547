import { Input as InputAntd, InputRef } from 'antd';
import {
  InputProps as InputAntdProps,
  PasswordProps as PasswordAntdProps,
  TextAreaProps as TextAreaAntdProps,
} from 'antd/lib/input';
import { MenuProps } from 'antd/lib/menu/menu';
import classNames from 'classnames';
import { Copy, Link, MoreHorizontal } from 'feather-icons-react';
import { MouseEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'utils';

import { Dropdown } from 'components/common';

import { nanoTwMerge } from 'services/twMerge';

import { Button, IconButton } from '../button';
import { Tooltip } from '../tooltip';

type BaseInputProps = {
  invalid?: boolean;
};

type TextAreaProps = TextAreaAntdProps & BaseInputProps;

interface InputProps extends BaseInputProps, Omit<InputAntdProps, 'addonAfter'> {
  large?: boolean;
}

export const Input = forwardRef<InputRef, InputProps>(
  ({ className, invalid, large, ...rest }: InputProps, ref) => (
    <InputAntd
      ref={ref}
      className={nanoTwMerge(
        classNames('nano-input', {
          'nano-input__invalid': invalid,
          'nano-input__large': large,
        }),
        className,
      )}
      {...rest}
    />
  ),
);
Input.displayName = 'Input';

export const PasswordInput = forwardRef<InputRef, PasswordAntdProps & InputProps>(
  ({ className, invalid, large, ...rest }: PasswordAntdProps & InputProps, ref) => (
    <InputAntd.Password
      className={nanoTwMerge(
        classNames('nano-input', {
          'nano-input__invalid': invalid,
          'nano-input__large': large,
        }),
        className,
      )}
      {...rest}
      ref={ref}
    />
  ),
);
PasswordInput.displayName = 'PasswordInput';

export const TextArea = forwardRef<InputRef, TextAreaProps>(
  ({ className, invalid, ...rest }: TextAreaProps, ref) => {
    return (
      <InputAntd.TextArea
        className={nanoTwMerge(
          classNames('nano-input min-h-[82px]', {
            'nano-input__invalid': invalid,
          }),
          className,
        )}
        {...rest}
        ref={ref}
      />
    );
  },
);
TextArea.displayName = 'TextArea';

export const CopyableTextArea = forwardRef<InputRef, TextAreaProps>(
  ({ value, ...rest }: TextAreaProps, ref) => {
    const { t } = useTranslation();
    const isDisabled = (value as string)?.length === 0;

    const getMenuItems = () => {
      const urlItem = {
        key: 1,
        label: (
          <Tooltip placement="top" title={t('trackingTags.copyUrl')}>
            <IconButton
              size="small"
              variant="text"
              disabled={isDisabled}
              onClick={handleCopyUrlClick}
            >
              <Link size={16} />
            </IconButton>
          </Tooltip>
        ),
      };

      const copyItem = {
        key: 2,
        label: (
          <Tooltip placement="top" title={t('trackingTags.copyTag')}>
            <IconButton
              size="small"
              variant="text"
              disabled={isDisabled}
              onClick={handleCopyTagClick}
            >
              <Copy size={16} />
            </IconButton>
          </Tooltip>
        ),
      };
      const items: MenuProps['items'] = [urlItem, copyItem];

      return items;
    };

    const handleCopyTagClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      copyToClipboard(String(value));
    };

    const handleCopyUrlClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (value !== undefined) {
        const urlRegex = /https?:\/\/[^\s"]+/g;
        const urls: string[] = (value as string).match(urlRegex) || [];

        if (urls.length > 0) {
          copyToClipboard(urls[0]);
        } else {
          return '';
        }
      } else {
        return '';
      }
    };

    const handleDropdownClick = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    return (
      <div className="relative">
        <TextArea value={value} ref={ref} {...rest} />
        <div onClick={handleDropdownClick} className="absolute top-0 right-2">
          <Dropdown menu={{ items: getMenuItems() }}>
            <Button variant="text" className="px-2">
              <MoreHorizontal size={16} />
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  },
);
CopyableTextArea.displayName = 'CopyableTextArea';
