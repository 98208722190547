import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { EmotionDistributionMap, EmotionDistributionState } from './types';

const initialState: EmotionDistributionState = {
  emotionDistributionData: {},
  isLoading: false,
};

export const emotionDistributionSlice = createSlice({
  name: 'emotionDistribution',
  initialState,
  reducers: {
    setEmotionDistributionLoading: (state: EmotionDistributionState) => {
      state.isLoading = true;
    },
    getEmotionDistributionSuccess: (
      state: EmotionDistributionState,
      { payload }: PayloadAction<EmotionDistributionMap>,
    ) => {
      state.isLoading = false;
      state.emotionDistributionData = payload;
    },
    getEmotionDistributionFailure: (state: EmotionDistributionState) => {
      state.isLoading = false;
    },
    resetEmotionDistributionData: (state: EmotionDistributionState) => {
      state.emotionDistributionData = {};
      state.isLoading = false;
    },
  },
});

const emotionDistributionSelector = ({ emotionDistribution }: AppState) => emotionDistribution;

export const getEmotionDistributionData = (state: AppState) =>
  emotionDistributionSelector(state).emotionDistributionData;

export const getEmotionDistributionLoading = (state: AppState) =>
  emotionDistributionSelector(state).isLoading;

export const {
  setEmotionDistributionLoading,
  getEmotionDistributionSuccess,
  getEmotionDistributionFailure,
  resetEmotionDistributionData,
} = emotionDistributionSlice.actions;

export const emotionDistribution = emotionDistributionSlice.reducer;
