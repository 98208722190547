import { Tag } from 'antd';
import { CheckableTagProps } from 'antd/es/tag';
import classNames from 'classnames';

const { CheckableTag } = Tag;

export const Chip = ({ className, children, checked, ...rest }: CheckableTagProps) => (
  <CheckableTag checked={checked} className={classNames('nano-chip', className)} {...rest}>
    {children}
  </CheckableTag>
);
