import { Persona } from '../segment/types';
import { PersonaMap } from './types';

export const normalizePersonasResponse = (rawData: Persona[]): PersonaMap => {
  return rawData.reduce((acc, val) => {
    acc[val.id] = val;

    return acc;
  }, {} as PersonaMap);
};
