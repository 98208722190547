import { format } from 'date-fns';

const formatting = 'dd LLL yyyy';
const formattingWithTime = 'LLL dd h:mm aaa';

export const formatDate = (date: Date): string => format(date, formatting);

export const formatDateWithTime = (date: Date): string => format(date, formattingWithTime);

export const getCurrentDateAndTime = () => {
  const currentDate = new Date();
  const formattedDayOfWeek = format(currentDate, 'EEE');
  const formattedTime = format(currentDate, 'h:mm a');

  return `${formattedDayOfWeek} ${formattedTime}`;
};
