import { AuditLogCompareDialog } from '../dialogs/AuditLogCompareDialog';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { DomainListFormDialog } from '../dialogs/DomainListFormDialog';
import { EditCompanyFormDialog } from '../dialogs/EditCompanyFormDialog';
import { EditSegmentGroupFormDialog } from '../dialogs/EditSegmentGroupFormDialog';
import { EditTeamFormDialog } from '../dialogs/EditTeamFormDialog';
import { ExclusionListFormDialog } from '../dialogs/ExclusionListFormDialog';
import { ExpiredDateDialog } from '../dialogs/ExpiredDateDialog';
import { SegmentLabelsDialog } from '../dialogs/SegmentLabelsDialog';
import { TrackingTagsDialog } from '../dialogs/TrackingTagsDialog';
import { DialogNames } from './types';

const dialogComponents = {
  [DialogNames.CONFIRMATION_DIALOG]: ConfirmationDialog,
  [DialogNames.TRACKING_TAGS_DIALOG]: TrackingTagsDialog,
  [DialogNames.COMPANY_FORM_DIALOG]: EditCompanyFormDialog,
  [DialogNames.TEAM_FORM_DIALOG]: EditTeamFormDialog,
  [DialogNames.SEGMENT_LABELS_DIALOG]: SegmentLabelsDialog,
  [DialogNames.EXCLUSION_LIST_FORM_DIALOG]: ExclusionListFormDialog,
  [DialogNames.AUDIT_LOG_DIALOG]: AuditLogCompareDialog,
  [DialogNames.EXPIRED_DATE_DIALOG]: ExpiredDateDialog,
  [DialogNames.SEGMENT_GROUP_FORM_DIALOG]: EditSegmentGroupFormDialog,
  [DialogNames.DOMAIN_LIST_FORM_DIALOG]: DomainListFormDialog,
};

export default dialogComponents;

export type DialogComponentMapType = typeof dialogComponents;
