import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from '../../../../services/logService';
import { scrapeUrlApi } from './scrapeUrlApi';
import { ScrapeUrlRequest, ScrapeUrlResponse } from './scrapeUrlApiTypes';
import { getScrapeUrlState } from './scrapeUrlSelectors';
import { scrapeUrlFailure, scrapeUrlStart, scrapeUrlSuccessful } from './scrapeUrlSlice';
import { ScrapeUrlState } from './types';

export function* getScrapedUrlData() {
  try {
    const scrapeUrlState: ScrapeUrlState = yield select(getScrapeUrlState);

    const request: ScrapeUrlRequest = {
      url: scrapeUrlState.url,
      render: scrapeUrlState.render,
    };
    const response: ScrapeUrlResponse = yield call(scrapeUrlApi.scrapeUrl, request);

    yield put(scrapeUrlSuccessful(response));
  } catch (e) {
    notifyError(e);

    yield put(scrapeUrlFailure({ message: i18next.t('adminPanel.scrapeUrl.errors.failed') }));
  }
}

export function* scrapeUrlSagas() {
  yield all([takeLatest(scrapeUrlStart.type, getScrapedUrlData)]);
}
