import { IconProps } from 'feather-icons-react';

export const PlusIcon = ({ className, fill = '#56365A', size = 20 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 9.16671V3.33337H9.16659V9.16671H3.33325V10.8334H9.16659V16.6667H10.8333V10.8334H16.6666V9.16671H10.8333Z"
      fill={fill}
    />
  </svg>
);
