import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { SEGMENT_GROUP_URL } from '../constants';
import { SegmentGroupListItem } from '../segmentGroupApiTypes';

export const segmentGroupListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    segmentGroupList: build.query<SegmentGroupListItem[], null>({
      query: () => ({ method: 'GET', url: `${SEGMENT_GROUP_URL}/` }),
      providesTags: ['SegmentGroup'],
    }),
  }),
});

export const { useSegmentGroupListQuery } = segmentGroupListApiSlice;
