import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { Team, TeamsState } from './types';

const initialState: TeamsState = {
  teams: {},
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state: TeamsState, { payload }: PayloadAction<Record<string, Team>>) => {
      state.teams = payload;
    },
  },
});

const getTeamsReducer = ({ teams }: AppState) => teams;

export const getAllTeams = (state: AppState) => getTeamsReducer(state).teams;

export const { setTeams } = teamsSlice.actions;

export const teams = teamsSlice.reducer;
