import { Skeleton } from 'antd';
import { Search, X } from 'feather-icons-react';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'usehooks-ts';

import { IconButton, Input } from 'components/common';

import { useCategoriesQuery } from 'modules/apiData/apiDataApiSlice';
import {
  getCategoriesDataObject,
  getCategoriesSearchString,
  getCategoriesValues,
} from 'modules/segment/segmentSelectors';
import { setCategoriesDataObject, setCategoriesSearchString } from 'modules/segment/segmentSlice';

import { CheckBoxTree } from './CheckBoxTree';
import { filterNodes, mapCategoriesToObject } from './utils';

export const MIN_SEARCH_STRING_LENGTH = 1;

export const CheckboxTreeContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: categories, isLoading } = useCategoriesQuery(null);
  const searchString = useSelector(getCategoriesSearchString);
  const selectedIds = useSelector(getCategoriesValues);
  const categoriesDataObject = useSelector(getCategoriesDataObject);
  const debouncedSearchString = useDebounce(searchString, 250);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      dispatch(setCategoriesSearchString(event.target.value)),
    [dispatch],
  );

  const handleSearchClear = useCallback(() => {
    dispatch(setCategoriesSearchString(''));
  }, [dispatch]);

  useEffect(() => {
    const searchString = debouncedSearchString;

    dispatch(setCategoriesSearchString(searchString));

    if (searchString.length > MIN_SEARCH_STRING_LENGTH) {
      const filteredValues = filterNodes(categoriesDataObject, searchString);

      dispatch(setCategoriesDataObject(filteredValues));
    } else {
      const filteredValues = filterNodes(categoriesDataObject, '');

      dispatch(setCategoriesDataObject(filteredValues));
    }
    // TODO: exhaustive-deps rule disabling is needed to not deep into the maximum renderers issue. It should be fixed with the tree refactoring
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString, dispatch]);

  useEffect(() => {
    const mappedCategories = mapCategoriesToObject(categories ?? {}, selectedIds);

    dispatch(setCategoriesDataObject(mappedCategories));
    // TODO: exhaustive-deps rule disabling is needed to not deep into the maximum renderers issue. It should be fixed with the tree refactoring
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, dispatch]);

  return (
    <div className="flex h-full w-[53%] flex-col gap-y-2 overflow-hidden p-[1px]">
      <div className="relative">
        <Input
          value={searchString}
          addonBefore={<Search />}
          placeholder={t('forms.placeholders.search')}
          onChange={handleSearch}
        />
        {searchString.length ? (
          <IconButton
            size="small"
            className="absolute right-[10px] top-[10px] h-[20px] w-[20px] rounded-[50%]"
            onClick={handleSearchClear}
          >
            <X size={16} />
          </IconButton>
        ) : null}
      </div>
      <div className="flex-1 overflow-hidden">
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="h-[480px] overflow-auto">
            <CheckBoxTree />
          </div>
        )}
      </div>
    </div>
  );
};
