import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

type EmptyCategoryState = {
  isLoading: boolean;
  customCategoryId: string | null;
};

const initialState: EmptyCategoryState = {
  isLoading: false,
  customCategoryId: null,
};

export const emptyCustomCategorySlice = createSlice({
  name: 'emptyCustomCategory',
  initialState,
  reducers: {
    createEmptyCustomCategory: (state) => {
      state.isLoading = true;
      state.customCategoryId = null;
    },
    createEmptyCustomCategorySuccess: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.customCategoryId = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createEmptyCustomCategoryFailure: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
    },
  },
});

export const getEmptyCustomCategoryDataSelector = (state: AppState) => state.emptyCustomCategory;

export const {
  createEmptyCustomCategory,
  createEmptyCustomCategorySuccess,
  createEmptyCustomCategoryFailure,
} = emptyCustomCategorySlice.actions;

export const emptyCustomCategory = emptyCustomCategorySlice.reducer;
