import { Navigate } from 'react-router';

import { RolesPermission } from 'modules/adminPanel/users/types';
import { useCurrentRoleQuery } from 'modules/auth/authApiSlice';

import { ROUTES } from '../constants';

type ProtectedRouteProps = {
  children: JSX.Element;
};

export const AdminPanelAccessRoute = ({ children }: ProtectedRouteProps) => {
  const { data: userCurrent } = useCurrentRoleQuery(null);

  if (userCurrent?.role_name === RolesPermission.user) {
    return <Navigate replace to={ROUTES.ROOT} />;
  }

  return children;
};
