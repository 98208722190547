import classNames from 'classnames';
import { ChevronRight } from 'feather-icons-react';

export const CheckBoxExpandIcon = ({
  expanded,
  handleExpand,
}: {
  expanded: boolean;
  handleExpand: () => void;
}) => (
  <ChevronRight
    onClick={handleExpand}
    size={24}
    className={classNames('nano-checkbox-tree-expand-icon mr-2 transition-all', {
      'rotate-90': expanded,
    })}
  />
);
