import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ScrapeUrlRequest,
  ScrapeUrlResponse,
} from '../distanceChecker/scrapeUrl/scrapeUrlApiTypes';
import { SearchByVectorRequest, SearchByVectorResponse, SearchByVectorState } from './types';

export const SEARCH_BY_VECTOR_SLICE_NAME = 'searchByVector';

const initialState: SearchByVectorState = {
  isLoading: false,
  isLoadingScrape: false,
  keywords: null,
  matchingUrl: 'top',
  language: 'en',
  weightType: 'min',
  minSimilarity: 0.2,
  maxSimilarity: null,
  numOfURLs: 100,
  type: 'multi',
  vectors: [],
  urlList: [],
  message: '',
  categoryDistribution: {},
  percentageOfMatchedURLs: 0,
  isCardNarrow: false,
  url: '',
  render: true,
  scrapeUrl: null,
};

export const searchByVectorSlice = createSlice({
  name: SEARCH_BY_VECTOR_SLICE_NAME,
  initialState,
  reducers: {
    updateValues: (
      state: SearchByVectorState,
      { payload }: PayloadAction<SearchByVectorRequest>,
    ) => {
      return {
        ...state,
        ...payload,
      };
    },
    searchByVectorStart: (state: SearchByVectorState) => {
      state.isLoading = true;
      state.isCardNarrow = true;
    },
    toggleCardWidth(state: SearchByVectorState, { payload }: PayloadAction<boolean>) {
      state.isCardNarrow = payload;
    },
    searchByVectorSuccess: (
      state: SearchByVectorState,
      { payload }: PayloadAction<SearchByVectorResponse>,
    ) => {
      state.isLoading = false;
      state.vectors = payload.vectors;
      state.urlList = payload.urlList;
      state.categoryDistribution = payload.categoryDistribution;
      state.percentageOfMatchedURLs = payload.percentageOfMatchedURLs;
    },
    searchByVectorFailure: (
      state: SearchByVectorState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.message = payload.message;
      state.isCardNarrow = false;
    },
    resetSearchByVectorSearch: () => {
      return {
        ...initialState,
      };
    },
    scrapeUrlStart: (state: SearchByVectorState, { payload }: PayloadAction<ScrapeUrlRequest>) => {
      return {
        ...state,
        ...payload,
        ...{ isLoadingScrape: true },
      };
    },
    scrapeUrlSuccessful: (
      state: SearchByVectorState,
      { payload }: PayloadAction<ScrapeUrlResponse>,
    ) => {
      return {
        ...state,
        ...{ scrapeUrl: payload },
        ...{ isLoadingScrape: false },
      };
    },
    scrapeUrlFailure: (
      state: SearchByVectorState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoadingScrape = false;
      state.message = payload.message;
      state.scrapeUrl = null;
    },
  },
});
export const {
  updateValues,
  searchByVectorStart,
  searchByVectorSuccess,
  searchByVectorFailure,
  resetSearchByVectorSearch,
  toggleCardWidth,
  scrapeUrlStart,
  scrapeUrlSuccessful,
  scrapeUrlFailure,
} = searchByVectorSlice.actions;
export const searchByVector = searchByVectorSlice.reducer;
