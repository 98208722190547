import { api } from 'services/api';

import {
  ALL_GEO_LANGUAGES,
  EMOTION_DISTRIBUTION_URL,
  INTENT_CONFIRMATION_URL,
  INTENT_SEASONALITY_URL,
  LANGUAGES_BY_GEO,
  SEGMENT_GEO_LANGUAGES,
  SEGMENT_SCALE_IMPACT_URL,
  SENTIMENT_DISTRIBUTION_URL,
  TOTAL_REACH_URL,
} from './constants';
import { ChartsPayload } from './types';

const getEmotionDistribution = (data: ChartsPayload) => {
  return api.post(EMOTION_DISTRIBUTION_URL, data);
};

const getSentimentDistribution = (data: ChartsPayload) => {
  return api.post(SENTIMENT_DISTRIBUTION_URL, data);
};

const getTotalReach = (data: ChartsPayload) => {
  return api.post(TOTAL_REACH_URL, data);
};

const getIntentSeasonality = (data: ChartsPayload) => {
  return api.post(INTENT_SEASONALITY_URL, data);
};

const getIntentConfirmation = (data: ChartsPayload) => {
  return api.post(INTENT_CONFIRMATION_URL, data);
};

const getGeoLanguages = () => {
  return api.get(ALL_GEO_LANGUAGES);
};

const getLanguagesByGeo = (iso: string) => {
  return api.get(`${LANGUAGES_BY_GEO}/${iso}`);
};

const getSegmentGeoLanguages = (data: ChartsPayload) => {
  return api.post(SEGMENT_GEO_LANGUAGES, data);
};

const getScaleImpact = (data: ChartsPayload) => {
  return api.post(SEGMENT_SCALE_IMPACT_URL, data);
};

export const chartsApi = {
  getEmotionDistribution,
  getSentimentDistribution,
  getTotalReach,
  getIntentSeasonality,
  getIntentConfirmation,
  getGeoLanguages,
  getLanguagesByGeo,
  getSegmentGeoLanguages,
  getScaleImpact,
};
