import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { keywordGroupApi } from '../keywordGroupApi';
import { KeywordGroupResponse } from '../keywordGroupApiTypes';
import { setKeywordGroupCopyId } from '../keywordGroupSlice';
import { keywordGroupListApiSlice } from '../list/keywordGroupListApiSlice';

export function* copyKeywordGroupSaga({
  payload: { id, copyText },
}: PayloadAction<{ id: string; copyText: string }>) {
  try {
    yield put(setKeywordGroupCopyId(id));
    const keywordGroup: KeywordGroupResponse = yield call(keywordGroupApi.getKeywordGroupById, id);
    const name = `${keywordGroup.name} ${copyText}`;
    const newKeywordGroup = { ...keywordGroup, name, id: null };

    yield call(keywordGroupApi.saveKeywordGroup, newKeywordGroup);
    yield put(keywordGroupListApiSlice.util.invalidateTags(['KeywordGroups']));
    yield put(setKeywordGroupCopyId(null));
  } catch (error) {
    notifyError(error);
    yield put(setKeywordGroupCopyId(null));
  }
}
