import { api } from 'services/api';

import { DOMAIN_LIST_URL } from './constants';
import { DomainListRequestValues, DomainListListItem } from './domainListApiTypes';

const getDomainList = () => {
  return api.get(`${DOMAIN_LIST_URL}/all`);
};

const deleteDomainListItem = (id: string) => {
  return api.delete(`${DOMAIN_LIST_URL}/${id}`);
};

const getDomainListById = (id: string | undefined) => {
  return api.get<DomainListListItem>(`${DOMAIN_LIST_URL}/${id}`);
};

const saveDomainList = (data: DomainListRequestValues) => {
  return api.post(`${DOMAIN_LIST_URL}/`, data);
};

const updateDomainList = (id: string | undefined, data: DomainListRequestValues) => {
  return api.put(`${DOMAIN_LIST_URL}/${id}`, data);
};

export const domainListApi = {
  getDomainList,
  deleteDomainListItem,
  getDomainListById,
  saveDomainList,
  updateDomainList,
};
