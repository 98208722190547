import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserRequestValues, UserState } from './types';

export const USER_SLICE_NAME = 'user';

export const initialState: UserState = {
  id: '',
  name: '',
  surname: '',
  email: '',
  companyId: null,
  teamId: null,
  labels: [],
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  userToDeleteId: '',
  currentStep: 1,
};

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    setUser: (state: UserState, { payload }: PayloadAction<UserRequestValues>) => {
      Object.assign(state, payload);
    },
    saveUser: (state: UserState) => {
      state.isLoading = true;
    },
    updateValues: (state: UserState, { payload }: PayloadAction<UserRequestValues>) => {
      return {
        ...state,
        ...payload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveUserFailure: (state: UserState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
    },
    saveUserSuccess: (state: UserState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteUser: (state: UserState, { payload }: PayloadAction<{ id: string }>) => {
      state.isLoading = true;
    },
    deleteUserSuccess: (state: UserState) => {
      state.isLoading = false;
      state.userToDeleteId = '';
      state.isCreated = false;
    },
    deleteUserFailure: (
      state: UserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    setUserToDeleteId: (state: UserState, { payload }: PayloadAction<string>) => {
      state.userToDeleteId = payload;
    },
    clearUser: () => initialState,
    updateUser: (state: UserState, { payload }: PayloadAction<number>) => {
      state.isLoading = true;
      state.currentStep = payload;
    },
    updateUserSuccess: (state: UserState) => {
      state.isLoading = false;
      state.isCreated = true;
    },
    updateUserFailure: (
      state: UserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    setUserToUpdateId: (state: UserState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
    fetchUserDetails: (
      state: UserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ id?: string; navPath?: string }>,
    ) => {
      state.isLoading = true;
    },
    fetchUserSuccess: (
      state: UserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ navPath: string }>,
    ) => {
      state.isLoading = false;
    },
    fetchUserDetailsFailure: (
      state: UserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
    },
  },
});

export const {
  updateValues,
  saveUserFailure,
  setUser,
  saveUser,
  saveUserSuccess,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
  setUserToDeleteId,
  clearUser,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  setUserToUpdateId,
  fetchUserDetails,
  fetchUserSuccess,
  fetchUserDetailsFailure,
} = userSlice.actions;

export const user = userSlice.reducer;
