import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ScrapeUrlRequest, ScrapeUrlResponse } from './scrapeUrlApiTypes';
import { ScrapeUrlState } from './types';

export const SCRAPE_URL_SLICE_NAME = 'scrape_url';

const initialState: ScrapeUrlState = {
  url: '',
  render: true,
  content: '',
  adClutter: '',
  contentLength: '',
  tokenCount: '',
  language: '',
  metadata: null,
  version: '',
  timestamp: '',
  headings: [],
  traceId: '',

  isLoading: false,
  failMessage: '',
};

export const scrapeUrlSlice = createSlice({
  name: SCRAPE_URL_SLICE_NAME,
  initialState,
  reducers: {
    scrapeUrlStart: (state: ScrapeUrlState, { payload }: PayloadAction<ScrapeUrlRequest>) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: true },
      };
    },
    scrapeUrlSuccessful: (state: ScrapeUrlState, { payload }: PayloadAction<ScrapeUrlResponse>) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: false },
      };
    },
    scrapeUrlFailure: (state: ScrapeUrlState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.failMessage = payload.message;
    },
  },
});

export const { scrapeUrlStart, scrapeUrlSuccessful, scrapeUrlFailure } = scrapeUrlSlice.actions;

export const scrapeUrlReducer = scrapeUrlSlice.reducer;
