import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const SegmentCard = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <div
    className={classNames(
      'relative rounded-[20px] bg-secondary-white p-10 shadow-card-shadow',
      className,
    )}
  >
    {children}
  </div>
);

export const SegmentCardTitle = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => <h3 className={classNames('mb-9 text-heading-m font-semibold', className)}>{children}</h3>;
