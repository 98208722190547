import { Tour as AntdTour, TourProps } from 'antd';
import { ArrowLeft, ArrowRight } from 'feather-icons-react';
import { i18nFromLocale, useNanoTranslation } from 'i18n/i18nUtils';
import { useCallback, useEffect, useMemo } from 'react';

import { NanoBotImg } from 'components/common/nanoBot';

import { useNanoTourContext } from './NanoTourProvider';
import { NANO_TOUR_IDS } from './constants';
import { tourTrans } from './tourTrans';

const i18n = i18nFromLocale(tourTrans);

const stepBaseProps = {
  arrow: false,
  nextButtonProps: {
    children: <ArrowRight size={16} />,
  },
  prevButtonProps: {
    children: <ArrowLeft size={16} />,
  },
};

const TOUR_CURRENT_STEP_CLASS = 'tour-current-step';

export const NanoTour = () => {
  const { isOpen, endTour } = useNanoTourContext();

  const { t } = useNanoTranslation(i18n);

  const steps: TourProps['steps'] = useMemo(
    () => [
      {
        ...stepBaseProps,
        title: <TourTitle />,
        description: t('logoDesc'),
        target: () => document.querySelector(`[data-tour="${NANO_TOUR_IDS.logo}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('homeTitle')}</TourTitle>,
        description: t('homeDesc'),
        target: () => document.querySelector(`[data-tour="${NANO_TOUR_IDS.home}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('buildTitle')}</TourTitle>,
        description: t('buildDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.planning}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('manageTitle')}</TourTitle>,
        description: t('manageDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.managementSidebar}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('reportTitle')}</TourTitle>,
        description: t('reportDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.reporting}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('libraryTitle')}</TourTitle>,
        description: t('libraryDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.library}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('universityTitle')}</TourTitle>,
        description: t('universityDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.university}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('botTitle')}</TourTitle>,
        description: t('botDesc'),
        target: () => document.querySelector(`[data-tour="${NANO_TOUR_IDS.bot}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('profileTitle')}</TourTitle>,
        description: t('profileDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.profile}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('planningCardTitle')}</TourTitle>,
        description: t('planningCardDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.planningCard}"]`) as HTMLElement,
        placement: 'right',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('managementCardTitle')}</TourTitle>,
        description: t('managementCardDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.managementCard}"]`) as HTMLElement,
        placement: 'left',
      },
      {
        ...stepBaseProps,
        title: <TourTitle>{t('reportingCardTitle')}</TourTitle>,
        description: t('reportingCardDesc'),
        target: () =>
          document.querySelector(`[data-tour="${NANO_TOUR_IDS.reportingCard}"]`) as HTMLElement,
        placement: 'left',
      },
    ],
    [t],
  );

  const addCurrentStepClass = useCallback(
    (index: number) => {
      const currentStep = steps[index];

      if (typeof currentStep.target === 'function') {
        const target = currentStep.target() as HTMLElement;

        target.classList.add(TOUR_CURRENT_STEP_CLASS);
      }
    },
    [steps],
  );

  useEffect(() => {
    if (isOpen) {
      addCurrentStepClass(0);
    }
  }, [isOpen, addCurrentStepClass]);

  const removeCurrentStepClasses = useCallback(() => {
    const activeSteps = document.querySelectorAll(`.${TOUR_CURRENT_STEP_CLASS}`);

    activeSteps.forEach((step) => {
      step.classList.remove(TOUR_CURRENT_STEP_CLASS);
    });
  }, []);

  const handleClose = useCallback(() => {
    endTour();
    removeCurrentStepClasses();
  }, [endTour, removeCurrentStepClasses]);

  const handleStepChange = useCallback(
    (current: number) => {
      removeCurrentStepClasses();

      addCurrentStepClass(current);
    },
    [addCurrentStepClass, removeCurrentStepClasses],
  );

  return (
    <AntdTour
      steps={steps}
      open={isOpen}
      onClose={handleClose}
      onChange={handleStepChange}
      gap={{ offset: 1, radius: 16 }}
      indicatorsRender={(current, total) => (
        <span>
          <span className="text-primary-electric-cyan-100">{current + 1}</span> / {total}
        </span>
      )}
      mask={{
        style: {
          opacity: 0.9,
        },
        color: '#4c2a55',
      }}
    />
  );
};

const TourTitle = ({ children }: { children?: React.ReactNode }) => {
  return (
    <>
      <span className="absolute top-7 left-7">
        <NanoBotImg className="h-[56px]" />
      </span>
      {children}
    </>
  );
};
