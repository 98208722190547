import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CustomCategoryPayload,
  CustomCategoryResponse,
  CustomCategoryState,
  PersonasMode,
} from './types';

export const CUSTOM_CATEGORY_SLICE_NAME = 'customCategory';

const initialState: CustomCategoryState = {
  name: '',
  description: '',
  keywordGroups: [],
  isTouched: false,
  parentId: null,
  isLoadingCustomCategory: false,
  error: null,
  errorStatus: null,
  id: '',
  isCreated: false,
  message: '',
  isUpdating: false,
  isUpdated: false,
  mode: PersonasMode.customCategory,
  lastLoadedId: null,
  customCategoryCreatedId: null,
};

export const customCategorySlice = createSlice({
  name: CUSTOM_CATEGORY_SLICE_NAME,
  initialState,
  reducers: {
    changeCustomCategoryMode: (
      state: CustomCategoryState,
      { payload }: PayloadAction<PersonasMode>,
    ) => {
      state.mode = payload;
    },
    setIsCustomCategoryLoading: (
      state: CustomCategoryState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoadingCustomCategory = payload;
    },
    setCustomCategoryId: (state: CustomCategoryState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
    setCustomCategoryName: (state: CustomCategoryState, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setCustomCategoryDescription: (
      state: CustomCategoryState,
      { payload }: PayloadAction<string>,
    ) => {
      state.description = payload;
    },
    setCustomCategoryParentId: (state: CustomCategoryState, { payload }: PayloadAction<string>) => {
      state.parentId = payload;
    },
    clearCustomCategoryParentId: (state: CustomCategoryState) => {
      state.parentId = null;
    },
    deleteCustomCategoryStart: (state: CustomCategoryState) => {
      state.isLoadingCustomCategory = true;
    },
    deleteCustomCategorySuccess: (state: CustomCategoryState) => {
      state.isLoadingCustomCategory = false;
      state.lastLoadedId = null;
    },
    deleteCustomCategoryFailure: (state: CustomCategoryState, { payload }) => {
      state.isLoadingCustomCategory = false;
      state.error = payload;
    },
    setKeywordGroups: (state: CustomCategoryState, { payload }: PayloadAction<string[]>) => {
      state.keywordGroups = payload;
      state.isTouched = true;
    },
    resetKeywordGroups: (state: CustomCategoryState) => {
      state.keywordGroups = [];
    },
    saveCustomCategorySuccess: (
      state: CustomCategoryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<CustomCategoryResponse>,
    ) => {
      Object.assign(state, initialState);
      state.isCreated = true;
      state.customCategoryCreatedId = payload.id;
    },
    saveCustomCategoryFailure: (
      state: CustomCategoryState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoadingCustomCategory = false;
      state.message = payload.message;
    },
    saveCustomCategory: (
      state: CustomCategoryState,
      { payload }: PayloadAction<CustomCategoryPayload>,
    ) => ({
      ...state,
      ...payload,
    }),
    updateValues: (
      state: CustomCategoryState,
      { payload }: PayloadAction<CustomCategoryResponse>,
    ) => ({
      ...state,
      ...payload,
    }),
    updateCustomCategory: (
      state: CustomCategoryState,
      {
        payload,
      }: PayloadAction<{
        id: string;
        data: CustomCategoryPayload;
      }>,
    ) => {
      Object.assign(state, payload.data);
      state.isUpdating = true;
      state.isUpdated = false;
    },
    updateCustomCategorySuccess: (state: CustomCategoryState) => {
      state.isUpdating = false;
      state.isUpdated = true;
    },
    updateCustomCategoryFailure: (
      state: CustomCategoryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isUpdating = false;
      state.isUpdated = false;
    },
    resetCustomCategory: (state: CustomCategoryState) => {
      state = initialState;

      return state;
    },
    fetchCustomCategorySuccess: (
      state: CustomCategoryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ navPath: string; id: string }>,
    ) => {
      state.isLoadingCustomCategory = false;
      state.lastLoadedId = payload.id;
      state.lastLoadedId = payload.id;
    },
    fetchCustomCategoryDetailsFailure: (
      state: CustomCategoryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message?: string | null; status?: number }>,
    ) => {
      state.isLoadingCustomCategory = false;
      state.error = payload.message;
      state.errorStatus = payload.status;
    },
    fetchCustomCategoryDetails: (
      state: CustomCategoryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ id: string; navPath?: string }>,
    ) => {
      if (state.lastLoadedId !== payload.id) {
        state.isLoadingCustomCategory = true;
      }
    },

    initCustomCategoryDetails: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: CustomCategoryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ id: string }>,
    ) => {},
    resetError: (state: CustomCategoryState, { payload }: PayloadAction<null>) => {
      state.error = payload;
      state.errorStatus = payload;
    },
    resetIsCreatedOrIsUpdated: (
      state: CustomCategoryState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isUpdated = payload;
      state.isCreated = payload;
    },
    clearCustomCategoryId: (state: CustomCategoryState) => {
      state.customCategoryCreatedId = null;
    },
    clearCustomCategory: () => initialState,
    removeKeywordGroupValue: (state: CustomCategoryState, { payload }: PayloadAction<string>) => {
      state.keywordGroups = state.keywordGroups.filter((id: string) => id !== payload);
    },
  },
});

export const {
  changeCustomCategoryMode,
  setCustomCategoryId,
  setKeywordGroups,
  removeKeywordGroupValue,
  resetKeywordGroups,
  deleteCustomCategoryStart,
  deleteCustomCategorySuccess,
  deleteCustomCategoryFailure,
  saveCustomCategory,
  saveCustomCategorySuccess,
  saveCustomCategoryFailure,
  updateValues,
  updateCustomCategory,
  updateCustomCategoryFailure,
  updateCustomCategorySuccess,
  fetchCustomCategorySuccess,
  fetchCustomCategoryDetails,
  fetchCustomCategoryDetailsFailure,
  initCustomCategoryDetails,
  clearCustomCategory,
  resetError,
  resetIsCreatedOrIsUpdated,
  clearCustomCategoryId,
  setCustomCategoryParentId,
} = customCategorySlice.actions;

export const customCategory = customCategorySlice.reducer;
