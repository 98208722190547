import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { DialogComponentMapType } from './dialogComponents';
import { openDialog } from './dialogSlice';
import { DialogNames } from './types';

type DialogComponentParams<T extends DialogNames> = Parameters<DialogComponentMapType[T]>;

type UseDialogHook = <T extends DialogNames>(
  name: T,
) => [(props?: Omit<DialogComponentParams<T>[0], 'open' | 'onClose'>) => void];

export const useDialog: UseDialogHook = (name) => {
  const idRef = useRef(uuid());
  const id = idRef.current;

  const dispatch = useDispatch();

  return [(props) => dispatch(openDialog({ id, name, props }))];
};
