import { IconProps } from 'feather-icons-react';

export const AutomatedPdfIcon = ({ className, fill = 'currentColor', size = 48 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.5 28.4715V18.7744L27.2187 12.0413H18.625C17.7962 12.0413 17.0013 12.3565 16.4153 12.9177C15.8292 13.479 15.5 14.2401 15.5 15.0338V28.5001H17V15.2373C17 14.5001 17.2271 14.2564 17.5201 13.9758C17.8132 13.6952 18.2106 13.5375 18.625 13.5375H27.2187V16.53C27.2187 17.1253 27.4657 17.6962 27.9052 18.1171C28.3448 18.538 28.9409 18.7744 29.5625 18.7744H31.9128L32 28.4715H33.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7763 29.9552V36.0001H17.971V33.9778H19.136C19.6219 33.9778 20.0333 33.8924 20.3686 33.7199C20.7032 33.5459 20.9569 33.3071 21.1288 33.0036C21.3005 32.7006 21.3859 32.3546 21.3859 31.9665C21.3859 31.5823 21.3005 31.2383 21.1289 30.9353C20.959 30.63 20.7074 30.3902 20.3748 30.2161C20.0435 30.0416 19.6352 29.9552 19.1513 29.9552H16.7763ZM17.971 33.9778H17.9398V33.9479H17.971V33.9778ZM19.6731 32.9163C19.8522 32.8228 19.9844 32.6942 20.0699 32.5305C20.1573 32.3669 20.2011 32.1789 20.2011 31.9665C20.2011 31.7542 20.1573 31.5671 20.0699 31.4054C20.0278 31.3258 19.9742 31.255 19.909 31.1929C19.8417 31.1288 19.7621 31.074 19.6701 31.0285C19.4911 30.9369 19.2602 30.8911 18.9774 30.8911H17.9398V33.0566H18.9834C19.2642 33.0566 19.4941 33.0098 19.6731 32.9163Z"
      fill={fill}
    />
    <path
      d="M27.8464 29.9552V36.0001H29.0411V33.4576H31.6382V32.4889H29.0411V30.9239H31.9128V29.9552H27.8464Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9631 29.9552V36.0001H24.1122C24.7511 36.0001 25.2997 35.8795 25.757 35.6369C26.2162 35.3944 26.5674 35.0461 26.8103 34.5929C27.0552 34.1396 27.1769 33.5989 27.1769 32.9718C27.1769 32.3467 27.0562 31.8079 26.8134 31.3566C26.5705 30.9054 26.2224 30.5591 25.7693 30.3185C25.3182 30.0759 24.7797 29.9552 24.155 29.9552H21.9631ZM25.1379 31.1774C25.4185 31.3242 25.6307 31.5461 25.7745 31.8446C25.9185 32.1415 25.9914 32.5168 25.9914 32.9718C25.9914 33.4268 25.9186 33.804 25.7745 34.1049C25.6307 34.4033 25.4165 34.6272 25.1318 34.7779C24.8467 34.9268 24.489 35.0022 24.0573 35.0022H23.1578V30.9532H24.0909C24.5103 30.9532 24.8589 31.0286 25.1379 31.1774Z"
      fill={fill}
    />
  </svg>
);
