import { sessionStorageService } from 'services/sessionStorage';

import { SegmentState } from './types';

const SEGMENT_KEY = 'segment';
const saveSegment = (data: Partial<SegmentState>) => {
  const currentData = sessionStorageService.getItem(SEGMENT_KEY) as SegmentState;

  sessionStorageService.setItem(SEGMENT_KEY, { ...currentData, ...data });
};

const getSegment = (): Partial<SegmentState> | null => {
  return sessionStorageService.getItem(SEGMENT_KEY);
};

const clearSegment = () => {
  sessionStorageService.removeItem(SEGMENT_KEY);
};

export const segmentStorage = {
  saveSegment,
  getSegment,
  clearSegment,
};
