import classNames from 'classnames';
import { ButtonHTMLAttributes, memo } from 'react';

import { nanoTwMerge } from 'services/twMerge';

import { ButtonSize } from './types';

interface SmallButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
}

export const SmallButton = memo(
  ({ className, children, disabled, size = 'medium', ...rest }: SmallButtonProps) => (
    <button
      className={nanoTwMerge(
        classNames(
          'inline-flex w-full items-center justify-center rounded-lg font-bold text-primary-dark-purple-100',
          {
            'h-[16px] max-w-[79px] text-base-s': size === 'small',
            'h-[18px] max-w-[94px] text-base': size === 'medium',
            'h-[20px] max-w-[108px] text-base-l': size === 'large',
            'hover:text-secondary-purple active:text-primary-dark-purple-80': !disabled,
            'text-text-10': disabled,
          },
        ),
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  ),
);
SmallButton.displayName = 'SmallButton';
