import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { customCategoriesApi } from '../customCategoriesApi';
import { getCustomCategoryId } from '../customCategorySelectors';
import { deleteCustomCategoryFailure, deleteCustomCategorySuccess } from '../customCategorySlice';
import { customCategoriesListApiSlice } from '../list/customCategoriesListApiSlice';

export function* deleteCustomCategorySaga() {
  try {
    const id: string = yield select(getCustomCategoryId);

    yield call(customCategoriesApi.deleteCustomCategory, id);
    yield put(customCategoriesListApiSlice.util.invalidateTags(['CustomCategories']));
    yield put(deleteCustomCategorySuccess());
  } catch (error) {
    yield put(
      deleteCustomCategoryFailure({
        message: i18next.t('adminPanel.customCategories.errors.customCategoryDeleteFailed'),
      }),
    );
    notifyError(error);
  }
}
