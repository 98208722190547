import { bugsnagClient } from '../bugsnag';

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;

  return String(error);
}

export const reportError = ({ message }: { message: string }) => {
  bugsnagClient.notify(message);
};

export const notifyError = (error: unknown) => {
  reportError({ message: getErrorMessage(error) });
};
