import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { notifyError } from 'services/logService';

import { defaultBucketState } from '../defaultBucketState';
import { segmentsApi } from '../segmentApi';
import {
  BriefUploadResponse,
  CategoryGroup,
  EmotionGroup,
  TargetCombinations,
  KeywordEntityGroup,
  KeywordGroup,
  SentimentGroup,
  TargetCombinationsTypes,
} from '../segmentApiTypes';
import { setPerformance, setBuckets, getBriefUploadDataSuccess } from '../segmentSlice';

// TODO: replace with existing extractBuckets function when the brief-upload response format becomes acceptable
export const extractBuckets = (targetCombinations: TargetCombinations[]) => {
  return targetCombinations.map((combination) => {
    return combination.reduce(
      (acc, val) => {
        const allKeys = Object.keys(val);

        if (allKeys.includes(TargetCombinationsTypes.keywords)) {
          acc['keywords'] = {
            isTouched: true,
            isIncluded: !(val as KeywordGroup).exclude,
            keywordsText: (val as KeywordGroup).keywords.join(', '),
            keywordsValues: (val as KeywordGroup).keywords,
            uploadedValues: [],
            intentPrompt: (val as KeywordGroup).intentPrompt,
          };
        }
        if (allKeys.includes(TargetCombinationsTypes.sentiment)) {
          acc['sentiments'] = {
            isTouched: true,
            isIncluded: !(val as SentimentGroup).exclude,
            sentimentsValue: (val as SentimentGroup).sentiment,
          };
        }
        if (allKeys.includes(TargetCombinationsTypes.categories)) {
          acc['categories'] = {
            isTouched: true,
            isIncluded: !(val as CategoryGroup).exclude,
            categoriesValues: (val as CategoryGroup).categories,
          };
        }
        if (allKeys.includes(TargetCombinationsTypes.languages)) {
          acc['languages'] = {
            isTouched: true,
            isIncluded: false,
            languagesValues: [],
          };
        }
        if (allKeys.includes(TargetCombinationsTypes.emotions)) {
          acc['emotions'] = {
            isTouched: true,
            isIncluded: !(val as EmotionGroup).exclude,
            emotionsValues: (val as EmotionGroup).emotions,
          };
        }
        if (allKeys.includes(TargetCombinationsTypes.keywordEntities)) {
          acc['entity'] = {
            isTouched: true,
            isIncluded: !(val as KeywordEntityGroup).exclude,
            entity: (val as KeywordEntityGroup).keywordEntities,
          };
        }
        if (allKeys.includes(TargetCombinationsTypes.customCategories)) {
          acc['personas'] = {
            isTouched: true,
            isIncluded: false,
            personasValues: [],
          };
        }

        acc.id = uuid();

        return acc;
      },
      { ...defaultBucketState },
    );
  });
};

export function* segmentBriefUploadSaga({ payload }: PayloadAction<FormData>) {
  try {
    const briefUploadData: BriefUploadResponse = yield call(
      segmentsApi.getBriefUploadData,
      payload,
    );

    const buckets = extractBuckets(briefUploadData.targetCombinations as TargetCombinations[]);

    const { ctr, viewability, attentionMetric } = briefUploadData;

    const performance = {
      ctr,
      viewability,
      attentionMetric,
    };

    yield put(setPerformance(performance));
    yield put(setBuckets(buckets));
    yield put(getBriefUploadDataSuccess());
  } catch (error) {
    notifyError(error);
  }
}
