import { MappedCategory } from 'modules/segment/segmentForms/ManualStepForm/components/Categories/types';

import { SegmentState } from './types';

export const checkNodeChildren = (
  state: SegmentState,
  node: MappedCategory,
  newCheckedState: boolean,
) => {
  node.children?.forEach((childId) => {
    const childNode = state.currentBucket.categoriesDataObject[childId];

    childNode.checked = newCheckedState;

    if (node.children) {
      checkNodeChildren(state, childNode, newCheckedState);
    }
  });
};

export const checkParentNode = (state: SegmentState, node: MappedCategory) => {
  if (node.parentId) {
    const parent = state.currentBucket.categoriesDataObject[node.parentId];

    parent.checked = parent.children?.every(
      (childId: string) => state.currentBucket.categoriesDataObject[childId].checked,
    );

    checkParentNode(state, parent);
  }
};

export const handleCategoryNodeCheck = (state: SegmentState, payload: string) => {
  const selectedCategoriesIds: string[] = [];
  const node = state.currentBucket.categoriesDataObject[payload];

  node.checked = !node.checked;

  if (node.children) {
    checkNodeChildren(state, node, node.checked);
  }

  checkParentNode(state, node);

  Object.values(state.currentBucket.categoriesDataObject).forEach(
    ({ checked, id, parentId }: MappedCategory) => {
      if (!checked) return;

      if (parentId !== null) {
        const parent = state.currentBucket.categoriesDataObject[parentId];

        if (!parent.checked) {
          const checkedChildren = parent.children.filter(
            (childId: string) => state.currentBucket.categoriesDataObject[childId].checked,
          );

          if (checkedChildren.length === parent.children.length) {
            if (!selectedCategoriesIds.includes(parent.id)) {
              selectedCategoriesIds.push(parent.id);
            }

            parent.children.forEach((childId: string) => {
              selectedCategoriesIds.splice(selectedCategoriesIds.indexOf(childId), 1);
            });
          } else {
            checkedChildren.forEach((childId: string) => {
              if (!selectedCategoriesIds.includes(childId)) {
                selectedCategoriesIds.push(childId);
              }
            });
          }
        }
      } else {
        selectedCategoriesIds.push(id);
      }
    },
  );
  state.currentBucket.categories.categoriesValues = selectedCategoriesIds;
};
