import { AppState } from 'redux/store/store';

export const getSegmentGroup = ({ segmentGroup }: AppState) => segmentGroup;

export const getIsSegmentGroupLoading = (state: AppState) => getSegmentGroup(state).isLoading;

export const getSegmentGroupToDeleteId = (state: AppState) =>
  getSegmentGroup(state).segmentGroupToDeleteId;

export const getSegmentGroupId = (state: AppState) => getSegmentGroup(state).id;
