import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Paragraph, Tag } from 'components/common';

import {
  getCategoriesDataObject,
  getCategoriesValues,
  isSegmentCategoriesIncluded,
} from 'modules/segment/segmentSelectors';
import { removeAllCategoriesValues, removeCategoryValue } from 'modules/segment/segmentSlice';

export const SelectedCategoriesIds = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedIds = useSelector(getCategoriesValues);
  const isIncluded = useSelector(isSegmentCategoriesIncluded);

  const isSelectionPopulated = selectedIds.length > 0;
  const categoriesDataObject = useSelector(getCategoriesDataObject);

  const handleRemove = useCallback(
    (id: string) => {
      dispatch(removeCategoryValue(id));
    },
    [dispatch],
  );

  const handleRemoveAll = useCallback(() => {
    dispatch(removeAllCategoriesValues());
  }, [dispatch]);

  return (
    <div className="relative flex max-h-[525px] w-[42%] flex-col gap-y-2 self-start overflow-hidden rounded-xl bg-text-5 px-6 pt-4">
      <div className="flex h-[32px] items-center justify-between">
        <Paragraph large className="m-0 font-medium text-primary-dark-purple-100">
          {t('segment.tagList.yourSelection')}
        </Paragraph>
        {isSelectionPopulated && (
          <Button size="small" variant="text" type="button" onClick={handleRemoveAll}>
            {t('common.clearAll')}
          </Button>
        )}
      </div>
      <div className="flex flex-1 flex-wrap gap-1 overflow-auto pb-9">
        {!isSelectionPopulated && (
          <span className="text-text-60">{t('segment.intent.emptySelection')}</span>
        )}
        {selectedIds.map((id) => {
          return categoriesDataObject[id] ? (
            <Tag
              closable
              key={id}
              onClose={() => handleRemove(id)}
              variant={isIncluded ? 'green' : 'red'}
            >
              {categoriesDataObject[id].name}
            </Tag>
          ) : null;
        })}
      </div>
      <span className="absolute bottom-0 right-0 rounded-xl bg-counterBg px-4 py-3 text-base-s text-text-80">
        {selectedIds?.length || 0}
      </span>
    </div>
  );
};
