import { Tag } from 'antd';

import { CircleCloseIcon } from 'components/common/icons';

import { SegmentLabel } from 'modules/labels/types';

interface LabelsContainerProps {
  labels: SegmentLabel[];
  handleClose: (name: string) => void;
}

export const LabelsContainer = ({ labels, handleClose }: LabelsContainerProps) => {
  return (
    <div className="flex flex-wrap mt-2">
      {labels?.map(({ name, color, id }) => (
        <Tag
          key={id}
          className="h-[24px] mb-2 max-w-full flex align-middle whitespace-nowrap"
          bordered={false}
          color={color.background}
          onClose={() => handleClose(name)}
          closeIcon={<CircleCloseIcon fill={color.font} className="mt-[3px]" />}
          closable
        >
          <span className="overflow-hidden overflow-ellipsis text-base-s">
            {name.toUpperCase()}
          </span>
        </Tag>
      ))}
    </div>
  );
};
