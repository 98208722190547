import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getExpiredDate } from 'modules/segment/list/expiredDateSelectors';
import { updateExpiredDate } from 'modules/segment/list/expiredDateSlice';

import { DatePicker, Field, SubmitButton, Text } from '../common';
import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface ExpiredDateDialogProps extends DialogBaseProps {
  title: string;
  name: string;
  id: string;
  expiredDate: string;
}

export const ExpiredDateDialog = ({
  open,
  title,
  onClose,
  name,
  id,
  expiredDate,
}: ExpiredDateDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getExpiredDateData = useSelector(getExpiredDate);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      expiredDate,
    },
  });

  const onSubmit = handleSubmit((data) => {
    dispatch(updateExpiredDate({ expiredDate: data.expiredDate, segmentId: id }));
  });

  useEffect(() => {
    if (!getExpiredDateData.isExpiredDateDialogOpen) {
      onClose();
    }
  }, [getExpiredDateData.isExpiredDateDialogOpen, onClose]);

  return (
    <NanoDialog
      open={open}
      title={title}
      width={500}
      bodyStyle={{ minHeight: '320px' }}
      onClose={onClose}
    >
      <div className="flex">
        <Text className="text-base-l font-bold w-[100%]">{name}</Text>
      </div>
      <div className="flex text-primary-dark-purple-100 text-base-s opacity-60 mt-2">
        <Text className="w-[100%]">{id}</Text>
      </div>

      <form className="h-[150px] mt-8 overflow-y-auto" onSubmit={onSubmit}>
        <div className="flex items-center mt-4">
          <div className="w-[100%]">
            <Field
              name="expiredDate"
              control={control}
              className="mx-1"
              render={({ field }) => (
                <DatePicker
                  disabledBeforeToday
                  placeholder={t('forms.segment.create.expiredDate.placeholder')}
                  {...field}
                />
              )}
              rules={{
                validate: (value) => {
                  if (!value) return t('forms.errors.required');
                  if (new Date(value) < new Date()) return t('forms.errors.nonPastDate');
                },
              }}
              error={errors.expiredDate?.message}
            />
          </div>
        </div>
        <div className="mt-12 flex items-center justify-end gap-x-4">
          <SubmitButton isLoading={getExpiredDateData.isLoading}>
            {t('pages.segmentDetails.save')}
          </SubmitButton>
        </div>
      </form>
    </NanoDialog>
  );
};
