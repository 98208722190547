import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { call, put } from 'redux-saga/effects';

import { notifyError } from '../../../services/logService';
import { segmentsListApiSlice } from '../list/segmentsListApiSlice';
import { segmentsApi } from '../segmentApi';
import { reportSegmentFailure, updateSegmentReport } from '../segmentSlice';
import { ReportSegment } from '../types';

export function* segmentReportSaga({
  payload,
}: PayloadAction<{ startDate: string; endDate: string; id: string }>) {
  try {
    const response: ReportSegment = yield call(
      segmentsApi.getSegmentReport,
      payload.startDate,
      payload.endDate,
      payload.id,
    );

    yield put(updateSegmentReport(response));
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
  } catch (e) {
    notifyError(e);
    yield put(
      reportSegmentFailure({
        message: i18next.t('errors.segmentReportFailed'),
      }),
    );
  }
}
