import AntdCarousel, { CarouselProps as CarouselAntdProps, CarouselRef } from 'antd/es/carousel';
import { forwardRef, RefAttributes, ForwardedRef } from 'react';

type CarouselProps = CarouselAntdProps & RefAttributes<CarouselRef>;

export type { CarouselProps, CarouselRef };

export const Carousel = forwardRef((props: CarouselProps, ref: ForwardedRef<CarouselRef>) => (
  <AntdCarousel ref={ref} {...props} />
));

Carousel.displayName = 'Carousel';
