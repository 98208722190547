import { api } from 'services/api';

import { SEGMENT_GROUP_URL } from './constants';
import { SegmentGroupListItem, SegmentGroupRequestData } from './segmentGroupApiTypes';

const getSegmentGroups = () => {
  return api.get(`${SEGMENT_GROUP_URL}/`);
};

const getSegmentGroupById = (id: string) => {
  return api.get<SegmentGroupListItem>(`${SEGMENT_GROUP_URL}/${id}`);
};

const saveSegmentGroup = (data: SegmentGroupRequestData) => {
  return api.post(`${SEGMENT_GROUP_URL}/`, data);
};

const updateSegmentGroup = (id: string, data: SegmentGroupRequestData) => {
  return api.put(`${SEGMENT_GROUP_URL}/${id}`, data);
};

const deleteSegmentGroup = (id: string) => {
  return api.delete(`${SEGMENT_GROUP_URL}/${id}`);
};

export const segmentGroupApi = {
  getSegmentGroups: getSegmentGroups,
  getSegmentGroupById: getSegmentGroupById,
  saveSegmentGroup: saveSegmentGroup,
  updateSegmentGroup: updateSegmentGroup,
  deleteSegmentGroup: deleteSegmentGroup,
};
