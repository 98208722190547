import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExpiredDateState {
  isLoading: boolean;
  expiredDate: string;
  isExpiredDateDialogOpen: boolean;
}

const initialState: ExpiredDateState = {
  isLoading: false,
  expiredDate: '',
  isExpiredDateDialogOpen: false,
};

export const EXPIRED_DATE_SLICE_NAME = 'expiredDate';

export const expiredDateSlice = createSlice({
  name: EXPIRED_DATE_SLICE_NAME,
  initialState,
  reducers: {
    updateExpiredDate: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: ExpiredDateState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ expiredDate: string; segmentId: string }>,
    ) => {
      state.isLoading = true;
    },
    openExpiredDateDialog(state) {
      state.isExpiredDateDialogOpen = true;
    },
    closeExpiredDateDialog(state) {
      state.isExpiredDateDialogOpen = false;
      state.isLoading = false;
    },
  },
});
export const { updateExpiredDate, openExpiredDateDialog, closeExpiredDateDialog } =
  expiredDateSlice.actions;
export const expiredDate = expiredDateSlice.reducer;
