import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KeywordGroupResponse } from './keywordGroupApiTypes';
import { splitBySeparator } from './utils';

interface KeywordGroupState {
  name: string;
  id: string | null;
  isLoading: boolean;
  isSaving: boolean;
  keywordGroupDeleteId: string | null;
  keywordGroupCopyId: string | null;
  error?: string | null;
  success: string | null;
  keywords: string[];
  keywordsText: string;
  lastLoadedId: string | null;
  errorStatus?: number | null;
}

const initialState: KeywordGroupState = {
  name: '',
  id: null,
  isLoading: false,
  isSaving: false,
  keywordGroupDeleteId: null,
  keywordGroupCopyId: null,
  error: null,
  success: null,
  keywords: [],
  keywordsText: '',
  lastLoadedId: null,
  errorStatus: null,
};

export const keywordGroupSlice = createSlice({
  name: 'keywordGroup',
  initialState,
  reducers: {
    clearKeywordGroup: () => initialState,
    fetchKeywordGroupStart(
      state: KeywordGroupState,
      { payload }: PayloadAction<{ id?: string; isLoading: boolean }>,
    ) {
      if (state.lastLoadedId !== payload.id) {
        state.isLoading = payload.isLoading;
      }
    },
    fetchKeywordGroupSuccess(state, { payload }: PayloadAction<KeywordGroupResponse>) {
      state.isLoading = false;
      state.name = payload.name;
      state.id = payload.id;
      state.lastLoadedId = payload.id;
      state.keywords = payload.keywords;
      state.keywordsText = payload.keywords.join(',');
      state.error = null;
    },
    fetchKeywordGroupFailure(
      state: KeywordGroupState,
      { payload }: PayloadAction<{ message?: string | null; status?: number }>,
    ) {
      state.isLoading = false;
      state.error = payload.message;
      state.errorStatus = payload.status;
    },
    updateKeywordGroupStart(state: KeywordGroupState, { payload }) {
      state.isSaving = payload.isSaving;
      state.success = null;
      state.error = null;
    },
    updateKeywordGroupSuccess(
      state: KeywordGroupState,
      { payload }: PayloadAction<KeywordGroupResponse>,
    ) {
      state.isSaving = false;
      state.name = payload.name;
      state.id = payload.id;
      state.keywords = payload.keywords;
      state.success = 'success';
    },
    updateKeywordGroupFailure(
      state,
      { payload }: PayloadAction<{ message?: string | null; status?: number }>,
    ) {
      state.error = payload.message;
      state.errorStatus = payload.status;
      state.isSaving = false;
    },
    postKeywordGroupStart(state: KeywordGroupState, { payload }) {
      state.isSaving = payload.isSaving;
    },
    postKeywordGroupSuccess(
      state: KeywordGroupState,
      { payload }: PayloadAction<KeywordGroupResponse>,
    ) {
      state.isSaving = false;
      state.name = payload.name;
      state.id = payload.id;
      state.keywords = payload.keywords;
      state.success = 'success';
    },
    postKeywordGroupFailure(
      state: KeywordGroupState,
      {
        payload,
      }: PayloadAction<{
        message?: string | null;
        status?: number;
      }>,
    ) {
      state.isSaving = false;
      state.error = payload.message;
      state.errorStatus = payload.status;
    },
    deleteKeywordGroupStart(state: KeywordGroupState, { payload }: PayloadAction<{ id: string }>) {
      state.id = payload.id;
    },
    deleteKeywordGroupSuccess(state: KeywordGroupState) {
      if (state.lastLoadedId === state.keywordGroupDeleteId) {
        state.name = '';
        state.id = null;
        state.keywords = [];
        state.keywordsText = '';
        state.lastLoadedId = null;
      }
      state.keywordGroupDeleteId = null;
    },
    deleteKeywordGroupFailure(
      state: KeywordGroupState,
      { payload }: PayloadAction<{ message?: string | null }>,
    ) {
      state.error = payload.message;
      state.keywordGroupDeleteId = null;
    },
    copyKeywordGroupStart(
      state: KeywordGroupState,
      { payload }: PayloadAction<{ id: string; copyText: string }>,
    ) {
      state.id = payload.id;
    },
    copyKeywordGroupSuccess(state: KeywordGroupState) {
      state.keywordGroupCopyId = null;
    },
    copyKeywordGroupFailure(state: KeywordGroupState, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.keywordGroupCopyId = null;
    },
    setKeywordGroupDeleteId(state: KeywordGroupState, { payload }: PayloadAction<string | null>) {
      state.keywordGroupDeleteId = payload;
    },
    setKeywordGroupCopyId(state: KeywordGroupState, { payload }: PayloadAction<string | null>) {
      state.keywordGroupCopyId = payload;
    },
    setName: (state: KeywordGroupState, { payload }: PayloadAction<string>) => {
      state.name = payload;

      return state;
    },
    setKeywords: (state: KeywordGroupState, { payload }: PayloadAction<string>) => {
      state.keywordsText = payload;
      state.keywords = [...new Set(splitBySeparator(payload, /[,\n]/))];

      return state;
    },
    removeKeyword: (state: KeywordGroupState, { payload }: PayloadAction<string>) => {
      state.keywords = state.keywords.filter((value) => value !== payload);
      state.keywordsText = state.keywords.join(', ');

      return state;
    },
    resetError: (state: KeywordGroupState, { payload }: PayloadAction<null>) => {
      state.error = payload;
      state.errorStatus = payload;
    },
    resetSuccess: (state: KeywordGroupState, { payload }: PayloadAction<null>) => {
      state.success = payload;
    },
    clearAllKeywords: (state: KeywordGroupState) => {
      state.keywordsText = '';
      state.keywords = [];

      return state;
    },
  },
});

export const {
  fetchKeywordGroupStart,
  fetchKeywordGroupSuccess,
  fetchKeywordGroupFailure,
  updateKeywordGroupStart,
  updateKeywordGroupSuccess,
  updateKeywordGroupFailure,
  postKeywordGroupStart,
  postKeywordGroupSuccess,
  postKeywordGroupFailure,
  deleteKeywordGroupStart,
  deleteKeywordGroupSuccess,
  deleteKeywordGroupFailure,
  setKeywordGroupDeleteId,
  setKeywordGroupCopyId,
  copyKeywordGroupStart,
  clearKeywordGroup,
  resetSuccess,
  resetError,
} = keywordGroupSlice.actions;

export const keywordGroup = keywordGroupSlice.reducer;
