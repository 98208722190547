import { TeamsInfoForm } from 'modules/adminPanel/team/forms/teamsInfoForm';

import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface TeamFormDialogProps extends DialogBaseProps {
  title: string;
  id?: string;
  name?: string;
  companyId?: string;
  users?: string[];
}

export const EditTeamFormDialog = ({
  open,
  title,
  onClose,
  id,
  companyId,
  name,
  users,
}: TeamFormDialogProps) => {
  return (
    <NanoDialog open={open} title={title} width={616}>
      <TeamsInfoForm onClose={onClose} id={id} name={name} companyId={companyId} users={users} />
    </NanoDialog>
  );
};
