import { api } from 'services/api';

import { TEAMS_URL } from './constants';
import { TeamListItem, TeamRequestData } from './teamApiTypes';

const getTeams = () => {
  return api.get(TEAMS_URL);
};

const getTeamById = (id: string) => {
  return api.get<TeamListItem>(`${TEAMS_URL}/${id}`);
};

const saveTeam = (data: TeamRequestData) => {
  return api.post(TEAMS_URL, data);
};

const updateTeam = (id: string, data: TeamRequestData) => {
  return api.put(`${TEAMS_URL}/${id}`, data);
};

const deleteTeam = (id: string) => {
  return api.delete(`${TEAMS_URL}/${id}`);
};

export const teamApi = {
  getTeams,
  getTeamById,
  saveTeam,
  updateTeam,
  deleteTeam,
};
