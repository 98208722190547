import { Skeleton } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TagsList } from 'components/TagsList';
import { Checkbox, Input, Paragraph } from 'components/common';

import { useGetEntitiesSuggestQuery } from 'modules/apiData/apiDataApiSlice';
import { getBucketEntities } from 'modules/segment/segmentSelectors';
import { removeEntityTag, setEntity } from 'modules/segment/segmentSlice';

// Custom hook for debouncing the input value
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const Entity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const entity = useSelector(getBucketEntities);

  const [selectedEntities, setSelectedEntities] = useState<string[]>(
    entity.entity.map((item) => `${item.keyword} - ${item.entity_type}`),
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data: suggestedEntities, isLoading: isLoadingSuggestedEntities } =
    useGetEntitiesSuggestQuery(debouncedSearchQuery, {
      skip: debouncedSearchQuery.length < 3,
    });

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleCheckboxChange = useCallback(
    (value: string) => {
      setSelectedEntities((prevSelected) => {
        const isSelected = prevSelected.includes(value);
        const updatedSelection = isSelected
          ? prevSelected.filter((item) => item !== value)
          : [...prevSelected, value];

        // Update Redux state with the new selection
        dispatch(setEntity([...updatedSelection]));

        return updatedSelection;
      });
    },
    [dispatch],
  );

  const handleClear = useCallback(
    (tagItem: string) => {
      const updatedEntities = selectedEntities.filter((entity) => entity !== tagItem);

      setSelectedEntities(updatedEntities);
      dispatch(removeEntityTag(tagItem));
    },
    [dispatch, selectedEntities],
  );

  const handleClearAll = useCallback(() => {
    setSelectedEntities([]);
    dispatch(setEntity([]));
  }, [dispatch]);

  return (
    <div className="grid grid-cols-5 gap-x-9">
      <div className="col-span-2">
        <Paragraph className="text-base-l font-medium">
          {t('segment.entity.chooseEntity')}
        </Paragraph>
        <Input
          className="w-full max-w-[310px] border border-gray-300"
          placeholder={t('pages.management.searchPlaceholder')}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="flex flex-col mt-6">
          {isLoadingSuggestedEntities ? (
            <Skeleton active />
          ) : suggestedEntities && Object.keys(suggestedEntities.keywords).length === 0 ? (
            <Paragraph className="self-center">{t('segment.entity.noData')}</Paragraph>
          ) : (
            <div className="overflow-y-auto h-[460px]">
              {suggestedEntities &&
                Object.keys(suggestedEntities.keywords).map((key) => (
                  <div key={key} className="flex items-center mb-2">
                    <Checkbox
                      checked={selectedEntities.includes(
                        key + ' - ' + suggestedEntities.keywords[key],
                      )}
                      onChange={() =>
                        handleCheckboxChange(key + ' - ' + suggestedEntities.keywords[key])
                      }
                      className="mr-2"
                    />
                    <label>{key + ' - ' + suggestedEntities.keywords[key]}</label>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="col-span-3">
        <div className="w-full shrink-0 grow gap-3">
          <TagsList
            tagsItems={selectedEntities}
            isIncluded={entity?.isIncluded}
            onClearAll={handleClearAll}
            onClear={handleClear}
          />
        </div>
      </div>
    </div>
  );
};
