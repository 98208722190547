import { ManualStepValues } from 'modules/segment/types';

import { transformKeywords } from './utils';

export const getTargetCombinations = (buckets: ManualStepValues[]) =>
  buckets.map(({ keywords, categories, sentiments, languages, entity, personas, emotions }) => [
    transformKeywords(keywords),
    {
      exclude: !categories.isIncluded,
      categories: categories.categoriesValues,
    },
    {
      exclude: !sentiments.isIncluded,
      sentiment: {
        min: sentiments.sentimentsValue.min,
        max: sentiments.sentimentsValue.max,
      },
    },
    {
      exclude: !languages.isIncluded,
      languages: languages.languagesValues,
    },
    {
      exclude: !entity.isIncluded,
      keywordEntities: entity.entity.map((specValue) => ({
        keyword: specValue.keyword,
        // eslint-disable-next-line camelcase
        entity_type: specValue.entity_type,
      })),
    },
    {
      exclude: !personas.isIncluded,
      customCategories: personas.personasValues,
    },
    {
      exclude: !emotions.isIncluded,
      emotions: emotions.emotionsValues,
    },
  ]);
