import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExclusionListListItem } from './exclusionListApiTypes';
import { ExclusionListState } from './types';

export const EXCLUSION_LIST_SLICE_NAME = 'exclusion_list';

export const initialState: ExclusionListState = {
  id: '',
  urls: [],
  isLoading: false,
  itemToDeleteId: '',
  errorMessage: '',
  createdDate: '',
};

export const exclusionListSlice = createSlice({
  name: EXCLUSION_LIST_SLICE_NAME,
  initialState,
  reducers: {
    deleteExclusionListItem: (state: ExclusionListState) => {
      state.isLoading = true;
    },
    deleteExclusionListSuccess: (state: ExclusionListState) => {
      state.isLoading = false;
      state.itemToDeleteId = '';
    },
    deleteExclusionListFailure: (
      state: ExclusionListState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
    deleteAllExclusionListItems: (state: ExclusionListState) => {
      state.isLoading = true;
    },
    setExclusionListItemToDeleteId: (
      state: ExclusionListState,
      { payload }: PayloadAction<string>,
    ) => {
      state.itemToDeleteId = payload;
    },

    saveExclusionList: (state: ExclusionListState, { payload }: PayloadAction<string[]>) => {
      state.urls = [...payload];
      state.isLoading = true;
    },
    saveExclusionListSuccess: (state: ExclusionListState) => {
      state.isLoading = false;
    },
    saveOneExclusionListSuccess: (
      state: ExclusionListState,
      { payload }: PayloadAction<ExclusionListListItem>,
    ) => {
      state.isLoading = false;
      state.id = payload.id;
      state.urls = [...state.urls, payload.url];
      state.createdDate = payload.createdDate;
    },
    saveExclusionListFailure: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
  },
});

export const {
  deleteExclusionListItem,
  deleteAllExclusionListItems,
  deleteExclusionListSuccess,
  deleteExclusionListFailure,
  setExclusionListItemToDeleteId,
  saveExclusionList,
  saveExclusionListSuccess,
  saveOneExclusionListSuccess,
  saveExclusionListFailure,
} = exclusionListSlice.actions;

export const exclusionList = exclusionListSlice.reducer;
