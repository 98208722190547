import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { segmentsListApiSlice } from '../list/segmentsListApiSlice';
import { segmentsApi } from '../segmentApi';
import { SegmentDetailsResponse } from '../segmentApiTypes';
import { setIsSegmentLoading } from '../segmentSlice';
import { SegmentStatus } from '../types';

export function* changeStatusSaga({
  payload: { id, status },
}: PayloadAction<{ id: string; status: SegmentStatus }>) {
  try {
    yield put(setIsSegmentLoading(true));
    const segment: SegmentDetailsResponse = yield call(segmentsApi.getSegmentById, id);
    const updSegment = {
      ...segment,
      labels: segment.labels.map(({ name }) => name),
      status: status,
    };

    yield call(segmentsApi.updateSegment, id, updSegment);
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(setIsSegmentLoading(false));
  } catch (error) {
    notifyError(error);
    yield put(setIsSegmentLoading(false));
  }
}
