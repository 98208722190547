import classNames from 'classnames';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface UniversalBackgroundProps {
  children?: ReactNode;
  isOpen: boolean;
}

export const UniversalBackground = ({ children, isOpen }: UniversalBackgroundProps) => (
  <>
    {createPortal(
      <div
        className={classNames('fixed bottom-0 left-0 right-0 top-0 bg-text-100/30', {
          hidden: !isOpen,
        })}
      />,
      document.body,
    )}
    {children}
  </>
);
