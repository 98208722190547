import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Field, Input, SubmitButton } from 'components/common';

import { notificationService } from 'modules/notifications';

import { getCompany } from '../../companySelectors';
import { saveCompany, setCompanyToUpdateId, updateCompany, updateValues } from '../../companySlice';

type CompanyInfoFormProps = {
  id?: string;
  name?: string;
  address?: string;
  onClose: () => void;
};

export const CompanyInfoForm = ({ id, name, address, onClose }: CompanyInfoFormProps) => {
  const { t } = useTranslation();

  const currentCompany = useSelector(getCompany);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { name: name, address: address } });

  const onSubmit = handleSubmit((data) => {
    dispatch(updateValues(data));
    if (!id) {
      dispatch(saveCompany());
    } else {
      dispatch(setCompanyToUpdateId(id));
      dispatch(updateCompany());
    }
  });

  const showSuccessNotification = useCallback(() => {
    notificationService.showSuccess({
      key: 'companyFormSuccess',
      message: t('adminPanel.company.success.companySaveSuccess'),
    });
  }, [t]);

  useEffect(() => {
    if (!currentCompany.isLoading && currentCompany.isCreated) {
      onClose();
      showSuccessNotification();
    }
  }, [
    id,
    currentCompany,
    name,
    address,
    currentCompany.isLoading,
    currentCompany.isCreated,
    onClose,
    showSuccessNotification,
  ]);

  return (
    <form className="flex flex-col gap-y-9" onSubmit={onSubmit}>
      <Field
        name="name"
        label={t('adminPanel.company.dialog.name')}
        control={control}
        className="h-[80px]"
        render={({ field, fieldState: { invalid } }) => (
          <Input placeholder={t('adminPanel.company.dialog.name')} {...field} invalid={invalid} />
        )}
        rules={{
          required: 'Required',
        }}
        error={errors.name?.message}
      />
      <Field
        name="address"
        label={t('adminPanel.company.dialog.address')}
        control={control}
        className="h-[80px]"
        render={({ field, fieldState: { invalid } }) => (
          <Input
            placeholder={t('adminPanel.company.dialog.address')}
            {...field}
            invalid={invalid}
          />
        )}
        rules={{
          required: 'Required',
        }}
        error={errors.address?.message}
      />
      <div className="mt-2 flex items-center justify-end gap-x-4">
        <Button type="button" variant="outline" onClick={onClose}>
          {t('adminPanel.company.dialog.cancelButton')}
        </Button>
        <SubmitButton isLoading={currentCompany.isLoading}>
          {t('adminPanel.company.dialog.submitButton')}
        </SubmitButton>
      </div>
    </form>
  );
};
