export const keyPrefix = 'nano';

class SessionStorage {
  public setItem(key: string, value: unknown): void {
    sessionStorage.setItem(composeKeyName(key), JSON.stringify(value));
  }

  getItem<T>(key: string): T | null;
  getItem<T>(key: string, defaultValue: T): T;
  getItem<T>(key: string, defaultValue?: T): T | null {
    const data: string | null = sessionStorage.getItem(composeKeyName(key));

    if (data !== null) {
      return JSON.parse(data);
    }

    if (defaultValue) {
      return defaultValue;
    }

    return null;
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(composeKeyName(key));
  }
}

export const composeKeyName = (key: string) => `${keyPrefix}_${key}`;

export const sessionStorageService = new SessionStorage();
