import { AppState } from 'redux/store/store';

export const getUser = ({ user }: AppState) => user;
export const getAssignRole = ({ roleAssign }: AppState) => roleAssign;

export const getIsUserLoading = (state: AppState) => getUser(state).isLoading;

export const getUserToDeleteId = (state: AppState) => getUser(state).userToDeleteId;

export const getUserId = (state: AppState) => getUser(state).id;
