import { Collapse } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Checkbox, Paragraph, Button, Tag } from 'components/common';

import { setPersonas } from 'modules/segment/segmentSlice';
import { Persona } from 'modules/segment/types';

import { usePersonas } from './usePersonas';

const { Panel } = Collapse;

type PersonaType = {
  id: string;
  name: string;
  children: Persona[];
};

type TreeNodeProps = {
  persona: PersonaType;
  expanded: boolean;
  onExpand: (id: string) => void;
  personas: PersonaType[];
};

export const TreeNodePersonas = ({ persona, expanded, onExpand, personas }: TreeNodeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selected } = usePersonas();

  const [selectedItems, setSelectedItems] = useState<string[]>(selected);

  const handleCheckboxChange = (id: string) => {
    // Toggle selection of the checkbox
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      dispatch(setPersonas(selectedItems.filter((item) => item !== id)));
      // Remove item if already selected
    } else {
      setSelectedItems([...selectedItems, id]); // Add item to selected items
      dispatch(setPersonas([...selectedItems, id]));
    }
  };

  const filteredNamesByParent = useMemo(() => {
    const namesByParent: Record<string, string[]> = {};

    personas.forEach((persona) => {
      persona.children.forEach((child) => {
        if (selectedItems.includes(child.id)) {
          const parentName = persona.name;
          const childName = child.name;

          if (!namesByParent[parentName]) {
            namesByParent[parentName] = [];
          }

          namesByParent[parentName].push(childName);
        }
      });
    });

    return namesByParent;
  }, [personas, selectedItems]);

  const handleRemoveAllPersonas = useCallback(() => {
    setSelectedItems([]);
    dispatch(setPersonas([]));
  }, [dispatch]);

  const handleRemoveOnePersonas = useCallback(
    (personaNamesToRemove: string[]) => {
      const updatedSelected = selectedItems.filter((personaId) => {
        const matchingPersonas = personas
          .flatMap((parentPersona) => parentPersona.children)
          .filter((childPersona) => personaNamesToRemove.includes(childPersona.name));

        return !matchingPersonas.some((persona) => persona.id === personaId);
      });

      setSelectedItems(updatedSelected);
      dispatch(setPersonas(updatedSelected));
    },
    [selectedItems, dispatch, personas],
  );

  return (
    <div className="flex justify-between">
      <div className="w-1/2 pr-2">
        <Collapse
          activeKey={expanded ? persona.id : undefined}
          onChange={() => onExpand(persona.id)}
        >
          <Panel header={persona.name} key={persona.id}>
            <div className="pl-6 overflow-y-auto h-[280px]">
              {persona.children.map((child: Persona) => (
                <div key={child.id} className="flex items-center mt-2">
                  <Checkbox
                    id={child.id}
                    checked={selectedItems.includes(child.id)}
                    onChange={() => handleCheckboxChange(child.id)}
                    className="mr-2"
                  />
                  <label htmlFor={child.id}>{child.name}</label>
                </div>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="w-1/2 pl-2">
        <div className="mb-4 relative flex flex-col h-[360px] gap-y-2 self-start overflow-hidden rounded-xl bg-text-5 px-6 pt-4 item-start">
          <div className="flex max-h-[32px] items-center justify-between">
            <Paragraph large className="m-0 font-medium text-primary-dark-purple-100">
              {t('adminPanel.user.yourSelection')}
            </Paragraph>
            {selectedItems.length > 0 && (
              <Button size="small" onClick={handleRemoveAllPersonas} variant="text" type="button">
                {t('adminPanel.common.clearAll')}
              </Button>
            )}
          </div>
          <div className="flex flex-1 flex-wrap gap-7 overflow-auto pb-9 text-left">
            {selectedItems.length === 0 && (
              <span className="text-text-60">{t('segment.intent.emptySelection')}</span>
            )}
            {Object.entries(filteredNamesByParent).map(([parentName, childNames]) => {
              return parentName ? (
                <div key={parentName} className="mb-2 mt-4">
                  <Tag
                    closable
                    onClose={() => handleRemoveOnePersonas(childNames)}
                    className="text-2xl font-bold"
                    variant="green"
                  >
                    {parentName}
                  </Tag>
                  <div>
                    {childNames?.map((child) => (
                      <span key={child} className="block">
                        {child}
                      </span>
                    ))}
                  </div>
                </div>
              ) : null;
            })}
          </div>
          <span className="absolute bottom-0 right-0 rounded-xl bg-counterBg px-4 py-3 text-base-s text-text-80">
            {selectedItems.length || 0}
          </span>
        </div>
      </div>
    </div>
  );
};
