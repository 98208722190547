import { IconProps } from 'feather-icons-react';

export const MagicBulb = ({ className, size = 24 }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clipPath="url(#clip0_4943_9170)">
      <path
        d="M7.5 20H11.5C11.5 21.1 10.6 22 9.5 22C8.4 22 7.5 21.1 7.5 20ZM5.5 19H13.5V17H5.5V19ZM17 9.5C17 13.32 14.34 15.36 13.23 16H5.77C4.66 15.36 2 13.32 2 9.5C2 5.36 5.36 2 9.5 2C13.64 2 17 5.36 17 9.5ZM15 9.5C15 6.47 12.53 4 9.5 4C6.47 4 4 6.47 4 9.5C4 11.97 5.49 13.39 6.35 14H12.65C13.51 13.39 15 11.97 15 9.5ZM21.87 7.37L20.5 8L21.87 8.63L22.5 10L23.13 8.63L24.5 8L23.13 7.37L22.5 6L21.87 7.37ZM19.5 6L20.44 3.94L22.5 3L20.44 2.06L19.5 0L18.56 2.06L16.5 3L18.56 3.94L19.5 6Z"
        fill="#56365A"
      />
    </g>
    <defs>
      <clipPath id="clip0_4943_9170">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
