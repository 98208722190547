import classNames from 'classnames';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Text, Tooltip } from 'components/common';

import { formatNumber } from 'services/numberUtils';

import { ScaleImpactArrow } from './ScaleImpactArrow';
import { getScaleImpactData } from './scaleImpactSlice';
import { useLeftScaleCutout } from './useLeftScaleCutout';
import { useRightScaleCutout } from './useRightScaleCutout';

const CONTAINER_WIDTH = 600;
const RIGHT_MARGIN = 300;
const MIN_BAR_WIDTH = 50;

export const ScaleImpact = ({ expanded }: { expanded: boolean }) => {
  const leftParent = useRef<HTMLDivElement | null>(null);
  const rightParent = useRef<HTMLDivElement | null>(null);
  const { coords, refs: leftRefs, arrowCoords } = useLeftScaleCutout(leftParent);
  const { coords: rightCoords, refs: rightRefs, stepDecrement } = useRightScaleCutout(leftParent);
  const scaleImpactData = useSelector(getScaleImpactData);

  const style = { clipPath: `polygon(${coords.join(', ')})` };

  const rightStyle = { clipPath: `polygon(${rightCoords.join(', ')})` };

  const [x, y] = arrowCoords;

  return (
    <div className={classNames('relative', { 'overflow-hidden': !expanded })}>
      <div className="flex flex-col gap-3 z-10 relative">
        {scaleImpactData.map((i, index) => (
          <div key={i.value} className="flex group">
            <div
              className="mr-[10px] w-[110px] flex justify-end"
              ref={(el) => (leftRefs.current[index] = el)}
            >
              <div className="group-hover:bg-text-5 px-2 py-1 w-[60px] rounded-md flex items-end flex-col group-hover:transition-colors group-hover:duration-300 mr-1 mt-1">
                <Text className="font-semibold lowercase leading-[15px] text-text-100">
                  {formatNumber(i.traffic)}
                </Text>
                <Text className="font-normal text-text-60 leading-[15px]">{`${Math.ceil(
                  Number(i.ratio) * 100,
                )}%`}</Text>
              </div>
            </div>
            <div className="grow">
              <div className="flex justify-between items-center">
                <div ref={(el) => (rightRefs.current[index] = el)}>
                  <Bar
                    barWidth={Math.max(
                      CONTAINER_WIDTH - RIGHT_MARGIN - index * stepDecrement,
                      MIN_BAR_WIDTH,
                    )}
                  />
                </div>
                <div className="border-dashed border-[1px] border-secondary-white grow group-hover:border-solid group-hover:transition-colors group-hover:duration-300"></div>
                <Tooltip title={`${i.type}: ${i.value}`} placement="right">
                  <span className="w-[200px] overflow-hidden truncate ml-2 group-hover:bg-text-5 px-4 py-1 rounded-md group-hover:shadow-scale-impact text-secondary-green group-hover:transition-colors group-hover:duration-300 text-ellipsis">
                    {i.type}: {i.value}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
        <div className="absolute pointer-events-none" style={{ left: x, top: y }}>
          <ScaleImpactArrow />
        </div>
      </div>

      <div
        className="bg-scale-impact-back-gradient absolute top-0 left-0 w-[110px] h-full"
        style={style}
        ref={leftParent}
      ></div>
      <div
        className="bg-scale-impact-back-gradient absolute top-0  w-full h-full"
        style={rightStyle}
        ref={rightParent}
      ></div>
    </div>
  );
};

const Bar = ({ barWidth }: { barWidth: number }) => (
  <div
    className="rounded-r-md h-[40px] bg-secondary-barColor hover:bg-scale-impact-hover-gradient"
    style={{
      width: `${barWidth}px`,
    }}
  />
);
