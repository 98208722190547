import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

export type TotalReachEstimationState = {
  totalReach: number;
  isLoading: boolean;
};

const initialState: TotalReachEstimationState = {
  totalReach: 0,
  isLoading: true,
};

export const totalReachEstimationSlice = createSlice({
  name: 'totalReachEstimation',
  initialState,
  reducers: {
    setTotalReachEstimationLoading: (state: TotalReachEstimationState) => {
      state.isLoading = true;
    },
    getTotalReachEstimationSuccess: (
      state: TotalReachEstimationState,
      { payload }: PayloadAction<Record<'total', number>>,
    ) => {
      state.totalReach = payload.total;
      state.isLoading = false;
    },
    getTotalReachEstimationFailure: (state: TotalReachEstimationState) => {
      state.isLoading = false;
    },
    getTotalReachEstimation: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: TotalReachEstimationState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ navPath: string }>,
    ) => {},
    resetTotalReachData: (state: TotalReachEstimationState) => {
      state.totalReach = 0;
      state.isLoading = false;
    },
  },
});

export const getTotalReachEstimationSelector = (state: AppState) => state.totalReachEstimation;

export const {
  setTotalReachEstimationLoading,
  getTotalReachEstimationSuccess,
  getTotalReachEstimationFailure,
  getTotalReachEstimation,
  resetTotalReachData,
} = totalReachEstimationSlice.actions;

export const totalReachEstimation = totalReachEstimationSlice.reducer;
