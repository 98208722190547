import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  toggleNanoBotVisibility,
  sendMessage,
  sendMessageHistoryUpdate,
} from 'modules/nanoBot/nanoBotSlice';

export const useNanoBotActions = () => {
  const dispatch = useDispatch();
  const [userMessage, setUserMessage] = useState<string>('');

  const handleSendMessage = useCallback(
    (userMessage: string) => {
      if (userMessage.length > 0) {
        dispatch(sendMessage(userMessage));
        dispatch(sendMessageHistoryUpdate(userMessage));
      }
    },
    [dispatch],
  );

  const handleMessageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setUserMessage(event.target.value);
  }, []);

  const handleNanoBotOpen = useCallback((): void => {
    dispatch(toggleNanoBotVisibility());
  }, [dispatch]);

  const handleMessageSubmit = (event: FormEvent<HTMLFormElement | HTMLElement>): void => {
    event.preventDefault();
    handleSendMessage(userMessage);
    setUserMessage('');
  };

  return {
    userMessage,
    handleMessageChange,
    handleMessageSubmit,
    handleNanoBotOpen,
  };
};
