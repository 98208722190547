import { IconProps } from 'feather-icons-react';

export const CompassIcon = ({ className, fill = '#56365A', size = 20 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49992 9.99992C2.49992 5.85778 5.85778 2.49992 9.99992 2.49992C14.1421 2.49992 17.4999 5.85778 17.4999 9.99992C17.4999 14.1421 14.1421 17.4999 9.99992 17.4999C5.85778 17.4999 2.49992 14.1421 2.49992 9.99992ZM9.99992 0.833252C4.93731 0.833252 0.833252 4.93731 0.833252 9.99992C0.833252 15.0625 4.93731 19.1666 9.99992 19.1666C15.0625 19.1666 19.1666 15.0625 19.1666 9.99992C19.1666 4.93731 15.0625 0.833252 9.99992 0.833252ZM14.3236 6.73011C14.4235 6.43067 14.3455 6.10053 14.1223 5.87733C13.8991 5.65414 13.569 5.57621 13.2696 5.67602L7.96956 7.44269C7.72072 7.52563 7.52546 7.7209 7.44251 7.96973L5.67584 13.2697C5.57603 13.5692 5.65396 13.8993 5.87716 14.1225C6.10035 14.3457 6.43049 14.4236 6.72994 14.3238L12.0299 12.5572C12.2788 12.4742 12.474 12.279 12.557 12.0301L14.3236 6.73011ZM7.78403 12.2156L8.89189 8.89207L12.2155 7.78421L11.1076 11.1078L7.78403 12.2156Z"
      fill={fill}
    />
  </svg>
);
