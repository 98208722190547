import { ConfigProvider as AntdConfigProvider } from 'antd';
import React from 'react';

import { SplashScreen } from './components/SplashScreen';
import { DialogManager } from './components/dialog/DialogManager';
import { StoreProvider } from './redux/store/StoreProvider';
import { AppRouter } from './routing/AppRouter';

function App() {
  return (
    <StoreProvider>
      <AntdConfigProvider wave={{ disabled: true }}>
        <DialogManager />
        <SplashScreen>
          <AppRouter />
        </SplashScreen>
      </AntdConfigProvider>
    </StoreProvider>
  );
}

export default App;
