import { useSelector } from 'react-redux';

import { DialogWrapper } from './DialogWrapper';
import { getDialogsListIds } from './dialogSlice';

export const DialogManager = () => {
  const dialogIds = useSelector(getDialogsListIds);

  const dialogs = dialogIds.map((id) => <DialogWrapper id={id} key={id} />);

  return <>{dialogs}</>;
};
