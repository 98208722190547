import { useTranslation } from 'react-i18next';

import { Card, CardContent, Text, Title } from 'components/common';

type DistanceCheckerGeneralInfoProps = {
  language: string | null;
  status: string | null;
  contentValidDays: number | null;
  accuracy: number | null;
};

export const DistanceCheckerGeneralInfo = ({
  language,
  accuracy,
  contentValidDays,
  status,
}: DistanceCheckerGeneralInfoProps) => {
  const { t } = useTranslation();

  if (!language && !accuracy && !contentValidDays && !status) {
    return null;
  }

  return (
    <div className="w-full gap-6 h-[140px] grid grid-cols-4">
      <Card className=" rounded-[20px]">
        <Title level={4}>{t('adminPanel.distanceChecker.generalInfo.language')}</Title>
        <CardContent className="flex justify-center items-center">
          <Text className="uppercase text-heading-m font-extralight">{language}</Text>
        </CardContent>
      </Card>

      <Card className=" rounded-[20px]">
        <Title level={4}>{t('adminPanel.distanceChecker.generalInfo.status')}</Title>
        <CardContent className="flex justify-center items-center">
          <Text className="capitalize text-heading-m font-extralight">{status}</Text>
        </CardContent>
      </Card>

      <Card className=" rounded-[20px]">
        <Title level={4}>{t('adminPanel.distanceChecker.generalInfo.validDaysOfContent')}</Title>
        <CardContent className="flex justify-center items-center">
          <Text className="uppercase text-heading-m font-extralight">{contentValidDays}</Text>
        </CardContent>
      </Card>

      <Card className=" rounded-[20px]">
        <Title level={4}>{t('adminPanel.distanceChecker.generalInfo.accuracy')}</Title>
        <CardContent className="flex justify-center items-center">
          <Text className="uppercase text-heading-m font-extralight">{accuracy?.toFixed(2)}</Text>
        </CardContent>
      </Card>
    </div>
  );
};
