import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { useAuth } from '../auth/useAuth';
import { useNanoTour } from './useNanoTour';

type NanoTourContext = {
  isOpen: boolean;
  startTour: () => void;
  endTour: () => void;
  navigateToOnboarding: () => void;
};

const NanoTourContext = createContext<NanoTourContext | undefined>(undefined);

const ONBOARDING_QUERY_PARAM = 'showOnboarding';

export const NanoTourProvider = ({ children }: { children: ReactNode }) => {
  const { hasVisitedTour, setVisited } = useNanoTour();

  const navigate = useNavigate();

  const [search, setSearchParams] = useSearchParams();
  const showOnboarding = Boolean(search.get(ONBOARDING_QUERY_PARAM));

  useEffect(() => {
    if (showOnboarding) {
      startTour();
    }
  }, [showOnboarding]);

  const [isOpen, setValue] = useState(() => !hasVisitedTour());

  const startTour = () => {
    setValue(true);
  };

  const navigateToOnboarding = () => {
    navigate({
      pathname: '/',
      search: createSearchParams({
        [ONBOARDING_QUERY_PARAM]: 'true',
      }).toString(),
    });
  };

  const endTour = () => {
    setValue(false);
    setVisited();
    setSearchParams({});
  };

  return (
    <NanoTourContext.Provider value={{ isOpen, startTour, endTour, navigateToOnboarding }}>
      {children}
    </NanoTourContext.Provider>
  );
};

export const NanoTourWrapper = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <NanoTourProvider>{children}</NanoTourProvider> : <>{children}</>;
};

export const useNanoTourContext = () => {
  const context = useContext(NanoTourContext);

  if (!context) {
    throw new Error('useNanoTourContext is used outside of provider');
  }

  return context;
};
