import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';

import { EXCLUSION_LIST_URL } from '../constants';
import { ExclusionListListItem } from '../exclusionListApiTypes';

export const exclusionListApiSlice = nanoAdminPanelApi.injectEndpoints({
  endpoints: (build) => ({
    exclusionList: build.query<ExclusionListListItem[], null>({
      query: () => ({ method: 'GET', url: `${EXCLUSION_LIST_URL}/all` }),
      providesTags: ['ExclusionList'],
    }),
  }),
});

export const { useExclusionListQuery } = exclusionListApiSlice;
