import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { companyApi } from './companyApi';
import { CompanyRequestData, CompanyResponseData } from './companyApiTypes';
import { getCompany, getCompanyToDeleteId } from './companySelectors';
import {
  deleteCompany,
  deleteCompanyFailure,
  deleteCompanySuccess,
  saveCompany,
  saveCompanyFailure,
  saveCompanySuccess,
  updateCompany,
  updateCompanyFailure,
  updateCompanySuccess,
} from './companySlice';
import { companyListApiSlice } from './list/companyListApiSlice';
import { CompanyState } from './types';

function* saveCompanySaga() {
  try {
    const company: CompanyState = yield select(getCompany);
    const companyData = {
      name: company.name,
      address: company.address,
    };
    const response: CompanyResponseData = yield call(companyApi.saveCompany, companyData);

    yield put(saveCompanySuccess(response));
    yield put(companyListApiSlice.util.invalidateTags(['Company']));
  } catch (e) {
    notifyError(e);

    yield put(
      saveCompanyFailure({
        message: i18next.t('adminPanel.company.errors.companySaveFailed'),
      }),
    );
  }
}

function* deleteCompanySaga() {
  try {
    const companyToDeleteId: string = yield select(getCompanyToDeleteId);

    yield call(companyApi.deleteCompany, companyToDeleteId);

    yield put(deleteCompanySuccess());
    yield put(companyListApiSlice.util.invalidateTags(['Company']));
  } catch (e) {
    notifyError(e);

    yield put(
      deleteCompanyFailure({
        message: i18next.t('adminPanel.company.errors.companyDeleteFailed'),
      }),
    );
  }
}

function* updateCompanySaga() {
  try {
    const company: CompanyState = yield select(getCompany);

    const data: CompanyRequestData = {
      name: company.name,
      address: company.address,
    };

    yield call(companyApi.updateCompany, company.id, data);

    yield put(companyListApiSlice.util.invalidateTags(['Company']));
    yield put(updateCompanySuccess());
  } catch (e) {
    notifyError(e);

    yield put(
      updateCompanyFailure({
        message: i18next.t('adminPanel.company.errors.companyUpdateFailed'),
      }),
    );
  }
}

export function* companySagas() {
  yield all([takeLatest(saveCompany.type, saveCompanySaga)]);
  yield all([takeLatest(deleteCompany.type, deleteCompanySaga)]);
  yield all([takeLatest(updateCompany.type, updateCompanySaga)]);
}
