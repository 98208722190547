import { useTranslation } from 'react-i18next';

import { Checkbox, Paragraph } from 'components/common';

export type Emotion = {
  key: string;
  icon: string;
};

export interface EmotionNodeProps {
  emotion: Emotion;
}

export const EmotionNode = ({ emotion }: EmotionNodeProps) => {
  const { t } = useTranslation();

  return (
    <div className="h-[72px] w-[200px] grow rounded-lg bg-primary-dark-purple-5 p-4">
      <Checkbox value={emotion.key}>
        <div className="flex items-end">
          <img alt={emotion.key} className="mr-2 w-[32px]" src={emotion.icon} />
          <Paragraph className="text-base-l">{t(`segment.emotions.${emotion.key}`)}</Paragraph>
        </div>
      </Checkbox>
    </div>
  );
};
