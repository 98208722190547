import { IconProps } from 'feather-icons-react';

export const DocIcon = ({ className, fill = 'currentColor', size = 48 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 18.75V28.5H31.5V18.75H28.5C27.9033 18.75 27.331 18.5129 26.909 18.091C26.4871 17.669 26.25 17.0967 26.25 16.5V13.5H18C17.6022 13.5 17.2206 13.658 16.9393 13.9393C16.658 14.2206 16.5 14.6022 16.5 15V28.5H15V15C15 14.2044 15.3161 13.4413 15.8787 12.8787C16.4413 12.3161 17.2044 12 18 12H26.25L33 18.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5505 33.2633V32.4938C26.5505 31.9283 26.4485 31.4423 26.2445 31.0358C26.0375 30.6248 25.7435 30.3098 25.3595 30.0908C24.9785 29.8703 24.521 29.7608 23.9855 29.7608C23.4515 29.7608 22.991 29.8703 22.6055 30.0908C22.22 30.3068 21.926 30.6203 21.7205 31.0313C21.5165 31.4378 21.413 31.9253 21.413 32.4938V33.2633C21.4001 33.7671 21.5054 34.267 21.7205 34.7228C21.9141 35.1178 22.2219 35.4454 22.604 35.6633C23.0284 35.8883 23.5038 35.9999 23.984 35.9873C24.5195 35.9873 24.9785 35.8793 25.3595 35.6633C25.7412 35.4449 26.0489 35.1174 26.243 34.7228C26.4485 34.3133 26.5505 33.8258 26.5505 33.2633ZM25.346 32.4848V33.2678C25.3565 33.5976 25.2967 33.9259 25.1705 34.2308C25.0707 34.4675 24.9025 34.6691 24.6875 34.8098C24.4755 34.9378 24.2316 35.0033 23.984 34.9988C23.7354 35.0034 23.4906 34.9379 23.2775 34.8098C23.0638 34.6678 22.8968 34.4659 22.7975 34.2293C22.682 33.9728 22.622 33.6518 22.622 33.2678V32.4848C22.6112 32.1554 22.6711 31.8276 22.7975 31.5233C22.8971 31.2873 23.064 31.0859 23.2775 30.9443C23.4895 30.813 23.7347 30.7453 23.984 30.7493C24.2322 30.7449 24.4764 30.8121 24.6875 30.9428C24.9027 31.0839 25.0709 31.286 25.1705 31.5233C25.2969 31.8276 25.3567 32.1554 25.346 32.4848Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1045 29.8748V35.8733H18.293C18.896 35.8733 19.3955 35.7548 19.7915 35.5178C20.189 35.2793 20.4875 34.9358 20.684 34.4843C20.8805 34.0343 20.978 33.4913 20.978 32.8583C20.978 32.2298 20.879 31.6913 20.684 31.2473C20.4905 30.8003 20.1965 30.4613 19.8005 30.2273C19.4045 29.9918 18.902 29.8748 18.293 29.8748H16.1045ZM17.2895 30.8423H18.1325C18.506 30.8423 18.8105 30.9173 19.0475 31.0703C19.295 31.2322 19.4818 31.4717 19.5785 31.7513C19.6955 32.0528 19.7555 32.4308 19.7555 32.8808C19.7601 33.18 19.7259 33.4785 19.6535 33.7688C19.589 34.0208 19.4915 34.2308 19.3595 34.4018C19.229 34.57 19.0561 34.7005 18.8585 34.7798C18.653 34.8608 18.4115 34.9028 18.134 34.9028H17.2895V30.8423Z"
      fill={fill}
    />
    <path
      d="M28.424 31.5668C28.3205 31.8263 28.2695 32.1443 28.2695 32.5163V33.2588C28.2614 33.5792 28.3138 33.8983 28.424 34.1993C28.5102 34.4364 28.666 34.6419 28.871 34.7888C29.085 34.9245 29.3347 34.9929 29.588 34.9853C29.7961 34.9897 30.0023 34.9444 30.1895 34.8533C30.3568 34.7682 30.4983 34.6397 30.599 34.4813C30.7008 34.3162 30.761 34.1288 30.7745 33.9353H31.922V34.0493C31.9075 34.4117 31.7899 34.7624 31.583 35.0603C31.3775 35.3513 31.1015 35.5763 30.7565 35.7413C30.4115 35.9063 30.0185 35.9873 29.579 35.9873C29.0405 35.9873 28.583 35.8793 28.208 35.6633C27.833 35.4428 27.5465 35.1293 27.3515 34.7228C27.158 34.3148 27.0605 33.8273 27.0605 33.2588V32.5118C27.0605 31.9448 27.1595 31.4543 27.356 31.0448C27.5525 30.6338 27.8375 30.3173 28.2125 30.0953C28.589 29.8718 29.0465 29.7608 29.579 29.7608C29.888 29.758 30.1953 29.8071 30.488 29.9063C30.7573 29.9928 31.0071 30.1315 31.223 30.3143C31.4333 30.4937 31.6041 30.7149 31.7244 30.9639C31.8448 31.2128 31.912 31.484 31.922 31.7603V31.8698H30.7745C30.7607 31.6677 30.699 31.4718 30.5945 31.2983C30.4962 31.1333 30.3546 30.9984 30.185 30.9083C30.0125 30.8108 29.813 30.7628 29.588 30.7628C29.3348 30.7562 29.0858 30.8286 28.8755 30.9698C28.6698 31.1203 28.5128 31.3279 28.424 31.5668Z"
      fill={fill}
    />
  </svg>
);
