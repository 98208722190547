import { call, put } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { chartsApi } from '../chartsApi';
import { formRequestPayloadSaga } from '../sagas/formRequestPayloadSaga';
import { ChartsPayload } from '../types';
import { getScaleImpactSuccess, getScaleImpactFailure, getScaleImpact } from './scaleImpactSlice';
import { ScaleImpactResponse } from './types';

export function* scaleImpactSaga() {
  yield put(getScaleImpact());
  try {
    const requestPayload: ChartsPayload = yield call(formRequestPayloadSaga);
    const response: ScaleImpactResponse = yield call(chartsApi.getScaleImpact, requestPayload);

    yield put(getScaleImpactSuccess(response));
  } catch (error) {
    notifyError(error);
    yield put(getScaleImpactFailure());
  }
}
