import { sessionStorageService } from 'services/sessionStorage';

import { ResponseMessage } from './types';

const NANO_BOT_KEY = 'nanoBotMessagesHistory';

const saveToNanoBotStorage = (data: ResponseMessage[]) => {
  const currentData: ResponseMessage[] = sessionStorageService.getItem(NANO_BOT_KEY) || [];

  sessionStorageService.setItem(NANO_BOT_KEY, [...currentData, ...data]);
};

const getFromNanoBotStorage = (): ResponseMessage[] | [] => {
  return sessionStorageService.getItem(NANO_BOT_KEY) || [];
};

const clearNanoBotStorage = () => {
  sessionStorageService.removeItem(NANO_BOT_KEY);
};

export const nanoBotStorage = {
  saveToNanoBotStorage,
  getFromNanoBotStorage,
  clearNanoBotStorage,
};
