import { Skeleton } from 'antd';
import { Plus } from 'feather-icons-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, Input } from 'components/common';

import { notificationService } from 'modules/notifications';

import { ROUTES } from 'routing/constants';

import { CompanyFilteringSelect } from './CompanyFilteringSelect';
import { GroupBySelectorUsers } from './GroupBySelectorUsers';
import { GroupedUserTable } from './GroupedUserTable';
import { UsersTable } from './UsersTable';
import { useUsersGrouping } from './useUserListGrouping';
import { useUsersListQuery } from './usersListApiSlice';

export const UsersList = () => {
  const { t } = useTranslation();

  const { data: users, isLoading, error } = useUsersListQuery(null);
  const navigate = useNavigate();
  const {
    groupingMode,
    onGroupingModeChange,
    isGroupingByCompanyApplied,
    isCompanyFilteringApplied,
    isTableUngrouped,
    selectedCompany,
    setGroupByCompany,
    filterByGroupingMode,
  } = useUsersGrouping();

  useEffect(() => {
    if (error) {
      notificationService.showError({
        key: 'UsersListFailed',
        message: t('adminPanel.user.errors.userListFetchFailed'),
      });
    }
  }, [error, t]);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const filterList = useMemo(() => {
    return users
      ? users?.filter(
          (u) =>
            u.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            u.id.includes(searchQuery.toLowerCase()),
        )
      : users;
  }, [searchQuery, users]);

  const filteredByGroupingMode = useMemo(
    () => filterByGroupingMode(filterList || []),
    [filterByGroupingMode, filterList],
  );

  return (
    <div>
      <div className="mb-6 flex justify-between justify-center">
        <div className="flex gap-2 items-center">
          <div className="flex gap-2">
            <GroupBySelectorUsers selectedGrouping={groupingMode} onChange={onGroupingModeChange} />
            {isGroupingByCompanyApplied ? (
              <CompanyFilteringSelect
                onChange={setGroupByCompany}
                selectedValues={selectedCompany}
              />
            ) : null}
          </div>
        </div>
        <div className="flex gap-2">
          <div>
            <Input
              className="w-full max-w-[310px] bg-secondary-white"
              onChange={handleSearchChange}
              placeholder="Search"
            />
          </div>
          <div className="mx-2">
            <Button
              isLoading={isLoading}
              variant="primary"
              onClick={() => navigate(ROUTES.USER)}
              className="w-[176px]"
            >
              <span>{t('adminPanel.user.newUser')}</span> <Plus className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Skeleton active />
        ) : isTableUngrouped ? (
          <UsersTable dataSource={filterList!} />
        ) : isCompanyFilteringApplied ? (
          <UsersTable dataSource={filteredByGroupingMode!} />
        ) : (
          <GroupedUserTable
            dataSource={filterList!}
            groupingMode={groupingMode}
            setGroupByCompany={setGroupByCompany}
          />
        )}
      </div>
    </div>
  );
};
