import { Typography as TypographyAntd } from 'antd';
import { TextProps as TextAntdProps } from 'antd/lib/typography/Text';
import { TitleProps } from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { memo } from 'react';

import { nanoTwMerge } from 'services/twMerge';

interface TextProps extends TextAntdProps {
  small?: boolean;
  large?: boolean;
}

export const Text = memo(({ className, children, small, large, ...rest }: TextProps) => (
  <TypographyAntd.Text
    className={nanoTwMerge(
      classNames('nano-text m-0', {
        'text-base-s': small,
        'text-base-l': large,
      }),
      className,
    )}
    {...rest}
  >
    {children}
  </TypographyAntd.Text>
));
Text.displayName = 'Text';

export const Paragraph = memo(({ className, children, small, large, ...rest }: TextProps) => (
  <TypographyAntd.Paragraph
    className={nanoTwMerge(
      classNames('nano-text m-0 mb-1', {
        'text-base-s': small,
        'text-base-l': large,
      }),
      className,
    )}
    {...rest}
  >
    {children}
  </TypographyAntd.Paragraph>
));
Paragraph.displayName = 'Paragraph';

export const Title = memo(({ className, children, level, ...rest }: TitleProps) => (
  <TypographyAntd.Title
    className={nanoTwMerge(
      classNames('nano-text m-0', {
        'mb-8 text-title font-bold': !level || level === 1,
        'mb-6 text-heading-l font-semibold': level === 2,
        'mb-6 text-heading-m font-semibold': level === 3,
        'mb-6 text-heading-s font-semibold': level === 4,
      }),
      className,
    )}
    level={level}
    {...rest}
  >
    {children}
  </TypographyAntd.Title>
));
Title.displayName = 'Title';
