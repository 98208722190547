import classNames from 'classnames';
import { ReactNode } from 'react';

import { nanoTwMerge } from 'services/twMerge';

import { BugsnagErrorBoundary, ErrorPlaceholder } from '../ErrorBoundary';
import { Sidebar } from '../Sidebar';

export type PageLayoutProps = {
  className?: string;
  contentClassName?: string;
  displaySidebar?: boolean;
  zeroContentPadding?: boolean;
  children: ReactNode;
};
export const PageLayout = ({
  className,
  contentClassName,
  displaySidebar = true,
  zeroContentPadding,
  children,
}: PageLayoutProps) => (
  <section
    className={classNames('page-background flex min-h-screen w-full bg-page-default', className)}
  >
    {displaySidebar ? <Sidebar /> : null}
    <section className="flex flex-1 justify-center">
      <div
        className={nanoTwMerge(
          classNames('flex-1', { 'max-w-[1320px] p-10': !zeroContentPadding }),
          contentClassName,
        )}
      >
        <BugsnagErrorBoundary FallbackComponent={ErrorPlaceholder}>{children}</BugsnagErrorBoundary>
      </div>
    </section>
  </section>
);
