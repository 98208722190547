import { Tag as TagAntd, TagProps as TagAntdProps } from 'antd';
import classNames from 'classnames';
import { X } from 'feather-icons-react';

export type TagProps = {
  variant: 'green' | 'red' | string;
  className?: string;
} & TagAntdProps;

export const Tag = ({ variant, children, className, ...rest }: TagProps) => (
  <TagAntd
    closeIcon={<X size={16} />}
    bordered={false}
    className={classNames('nano-tag', variant, className)}
    {...rest}
  >
    <span className="overflow-hidden overflow-ellipsis">{children}</span>
  </TagAntd>
);
