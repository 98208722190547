import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLanguagesQuery } from 'modules/apiData/apiDataApiSlice';
import { DefaultLanguagesIsoCodes } from 'modules/segment/constants';
import { getBucketLanguages } from 'modules/segment/segmentSelectors';
import { setLanguages } from 'modules/segment/segmentSlice';

export const useLanguages = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const { isLoading, data: languages } = useLanguagesQuery(null);
  const { languagesValues } = useSelector(getBucketLanguages);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(languagesValues);

  const languagesList = useMemo(() => Object.values(languages ?? {}), [languages]);

  const defaultLanguages = useMemo(
    () => languagesList.filter(({ iso }) => DefaultLanguagesIsoCodes.includes(iso)),
    [languagesList],
  );

  const regularLanguages = useMemo(
    () => languagesList.filter(({ iso }) => !DefaultLanguagesIsoCodes.includes(iso)),
    [languagesList],
  );

  const allLanguageCodes = useMemo(
    () => [...defaultLanguages.map(({ iso }) => iso), ...regularLanguages.map(({ iso }) => iso)],
    [defaultLanguages, regularLanguages],
  );

  const filteredLanguages = useMemo(() => {
    return !searchQuery
      ? regularLanguages
      : regularLanguages.filter(({ language }) =>
          language.toLowerCase().includes(searchQuery.toLowerCase()),
        );
  }, [searchQuery, regularLanguages]);

  const handleLanguageChange = useCallback(
    (iso: string) => {
      if (selectedLanguages.includes(iso)) {
        setSelectedLanguages(selectedLanguages.filter((languageIso) => languageIso !== iso));
        dispatch(setLanguages(selectedLanguages.filter((languageIso) => languageIso !== iso)));
      } else {
        setSelectedLanguages([...selectedLanguages, iso]);
        dispatch(setLanguages([...selectedLanguages, iso]));
      }
    },
    [dispatch, selectedLanguages],
  );

  const handleSelectAllChecked = useCallback(
    (checked: boolean) => {
      setSearchQuery('');
      setSelectedLanguages(checked ? allLanguageCodes : []);
      dispatch(setLanguages(checked ? allLanguageCodes : []));
    },
    [allLanguageCodes, dispatch],
  );

  // useEffect(() => {
  //   if (languagesList.length && !isTouched) {
  //     handleSelectAllChecked(false);
  //   }
  // }, [languagesList.length, isTouched, handleSelectAllChecked]);

  return {
    handleSelectAllChecked,
    handleLanguageChange,
    searchQuery,
    setSearchQuery,
    isLoading,
    filteredLanguages,
    languagesValues,
    defaultLanguages,
  };
};
