import { CheckboxTreeContainer } from './CheckboxTreeContainer';
import { SelectedCategoriesIds } from './SelectedCategoriesIds';

export const Categories = () => {
  return (
    <div className="flex h-full gap-x-9">
      <CheckboxTreeContainer />
      <SelectedCategoriesIds />
    </div>
  );
};
