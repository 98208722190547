import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Field, SubmitButton, TextArea } from 'components/common';

import { getExclusionList } from '../../exclusionListSelectors';
import { saveExclusionList } from '../../exclusionListSlice';

export type ExclusionListFormProps = {
  onClose: () => void;
};

export const ExclusionListForm = ({ onClose }: ExclusionListFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(getExclusionList);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { urlsField: '' } });

  const onSubmit = handleSubmit((data) => {
    const urlsFromForm = data.urlsField
      .split('\n')
      .filter(Boolean)
      .map((i) => i.trim());

    dispatch(saveExclusionList(urlsFromForm));
    onClose();
  });

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const validateTextAreaForUrls = (data: string) => {
    const urlsFromForm = data
      .split('\n')
      .filter(Boolean)
      .map((i) => i.trim());

    for (const [index, urlLine] of urlsFromForm.entries()) {
      if (!isValidUrl(urlLine)) {
        return `Line ${index + 1} is not valid URL!`;
      }
    }

    return true;
  };

  return (
    <form className="flex flex-col gap-y-9" onSubmit={onSubmit}>
      <Field
        name="urlsField"
        control={control}
        render={({ field, fieldState: { invalid } }) => (
          <TextArea
            className="mb-4 h-[164px] w-full"
            {...field}
            placeholder={t('adminPanel.exclusionList.dialog.placeholder')}
            invalid={invalid}
          />
        )}
        rules={{
          required: t('adminPanel.exclusionList.validators.required'),
          validate: validateTextAreaForUrls,
        }}
        error={errors.urlsField?.message}
      />
      <div className="mt-2 flex items-center justify-end gap-x-4">
        <Button type="button" variant="outline" onClick={onClose}>
          {t('adminPanel.exclusionList.dialog.cancelButton')}
        </Button>
        <SubmitButton isLoading={isLoading}>
          {t('adminPanel.exclusionList.dialog.submitButton')}
        </SubmitButton>
      </div>
    </form>
  );
};
