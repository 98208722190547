import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  sendMessageSuccess,
  sendMessageFailure,
  sendMessage,
  getNanoBotMessagesHistory,
} from 'modules/nanoBot/nanoBotSlice';

import { notifyError } from 'services/logService';

import { nanoBotApi } from './nanoBotApi';
import { MessageAction, ResponseMessage } from './types';

function* sendMessageSaga(action: MessageAction) {
  const nanoBotStateMessageHistory: Array<ResponseMessage> =
    yield select(getNanoBotMessagesHistory);

  try {
    const messageData: Array<ResponseMessage> = yield call(nanoBotApi.sendMessage, {
      userMessages: nanoBotStateMessageHistory,
      message: action.payload,
    });

    yield put(sendMessageSuccess(messageData));
  } catch (error) {
    yield put(sendMessageFailure());
    notifyError(error);
  }
}

export function* nanoBotSagas() {
  yield all([takeLatest(sendMessage.type, sendMessageSaga)]);
}
