import { IconProps } from 'feather-icons-react';

export const MinusIcon = ({ className, fill = '#56365A', size = 20 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33325 9.16663H16.6666V10.8333H3.33325V9.16663Z"
      fill={fill}
    />
  </svg>
);
