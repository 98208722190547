import { format, parseISO } from 'date-fns';
import * as echarts from 'echarts';
import { TFunction } from 'i18next';

import { IntervalFilter, IntervalFilters } from 'modules/segment/types';

import { formatNumber } from 'services/numberUtils';

import { TransformedIntentSeasonalityData } from '../types';

export const getIntentSeasonalityDailyOptions = (data: TransformedIntentSeasonalityData) => {
  return {
    title: {
      show: false,
    },
    legend: {
      data: ['Impression / daily'],
      orient: 'horizontal',
      bottom: 0,
      textStyle: {
        color: '#AB9BAD',
      },
    },
    tooltip: {
      type: 'line',
      trigger: 'axis',
      formatter: (params: { dataIndex: number; value: number }[]) => {
        const dataPoint = data[params[0].dataIndex];
        const value = `<b>${formatNumber(params[0].value)}</b>`;
        const formattedDate = format(parseISO(dataPoint.date), 'MMM dd, HH:mm');

        return `${formattedDate} ${value}`;
      },
    },
    xAxis: {
      type: 'category',
      axisPointer: {
        lineStyle: {
          type: 'line',
          color: '#41fbfb',
          width: 1,
        },
        label: {
          show: false,
        },
        z: 1,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#AB9BAD',
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: '#AB9BAD',
        },
      },
      boundaryGap: false,
      data: data.map(({ date }) => format(new Date(date), 'd')),
    },
    yAxis: {
      type: 'value',
      axisTick: {
        inside: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
      axisLabel: {
        inside: false,
        formatter: formatNumber,
        color: '#AB9BAD',
      },
    },
    grid: {
      top: 10,
      left: 50,
      right: 15,
    },
    series: [
      {
        name: 'Impression / daily',
        type: 'line',
        showSymbol: false,
        smooth: true,
        symbol: 'circle',
        symbolSize: 10,
        sampling: 'average',
        itemStyle: {
          color: '#41fbfb',
          borderColor: 'white',
          borderWidth: 2,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          shadowOffsetX: 0,
          shadowOffsetY: 2,
        },
        stack: 'a',
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(121, 227, 241, 0.9)',
            },
            {
              offset: 1,
              color: 'rgba(209, 190, 228, 0.5)',
            },
          ]),
          emphasis: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(121, 227, 241, 0.9)',
              },
              {
                offset: 1,
                color: 'rgba(209, 190, 228, 0.5)',
              },
            ]),
          },
        },
        data: data.map(({ value }) => value),
        z: 2,
      },
    ],
  };
};

export const transformData = (data: Record<string, number>) => {
  return Object.entries(data)
    .map(([date, value]) => ({
      date,
      value,
    }))
    .reverse();
};

export const getIntentSeasonalityMonthlyOptions = (data: TransformedIntentSeasonalityData) => {
  return {
    xAxis: {
      type: 'category',
      axisPointer: {
        show: true,
        lineStyle: {
          type: 'none',
          color: 'rgba(0, 0, 0, 0.1)',
          width: 1,
        },
        label: {
          show: false,
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#AB9BAD',
      },
      axisLine: {
        lineStyle: {
          color: '#AB9BAD',
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
      boundaryGap: ['5%', '5%'],
      data: data.map(({ date }) => format(new Date(date), 'MMM')),
    },
    yAxis: {
      type: 'value',
      axisTick: {
        inside: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
      axisLabel: {
        inside: false,
        formatter: formatNumber,
        color: '#AB9BAD',
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      formatter: (
        params: {
          data: number;
          dataIndex: number;
        }[],
      ) => {
        const dataPoint = data[params[0].dataIndex];
        const month = format(new Date(dataPoint.date), 'MMM');
        const year = format(new Date(dataPoint.date), 'yy');
        const barValue = formatNumber(dataPoint.value);

        return `${month}, '${year} <b>${barValue}</b>`;
      },
    },
    legend: {
      data: ['Impression / monthly'],
      orient: 'horizontal',
      bottom: 0,
      textStyle: {
        color: '#AB9BAD',
      },
    },
    grid: {
      top: 10,
      left: 50,
      right: 15,
      height: 160,
    },
    series: [
      {
        name: 'Impression / monthly',
        data: data.map(({ value }) => value),
        type: 'line',
        lineStyle: {
          color: '#41fbfb',
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        sampling: 'average',
        itemStyle: {
          color: '#41fbfb',
          borderColor: 'white',
          borderWidth: 2,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          shadowOffsetX: 0,
          shadowOffsetY: 2,
        },
      },
      {
        data: data.map(({ value }) => value),
        type: 'bar',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(65, 251, 251, 0.5)',
              },
              {
                offset: 1,
                color: 'rgba(200, 140, 183, 0.5)',
              },
            ],
          },
        },
      },
    ],
  };
};

export const getIntentSeasonalityWeeklyOptions = (data: TransformedIntentSeasonalityData) => {
  return {
    xAxis: {
      type: 'category',
      axisPointer: {
        show: true,
        lineStyle: {
          type: 'none',
          color: 'rgba(0, 0, 0, 0.1)',
          width: 1,
        },
        label: {
          show: false,
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#AB9BAD',
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: '#AB9BAD',
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
      boundaryGap: ['5%', '5%'],
      data: data.map(({ date }) => format(new Date(date), 'MMM d')),
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      formatter: (
        params: {
          data: number;
          dataIndex: number;
        }[],
      ) => {
        const dataPoint = data[params[0].dataIndex];
        const barValue = formatNumber(dataPoint.value);
        const month = format(new Date(dataPoint.date), 'MMM d');

        return `${month} <b>${barValue}</b>`;
      },
    },
    legend: {
      data: ['Impression / weekly'],
      orient: 'horizontal',
      bottom: 0,
      textStyle: {
        color: '#AB9BAD',
      },
    },
    yAxis: {
      type: 'value',
      axisTick: {
        inside: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
      axisLabel: {
        inside: false,
        formatter: formatNumber,
        color: '#AB9BAD',
      },
    },
    grid: {
      top: 10,
      left: 50,
      right: 15,
      height: 160,
    },
    series: [
      {
        name: 'Impression / weekly',
        data: data.map(({ value }) => value),
        type: 'line',
        lineStyle: {
          color: '#41fbfb',
        },
        smooth: true,
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        sampling: 'average',
        itemStyle: {
          color: '#41fbfb',
          borderColor: 'white',
          borderWidth: 2,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          shadowOffsetX: 0,
          shadowOffsetY: 2,
        },
      },
      {
        data: data.map(({ value }) => value),
        type: 'bar',
        itemStyle: {
          borderRadius: [3, 3, 0, 0],
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(65, 251, 251, 0.5)',
              },
              {
                offset: 1,
                color: 'rgba(200, 140, 183, 0.5)',
              },
            ],
          },
        },
      },
    ],
  };
};

export const getChartOptions = (
  interval: IntervalFilter,
  intentSeasonalityData: TransformedIntentSeasonalityData,
) => {
  switch (interval) {
    case IntervalFilters.daily:
      return getIntentSeasonalityDailyOptions(intentSeasonalityData);
    case IntervalFilters.monthly:
      return getIntentSeasonalityMonthlyOptions(intentSeasonalityData);
    case IntervalFilters.weekly:
      return getIntentSeasonalityWeeklyOptions(intentSeasonalityData);
    default:
      return getIntentSeasonalityDailyOptions(intentSeasonalityData);
  }
};

export const getIntervalTranslations = (
  interval: IntervalFilter,
  t: TFunction<'translation', undefined>,
) => {
  switch (interval) {
    case IntervalFilters.daily:
      return t('segment.dateRangeSwitcher.daily');
    case IntervalFilters.monthly:
      return t('segment.dateRangeSwitcher.monthly');
    case IntervalFilters.weekly:
      return t('segment.dateRangeSwitcher.weekly');
    default:
      return t('segment.dateRangeSwitcher.daily');
  }
};
