import { MenuProps } from 'antd/lib/menu/menu';
import { Edit, MoreHorizontal, Trash2 } from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Spin, Text } from 'components/common';

type UserListActionsProps = {
  isLoading: boolean;
  displayLoader: boolean;
  onUserDelete: () => void;
  onUserEdit: () => void;
};
export const UserListActions = ({
  isLoading,
  onUserDelete,
  onUserEdit,
  displayLoader,
}: UserListActionsProps) => {
  const { t } = useTranslation();

  const getMenuItems = () => {
    const editItem = {
      key: 1,
      label: (
        <Button variant="text" onClick={onUserEdit}>
          <Edit size={18} />
          <Text className="font-normal ml-2">{t('adminPanel.user.table.actionOptions.edit')}</Text>
        </Button>
      ),
    };

    const deleteItem = {
      key: 2,
      label: (
        <Button disabled={isLoading} variant="text" onClick={onUserDelete}>
          <Trash2 size={18} />
          <Text className="font-normal ml-2">
            {t('adminPanel.user.table.actionOptions.delete')}
          </Text>
        </Button>
      ),
    };

    const items: MenuProps['items'] = [editItem, deleteItem];

    return items;
  };

  return (
    <div className="flex items-center justify-around">
      {isLoading && displayLoader && (
        <div className="flex w-[25px] items-center justify-center">
          <Spin size="small" />
        </div>
      )}
      <Dropdown menu={{ items: getMenuItems() }}>
        <Button variant="text" className="px-2">
          <MoreHorizontal size={22} />
        </Button>
      </Dropdown>
    </div>
  );
};
