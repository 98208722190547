import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { customCategoriesApi } from '../customCategoriesApi';
import { getCustomCategory } from '../customCategorySelectors';
import { saveCustomCategoryFailure, saveCustomCategorySuccess } from '../customCategorySlice';
import { customCategoriesListApiSlice } from '../list/customCategoriesListApiSlice';
import { CustomCategoryPayload, CustomCategoryResponse, CustomCategoryState } from '../types';

export function* customCategorySaveSaga() {
  try {
    const customCategory: CustomCategoryState = yield select(getCustomCategory);

    const customCategoryData: CustomCategoryPayload = {
      name: customCategory.name,
      keywordGroups: customCategory.keywordGroups,
      description: customCategory.description,
      parentId: customCategory.parentId,
    };

    const response: CustomCategoryResponse = yield call(
      customCategoriesApi.saveCustomCategory,
      customCategoryData,
    );

    yield put(saveCustomCategorySuccess(response));
    yield put(customCategoriesListApiSlice.util.invalidateTags(['CustomCategories']));
  } catch (error) {
    notifyError(error);
    yield put(
      saveCustomCategoryFailure({
        message: i18next.t('adminPanel.customCategories.errors.customCategorySaveFailed'),
      }),
    );
  }
}
