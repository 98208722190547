import { Dropdown as DropdownAntd } from 'antd';
import { DropdownProps, DropdownButtonProps } from 'antd/lib/dropdown';
import classNames from 'classnames';
import { memo } from 'react';

export const Dropdown = memo(({ className, children, ...rest }: DropdownProps) => (
  <DropdownAntd className={classNames(className)} {...rest}>
    {children}
  </DropdownAntd>
));
Dropdown.displayName = 'Dropdown';

export const DropdownButton = memo(({ className, children, ...rest }: DropdownButtonProps) => (
  <DropdownAntd.Button className={classNames(className)} {...rest}>
    {children}
  </DropdownAntd.Button>
));
DropdownButton.displayName = 'DropdownButton';
