import { Download as DownloadIcon } from 'feather-icons-react';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ExternalLink, Text } from 'components/common';
import { FileUpload } from 'components/common/fileUpload';

import { getTotalReachEstimation } from 'modules/charts/TotalReachEstimation/totalReachEstimationSlice';
import { notificationService } from 'modules/notifications';
import {
  XLSX_FILE_MIME_TYPE,
  KEYWORDS_LIMIT,
  KEYWORDS_FILE_TEMPLATE_URL,
} from 'modules/segment/segmentForms/constants';
import { getCurrentBucket } from 'modules/segment/segmentSelectors';

import { parseFile } from 'services/xlsxUtils';

import { useKeywords } from '../../useKeywords';
import { FileComponent } from './File';

export type FileDescription = { key: string; name: string; size: number; values: string[] };

export const UploadKeywords = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    keywords: { uploadedValues },
  } = useSelector(getCurrentBucket);
  const {
    addUploadedKeywords,
    removeUploadedKeywords,
    uploadedValuesCount,
    manualKeywordsCount,
    uploadedFiles,
    allKeywords,
  } = useKeywords();
  const allKeywordsSum = manualKeywordsCount + uploadedValuesCount;

  const sumKeywordFiles = useCallback(
    (files: FileDescription[]) => {
      const result: FileDescription[] = [];
      let keywordsTotal = allKeywordsSum;

      for (let i = 0; i < files.length; i++) {
        if (keywordsTotal + files[i].values.length > KEYWORDS_LIMIT) {
          notificationService.showError({
            message: t('segment.keywords.exceededLimit', {
              limit: KEYWORDS_LIMIT,
            }),
          });

          return result;
        }
        result.push(files[i]);
        keywordsTotal += files[i].values.length;
      }

      return result;
    },
    [t, allKeywordsSum],
  );

  const validateFilesByExtension = useCallback(
    (files: FileList, fileType: string) => {
      const filesArray = Array.from(files);
      const result: File[] = [];

      for (let i = 0; i < filesArray.length; i++) {
        if (files[i].type === fileType) {
          result.push(files[i]);
        } else {
          notificationService.showError({
            message: t('segment.keywords.unSupportedFileType', { fileName: files[i].name }),
          });
        }
      }

      return result;
    },
    [t],
  );

  const handleFileDelete = useCallback(
    (file: FileDescription) => {
      removeUploadedKeywords(`${file.name}-${file.size}`);
    },
    [removeUploadedKeywords],
  );

  const deduplicateFilesValues = (files: FileDescription[], allKeywords: string[]) => {
    let keywordsBuffer = allKeywords;

    return files.map((file) => {
      const values = [
        ...new Set(file.values.filter((keyword) => !keywordsBuffer.includes(keyword))),
      ];

      keywordsBuffer = [...new Set([...keywordsBuffer, ...values])];

      return { ...file, values };
    });
  };

  const handleFileUpload = useCallback(
    async (files: FileList | null) => {
      if (!files) return;
      const validatedFiles = validateFilesByExtension(files, XLSX_FILE_MIME_TYPE);
      const parsedFiles = await Promise.all(validatedFiles.map((file) => parseKeywordsFile(file)));
      const summedKeywordFiles = sumKeywordFiles(parsedFiles);
      const uniqueFiles = summedKeywordFiles.filter(
        (file) => !uploadedFiles.find(({ key }) => key === file.key),
      );
      const deduplicatedFiles = deduplicateFilesValues(uniqueFiles, allKeywords);

      if (deduplicatedFiles.length) {
        addUploadedKeywords(deduplicatedFiles);
        dispatch(getTotalReachEstimation({ navPath: '/manual' }));
      }

      return false;
    },
    [
      uploadedFiles,
      validateFilesByExtension,
      sumKeywordFiles,
      allKeywords,
      addUploadedKeywords,
      dispatch,
    ],
  );

  const parseKeywordsFile = async (file: File): Promise<FileDescription> => {
    const values = await parseFile(file);

    const { name, size } = file;

    const key = `${name}-${size}`;

    return { key, values, name, size };
  };

  return (
    <>
      <div className="relative flex flex-col rounded-lg bg-primary-light-purple-10 pb-6 pt-4 px-5">
        <div className="flex justify-between content-center items-center">
          <Text className="mb-3 text-base-l font-medium">
            {t('segment.keywords.uploadedKeywords')}
          </Text>
          <Button variant="outline" size="small">
            <ExternalLink
              href={KEYWORDS_FILE_TEMPLATE_URL}
              className="grow text-center text-base-s  no-underline flex content-center text-primary-dark-purple-100"
            >
              <span>{t('segment.keywords.xlsxTemplate')}</span>
              <DownloadIcon size="20" className="ml-2 inline" />
            </ExternalLink>
          </Button>
        </div>
        <FileUpload
          multiple
          className="mt-5 h-[116px]"
          accept={XLSX_FILE_MIME_TYPE}
          onChange={handleFileUpload}
        >
          <span className="text-primary-dark-purple-100">
            <Trans i18nKey="segment.keywords.uploadPlaceholder">
              <span className="underline font-medium">Upload</span> or drag and drop XLSX files
            </Trans>
          </span>
        </FileUpload>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        {uploadedValues.map((file) => (
          <FileComponent key={file.key} file={file} deleteFile={handleFileDelete} />
        ))}
      </div>
    </>
  );
};
