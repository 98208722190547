import i18next, { i18n, ResourceKey } from 'i18next';
import { useTranslation as i18nUseTranslation } from 'react-i18next';

const NAMESPACE = 'translation';

export const i18nFromLocale = (locale: ResourceKey) => {
  const LANGUAGE = 'en';
  const i18n = i18next.createInstance(
    {
      lng: LANGUAGE,
      resources: {
        [LANGUAGE]: {
          [NAMESPACE]: locale,
        },
      },
    },
    () => {},
  );

  return i18n;
};

export const useNanoTranslation = (i18n: i18n) => i18nUseTranslation(NAMESPACE, { i18n });
