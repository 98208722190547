import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCategoriesDataObject } from 'modules/segment/segmentSelectors';

import { EmptyResult } from './EmptyResult';
import { TreeNode } from './TreeNode';

export const CheckBoxTree = () => {
  const categories = useSelector(getCategoriesDataObject);
  const categoriesArray = useMemo(() => Object.values(categories), [categories]);
  const categoriesAreEmpty = !Object.values(categories).filter(({ displayed }) => displayed).length;

  return (
    <div className="flex flex-col gap-y-4">
      {categoriesArray.map(({ id, checked, parentId }) =>
        parentId === null ? <TreeNode key={id} id={id} checked={checked} /> : null,
      )}
      {categoriesAreEmpty && <EmptyResult />}
    </div>
  );
};
