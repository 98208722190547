export const tourTrans = {
  logoDesc:
    'Welcome to LIIFT™, Nano\'s cutting-edge Live Intent Identity-Free Targeting solution. Learn how to effortlessly plan, manage, and report on your advertiser\'s campaigns with this quick guide. For further exploration, click on the LIIFT logo to be redirected to Nano\'s website, where additional information can be found ',
  homeTitle: 'Home Page',
  homeDesc:
    'Here you can quickly access our UI’s main components, the Build, Manage and Report tabs. Now let\'s explore what you can do with our interface',
  buildTitle: 'Build Main Tab',
  buildDesc:
    'Nano\'s build page equips you with the insights & necessary tools to make informed data-driven decisions and build a custom segment for your upcoming activity. Explore a wide range of intent targeting options, obtain accurate impressions estimations, and leverage visual aids to refine your campaign strategy',
  manageTitle: 'Manage Main Tab',
  manageDesc:
    'Maintain seamless control over your segments, dynamically adapt and optimise in real time by using the full scope of our intent-first, ID-free technology and effortlessly replicate segments across multiple DSPs as needed. Don\'t forget to refer to Nano\'s insightful Scale Impact graph to stay informed on your campaign\'s reach throughout the flight duration',
  libraryTitle: 'Intent Library',
  libraryDesc:
    'If you\'re interested in knowing more about the intricacies of each of the 1,000 pre-built Intent categories, Nano\'s Intent Library offers comprehensive definitions and an interactive visualization of Nano\'s contextual classification prowess across 100 languages',
  universityTitle: 'Nano University',
  universityDesc:
    'The centralised knowledge hub offers a comprehensive collection of resources to foster collaboration and continuous learning. Explore a wealth of best practices, practical tips, one-pagers, and insightful case studies, all designed to support understanding Nano’s technology',
  reportTitle: 'Report Main Tab',
  reportDesc:
    'Check your campaign\'s performance and uncover actionable insights to optimize your campaigns throughout their lifecycle with our comprehensive mid and post-campaign analysis reporting tab',
  botTitle: 'NanoBot',
  botDesc:
    'Whether seeking tailored recommendations or exploring answers to your queries, NanoBot is always ready to assist, providing information for more informed decisions and next steps. If you want to speak to a member of the team just ask him for contact information - we\'ll be happy to help you!',
  profileTitle: 'Account Settings',
  profileDesc:
    'Here you can check your profile settings to re-launch the onboarding guide or contact your Nano dedicated Client Services team',
  planningCardTitle: 'Build Main Tab',
  planningCardDesc:
    'Nano\'s build page equips you with the insights & necessary tools to make informed data-driven decisions and build a custom segment for your upcoming activity. Explore a wide range of intent targeting options, obtain accurate impressions estimations, and leverage visual aids to refine your campaign strategy',
  managementCardTitle: 'Manage Main Tab',
  managementCardDesc:
    'Maintain seamless control over your segments, dynamically adapt and optimise in real time by using the full scope of our intent-first, ID-free technology and effortlessly replicate segments across multiple DSPs as needed. Don\'t forget to refer to Nano\'s insightful Scale Impact graph to stay informed on your campaign\'s reach throughout the flight duration',
  reportingCardTitle: 'Report Main Tab',
  reportingCardDesc:
    'Check your campaign\'s performance and uncover actionable insights to optimize your campaigns throughout their lifecycle with our comprehensive mid and post-campaign analysis reporting tab',
};
