import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Tabs } from 'components/common';

import { getCurrentBucket } from 'modules/segment/segmentSelectors';

import { ManualKeywordsEntry } from './components/ManualEnterKeywords';
import { UploadKeywords } from './components/UploadKeywords';
import { useKeywords } from './useKeywords';

export const Keywords = () => {
  const { t } = useTranslation();
  const { keywordsValues, uploadedValuesCount } = useKeywords();
  const currentBucket = useSelector(getCurrentBucket);
  const intentPrompt = currentBucket.keywords.intentPrompt;

  const tabItems = useMemo(() => {
    const items = [
      {
        key: intentPrompt ? 'enterIntentPrompt' : 'enterKeywords',
        label: (
          <TabLabel
            name={
              intentPrompt
                ? t('segment.keywords.enterIntentPrompt')
                : t('segment.keywords.enterKeywords')
            }
            keywordCount={keywordsValues.length}
          />
        ),
        children: <ManualKeywordsEntry intentPrompt={intentPrompt} />,
      },
    ];

    if (!intentPrompt) {
      items.push({
        key: 'uploadKeywords',
        label: (
          <TabLabel
            name={t('segment.keywords.uploadedKeywords')}
            keywordCount={uploadedValuesCount}
          />
        ),
        children: <UploadKeywords />,
      });
    }

    return items;
  }, [intentPrompt, t, keywordsValues.length, uploadedValuesCount]);

  return <Tabs items={tabItems}></Tabs>;
};

const TabLabel = ({ name, keywordCount }: { name: string; keywordCount?: number }) => (
  <span>
    {name} {keywordCount ? `(${keywordCount})` : ''}
  </span>
);
