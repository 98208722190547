import { useDispatch, useSelector } from 'react-redux';

import {
  addLabelToSegment as addLabelToSegmentAction,
  createNewLabel,
  getLabelsData,
  getLabelsLoading,
  deleteLabel as deleteLabelAction,
  removeLabelFromSegment as removeLabelFromSegmentAction,
} from 'modules/labels/labelsSelector';

import { useSegmentsListQuery } from '../segment/list/segmentsListApiSlice';
import { SegmentLabel } from './types';

export const useLabels = (segmentId?: string) => {
  const { segment } = useSegmentsListQuery(null, {
    selectFromResult: ({ data }) => ({
      segment: data?.find((segment) => segment.id === segmentId),
    }),
  });

  const isLoading = useSelector(getLabelsLoading);
  const dispatch = useDispatch();

  const labels: SegmentLabel[] = useSelector(getLabelsData);

  const getColorizedLabel = (labelId: string) => labels.find(({ id }) => id === labelId);

  const segmentLabels = segment?.labels ? segment.labels.map(({ name }) => name) : [];

  const addLabel = (label: string, segmentId: string) => {
    if (label.length > 0) {
      dispatch(createNewLabel({ label, segmentId }));
    }
  };

  const addLabelToSegment = (label: string, segmentId: string) => {
    const labelNames = labels.map(({ name }) => name.toLowerCase());

    if (label.length > 0 && segmentId && !labelNames.includes(label.toLowerCase())) {
      dispatch(addLabelToSegmentAction({ label, segmentId }));
    }
  };

  const deleteLabel = (id: string, label: string) => {
    dispatch(deleteLabelAction(id));
    if (segment) {
      dispatch(removeLabelFromSegmentAction({ label, segment }));
    }
  };

  const removeLabelFromSegment = (label: string) => {
    if (segment) {
      dispatch(removeLabelFromSegmentAction({ label, segment }));
    }
  };

  return {
    labels,
    segmentLabels,
    isLoading,
    addLabel,
    deleteLabel,
    addLabelToSegment,
    removeLabelFromSegment,
    getColorizedLabel,
  };
};
