import { extendTailwindMerge } from 'tailwind-merge';

const themeConfig = {
  fontSize: [
    'base',
    'title',
    'heading-xl',
    'heading-l',
    'heading-m',
    'heading-s',
    'base-s',
    'base-l',
    'base-xl',
  ],
  backgroundImage: [
    'primary-gradient',
    'secondary-gradient',
    'sidebar-gradient',
    'main',
    'unauthorizedLeftBg',
    'welcome-gradient',
  ],
  boxShadow: ['input-focus-shadow', 'input-error-shadow'],
  colors: [
    'primary-dark-purple-100',
    'primary-dark-purple-80',
    'primary-dark-purple-60',
    'primary-dark-purple-40',
    'primary-dark-purple-20',
    'primary-dark-purple-10',
    'primary-dark-purple-5',

    'primary-light-purple-100',
    'primary-light-purple-40',
    'primary-light-purple-20',
    'primary-light-purple-10',

    'primary-electric-cyan-100',
    'primary-electric-cyan-40',
    'primary-electric-cyan-20',
    'primary-electric-cyan-10',

    'secondary-purple',
    'secondary-violet',
    'secondary-teal',
    'secondary-green',
    'secondary-white',

    'text-100',
    'text-80',
    'text-60',
    'text-40',
    'text-20',
    'text-10',
    'text-5',

    'system-info-100',
    'system-info-50',
    'system-info-20',
    'system-info-5',
    'system-info-hover',
    'system-info-active',

    'system-error-100',
    'system-error-50',
    'system-error-20',
    'system-error-5',
    'system-error-hover',
    'system-error-active',

    'system-warning-100',
    'system-warning-50',
    'system-warning-20',
    'system-warning-5',
    'system-warning-hover',
    'system-warning-active',

    'system-success-100',
    'system-success-50',
    'system-success-20',
    'system-success-5',
    'system-success-hover',
    'system-success-active',
  ],
};

const classGroupsConfig = {
  fontSize: [
    'text-inherit',
    'text-base',
    'text-title',
    'text-heading-3xl',
    'text-heading-xl',
    'text-heading-l',
    'text-heading-m',
    'text-heading-s',
    'text-base-s',
    'text-base-l',
    'text-base-xl',
  ],
  backgroundImage: [
    'bg-primary-gradient',
    'bg-secondary-gradient',
    'bg-sidebar-gradient',
    'bg-main',
    'bg-unauthorizedLeftBg',
    'bg-welcome-gradient',
    'bg-file-card-gradient',
  ],
  boxShadow: ['shadow-input-focus-shadow', 'shadow-input-error-shadow', 'shadow-card-shadow'],
  backgroundColors: [
    'bg-primary-dark-purple-100',
    'bg-primary-dark-purple-80',
    'bg-primary-dark-purple-60',
    'bg-primary-dark-purple-40',
    'bg-primary-dark-purple-20',
    'bg-primary-dark-purple-10',
    'bg-primary-dark-purple-5',

    'bg-primary-light-purple-100',
    'bg-primary-light-purple-40',
    'bg-primary-light-purple-20',
    'bg-primary-light-purple-10',

    'bg-primary-electric-cyan-100',
    'bg-primary-electric-cyan-40',
    'bg-primary-electric-cyan-20',
    'bg-primary-electric-cyan-10',

    'bg-secondary-purple',
    'bg-secondary-violet',
    'bg-secondary-teal',
    'bg-secondary-green',
    'bg-secondary-mint',
    'bg-secondary-white',

    'bg-system-info-100',
    'bg-system-info-50',
    'bg-system-info-20',
    'bg-system-info-5',
    'bg-system-info-hover',
    'bg-system-info-active',

    'bg-system-error-100',
    'bg-system-error-50',
    'bg-system-error-20',
    'bg-system-error-5',
    'bg-system-error-hover',
    'bg-system-error-active',

    'bg-system-warning-100',
    'bg-system-warning-50',
    'bg-system-warning-20',
    'bg-system-warning-5',
    'bg-system-warning-hover',
    'bg-system-warning-active',

    'bg-system-success-100',
    'bg-system-success-50',
    'bg-system-success-20',
    'bg-system-success-5',
    'bg-system-success-hover',
    'bg-system-success-active',
  ],
};

const conflictingClassGroupModifiersConfig = {
  hover: classGroupsConfig.backgroundColors,
  active: classGroupsConfig.backgroundColors,
};

const conflictingClassGroupsConfig = {
  textColor: [
    'text-primary-dark-purple-100',
    'text-primary-dark-purple-80',
    'text-primary-dark-purple-60',
    'text-primary-dark-purple-40',
    'text-primary-dark-purple-20',
    'text-primary-dark-purple-10',
    'text-primary-dark-purple-5',

    'text-primary-light-purple-100',
    'text-primary-light-purple-40',
    'text-primary-light-purple-20',
    'text-primary-light-purple-10',

    'text-primary-electric-cyan-100',
    'text-primary-electric-cyan-40',
    'text-primary-electric-cyan-20',
    'text-primary-electric-cyan-10',

    'text-secondary-purple',
    'text-secondary-violet',
    'text-secondary-teal',
    'text-secondary-green',
    'text-secondary-white',

    'text-text-100',
    'text-text-80',
    'text-text-60',
    'text-text-40',
    'text-text-20',
    'text-text-10',
    'text-text-5',

    'text-system-info-100',
    'text-system-info-50',
    'text-system-info-20',
    'text-system-info-5',
    'text-system-info-hover',
    'text-system-info-active',

    'text-system-error-100',
    'text-system-error-50',
    'text-system-error-20',
    'text-system-error-5',
    'text-system-error-hover',
    'text-system-error-active',

    'text-system-warning-100',
    'text-system-warning-50',
    'text-system-warning-20',
    'text-system-warning-5',
    'text-system-warning-hover',
    'text-system-warning-active',

    'text-system-success-100',
    'text-system-success-50',
    'text-system-success-20',
    'text-system-success-5',
    'text-system-success-hover',
    'text-system-success-active',
  ],
  backgroundColors: classGroupsConfig.backgroundColors,
};

export const nanoTwMerge = extendTailwindMerge({
  theme: themeConfig,
  classGroups: classGroupsConfig,
  conflictingClassGroups: conflictingClassGroupsConfig,
  conflictingClassGroupModifiers: conflictingClassGroupModifiersConfig,
});
