import { TabPaneProps, Tabs as TabsAntd, TabsProps as TabsAntdProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

interface TabsProps extends TabsAntdProps {
  withChevron?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

export const Tabs = memo(
  ({ children, withChevron, fullWidth, fullHeight, className, ...rest }: TabsProps) => (
    <TabsAntd
      className={classNames(
        'nano-tabs',
        {
          'nano-tabs-with-chevron': withChevron,
          'full-width': fullWidth,
          'full-height': fullHeight,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </TabsAntd>
  ),
);

Tabs.displayName = 'Tabs';

export const TabPane = memo(({ className, ...rest }: TabPaneProps) => (
  <TabsAntd.TabPane className={classNames(className)} {...rest} />
));

TabPane.displayName = 'TabPane';
