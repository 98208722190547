import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePersonasQuery } from 'modules/apiData/apiDataApiSlice';
import { getPersonas } from 'modules/segment/segmentSelectors';
import { Persona } from 'modules/segment/types';

export const usePersonas = () => {
  const personasSlice = usePersonasQuery(null);
  const { personasValues, isIncluded } = useSelector(getPersonas);

  const { data: personasMap, isLoading } = personasSlice;

  const extractedPersonaValues = useMemo(() => Object.values(personasMap ?? {}), [personasMap]);

  const parentPersonas = extractedPersonaValues.filter((persona) => !persona.parentId);

  const parentPersonasWithChildren = parentPersonas.map((persona) => {
    return {
      ...persona,
      children: findChildrenByParentId(extractedPersonaValues, persona.id),
    };
  });

  function findChildrenByParentId(personasValues: Persona[], id: string) {
    return personasValues.filter((persona) => persona.parentId === id) || [];
  }

  return {
    personasMap,
    isLoading,
    selected: personasValues,
    parentPersonasWithChildren,
    isIncluded,
  };
};
