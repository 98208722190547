import { DatePicker as AntdDatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import { Calendar } from 'feather-icons-react';
import { useMemo, useCallback, forwardRef } from 'react';

dayjs.extend(weekday);
dayjs.extend(localeData);

// Define the props type for the DatePicker component
interface DatePickerProps {
  value: string | null;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  disabledBeforeToday?: boolean;
  disabledFiveDaysFromToday?: boolean;
  onChange: (value: string | null) => void;
}

const DatePicker = forwardRef<React.ComponentRef<typeof AntdDatePicker>, DatePickerProps>(
  (
    {
      value,
      format = 'D MMM YYYY',
      onChange,
      placeholder,
      disabled,
      disabledBeforeToday,
      disabledFiveDaysFromToday,
    },
    ref,
  ) => {
    const valueObject = useMemo(() => (value !== null ? dayjs(value) : null), [value]);

    const handleChange = (dayjsValue: Dayjs | null) => {
      const value = dayjsValue ? dayjs(dayjsValue).toISOString() : dayjsValue;

      onChange(value);
    };

    const disabledDate: RangePickerProps['disabledDate'] = useCallback(
      (current: dayjs.Dayjs) => {
        const today = dayjs().startOf('day');

        if (disabledBeforeToday && current && current < today) {
          return true;
        }
        if (disabledFiveDaysFromToday) {
          const maxDate = today.add(5, 'day').endOf('day');

          return current && current > maxDate;
        }

        return false;
      },
      [disabledBeforeToday, disabledFiveDaysFromToday],
    );

    return (
      <AntdDatePicker
        ref={ref}
        disabledDate={disabledDate}
        disabled={disabled}
        showToday={false}
        onChange={handleChange}
        format={format}
        className="nano-date-picker"
        popupClassName="nano-date-picker-dropdown"
        suffixIcon={<Calendar className="text-text-80" size={16} />}
        placement="bottomLeft"
        placeholder={placeholder}
        value={valueObject}
      />
    );
  },
);

DatePicker.displayName = 'DatePicker';

export { DatePicker };
