import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { authStorage } from './authStorage';
import { AuthResponse, AuthState, Credentials, ReportResponse } from './types';

const initialState: AuthState = {
  token: authStorage.getAuthToken(),
  ssoUrl: null,
  isLoading: false,
  tableauToken: null,
};

export const loginAction = createAction<Credentials>('auth/loginRequest');
export const logoutAction = createAction('auth/logoutRequest');

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state: AuthState, { payload }: PayloadAction<AuthResponse>) {
      state.token = payload.token;
    },
    loginFailure(state: AuthState) {
      state.token = null;
    },
    ssoRequest(state: AuthState) {
      state.isLoading = true;
    },
    ssoRequestFailure(state: AuthState) {
      state.isLoading = false;
    },
    exchangeCode(state: AuthState) {
      state.isLoading = true;
    },
    exchangeCodeSuccess(state: AuthState, { payload }: PayloadAction<string>) {
      state.token = payload;
      state.isLoading = false;
    },
    getTableauToken(state: AuthState) {
      state.isLoading = true;
    },
    getTableauTokenSuccess(state: AuthState, { payload }: PayloadAction<ReportResponse>) {
      state.tableauToken = payload;
      state.isLoading = false;
    },
    getTableauTokenFailure(state: AuthState) {
      state.tableauToken = null;
      state.isLoading = false;
    },
  },
});

export const getAuthState = ({ auth }: AppState) => auth;
export const getIsAuthLoading = (state: AppState) => getAuthState(state).isLoading;
export const getAuthToken = (state: AppState) => getAuthState(state).token;

export const {
  loginFailure,
  exchangeCode,
  exchangeCodeSuccess,
  ssoRequest,
  ssoRequestFailure,
  getTableauToken,
  getTableauTokenSuccess,
  getTableauTokenFailure,
} = authSlice.actions;
export const auth = authSlice.reducer;
