import { useTranslation } from 'react-i18next';

import { Title } from 'components/common';

export const NoDataAvailable = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[208px] flex-col items-center justify-center">
      <Title level={3} className="text-primary-dark-purple-40">
        {t('common.noDataText')}
      </Title>
    </div>
  );
};
