import { UserListItem } from './userApiTypes';

export type UserState = {
  id: string;
  name: string;
  surname: string;
  email: string;
  labels: string[];
  password?: string;
  teamId: string | null;
  companyId: string | null;
  isCreated: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  isUpdated: boolean;
  userToDeleteId: string;
  currentStep?: number;
};

export interface ResponseState extends UserState {
  role: Role;
}

export type UserRequestValues = {
  companyId?: string | null;
  name?: string;
  surname?: string;
  labels?: string[];
  password?: string;
  teamId?: string | null;
  email?: string;
};

export type UserFormProps = {
  id: string | undefined;
  title: string;
  userData: UserState;
  assignRoleData: RoleAssign;
};

export type UserResponse = {
  companyId: string | null;
  email: string;
  id: string;
  labels: Label[];
  name: string;
  surname: string;
  teamId: string | null;
  role: Role;
};

export type Label = {
  name: string;
  id: string;
};

export type RoleAssign = {
  companies: string[];
  roles: string | null;
  teams: string[];
};

export enum RolesPermission {
  administrator = 'Administrator',
  generalAccess = 'General Access',
  companyManager = 'Company Manager',
  user = 'User',
  teamManager = 'Team Manager',
}

export enum Resource {
  Company = 'Company',
  Team = 'Team',
  User = 'User',
  Segment = 'Segment',
  CustomCategory = 'CustomCategory',
  KeywordGroup = 'KeywordGroup',
}

export enum Permission {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
  AssignRole = 'AssignRole',
}

export interface RolePermissions {
  [resource: string]: Permission[];
}

export interface Role {
  resources: Resource[];
  role_name: string;
  role: RolePermissions;
  assignable_roles: string[];
  restrictions: { [resource: string]: string[] };
}

export const GroupingModes = {
  UNGROUPED: 'ungrouped',
  COMPANY: 'company',
} as const;

type ObjectValues<T> = T[keyof T];

export type GroupingMode = ObjectValues<typeof GroupingModes>;

export type UsersListItemWithKey = UserListItem & {
  key: string;
  parentIndex?: number;
  //hack: isFirst/isLast is needed for marking items in the list which should get rounded borders
  isFirst?: boolean;
  isLast?: boolean;
  showSeeAllLink?: boolean;
  color?: { font: string; background: string };
};

export type UsersListItem = UsersListItemWithKey & {
  children: UsersListItemWithKey[];
};
