import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { TransformedIntentSeasonalityData } from '../types';
import { IntentSeasonalityState } from './types';

const initialState: IntentSeasonalityState = {
  intentSeasonalityData: [],
  isLoading: false,
};

export const intentSeasonalitySlice = createSlice({
  name: 'intentSeasonality',
  initialState,
  reducers: {
    setIntentSeasonalityLoading: (state: IntentSeasonalityState) => {
      state.isLoading = true;
    },
    getIntentSeasonalitySuccess: (
      state: IntentSeasonalityState,
      { payload }: PayloadAction<TransformedIntentSeasonalityData>,
    ) => {
      state.isLoading = false;
      state.intentSeasonalityData = payload
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
        .filter((el) => +el.value > 0);
    },
    getIntentSeasonalityFailure: (state: IntentSeasonalityState) => {
      state.isLoading = false;
    },
    resetIntentSeasonalityData: (state: IntentSeasonalityState) => {
      state.intentSeasonalityData = [];
      state.isLoading = false;
    },
  },
});

const intentSeasonalitySelector = ({ intentSeasonality }: AppState) => intentSeasonality;

export const getIntentSeasonalityData = (state: AppState) =>
  intentSeasonalitySelector(state).intentSeasonalityData;

export const getIntentSeasonalityLoading = (state: AppState) =>
  intentSeasonalitySelector(state).isLoading;

export const {
  setIntentSeasonalityLoading,
  getIntentSeasonalitySuccess,
  getIntentSeasonalityFailure,
  resetIntentSeasonalityData,
} = intentSeasonalitySlice.actions;

export const intentSeasonality = intentSeasonalitySlice.reducer;
