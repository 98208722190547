import { AppState } from 'redux/store/store';

export const getCustomCategory = ({ customCategory }: AppState) => customCategory;
export const getCustomCategoryLoading = (state: AppState) =>
  getCustomCategory(state).isLoadingCustomCategory;
export const getCustomCategoryId = (state: AppState) => getCustomCategory(state).id;

export const getCustomCategoryKeywordGroups = (state: AppState) =>
  getCustomCategory(state).keywordGroups;
export const getCustomCategoryIsTouched = (state: AppState) => getCustomCategory(state).isTouched;
