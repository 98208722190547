import { DistanceCheckerForm } from './DistanceCheckerForm';
import { DistanceCheckerList } from './DistanceCheckerList';

export const DistanceCheckerInnerPage = () => {
  return (
    <div className="mb-6">
      <DistanceCheckerForm />
      <DistanceCheckerList />
    </div>
  );
};
