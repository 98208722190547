import { read, WorkSheet } from 'xlsx';

import { notifyError } from 'services/logService';

export const parseFile = async (file: File): Promise<string[]> => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = read(arrayBuffer, { type: 'array' });

    let keywords: string[] = [];

    workbook.SheetNames.forEach((sheetName) => {
      const sheet: WorkSheet = workbook.Sheets[sheetName];

      keywords = [
        ...keywords,
        ...Object.values(sheet)
          .map((value) => value?.h) // assuming you're looking at the header of each cell
          .filter(Boolean),
      ];
    });

    return keywords;
  } catch (e) {
    notifyError(e);

    return [];
  }
};
