import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getNanoBotLoading, getNanoBotMessagesHistory } from 'modules/nanoBot/nanoBotSlice';

import { ChatLoadingMessage } from './ChatLoadingMessage';
import { ChatMessage } from './ChatMessage';
import { NanoBotWelcomeMessage } from './NanoBotWelcomeMessage';

export const ChatArea = () => {
  const messagesHistory = useSelector(getNanoBotMessagesHistory);
  const isLoading = useSelector(getNanoBotLoading);
  const chatAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [messagesHistory, chatAreaRef]);

  return (
    <section ref={chatAreaRef} className="h-3/5 overflow-y-auto px-[25px] text-secondary-white">
      <NanoBotWelcomeMessage />
      {messagesHistory?.length > 0 &&
        messagesHistory.map((message, index) => (
          <ChatMessage key={index} messageContent={message} />
        ))}
      {isLoading && <ChatLoadingMessage />}
    </section>
  );
};
