import { useTranslation } from 'react-i18next';

import { Option, Select, Text } from 'components/common';

import { GroupingMode } from '../../types';
import { groupingOptions } from './constants';

type GroupBySelectorUsersProps = {
  selectedGrouping: GroupingMode;
  onChange: (value: GroupingMode) => void;
};

export const GroupBySelectorUsers = ({ selectedGrouping, onChange }: GroupBySelectorUsersProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-1 bg-secondary-white p-1 items-center rounded-xl w-[300px]">
      <Text className="text-base-l mx-3 font-light text-nowrap w-[160px]">
        {t('pages.management.groupBy')}
      </Text>
      <Select value={selectedGrouping} key={selectedGrouping} onChange={onChange}>
        {groupingOptions.map((option) => (
          <Option key={option.id}>{option.label}</Option>
        ))}
      </Select>
    </div>
  );
};
