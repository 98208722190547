export interface CustomCategoryState {
  name: string;
  description: string;
  keywordGroups: string[];
  parentId: string | null;
  isLoadingCustomCategory: boolean;
  error?: string | null;
  errorStatus?: number | null;
  id: string;
  isTouched: boolean;
  isCreated: boolean;
  message: string;
  isUpdating: boolean;
  isUpdated: boolean;
  mode: PersonasMode;
  lastLoadedId: string | null;
  customCategoryCreatedId: string | null;
}

export enum PersonasMode {
  keywordGroups = 'keywordGroups',
  customCategory = 'customCategory',
}

export type CustomCategoryPayload = {
  name: string;
  keywordGroups: string[];
  description: string;
  parentId: string | null;
};

export type CustomCategoryResponse = {
  id: string;
  name: string;
  description: string;
  parentId: string | null;
  keywordGroups: string[];
};

export type CustomCategoryFormProps = {
  id?: string;
  title: string;
  preloadData: CustomCategoryResponse;
};
