import { BugsnagErrorBoundary as BugsnagErrorBoundaryProps } from '@bugsnag/plugin-react';
import { bugsnagClient } from 'bugsnag';
import React, { PropsWithChildren } from 'react';

const ErrorBoundary = bugsnagClient.getPlugin('react')?.createErrorBoundary(React);

export const BugsnagErrorBoundary: PropsWithChildren<BugsnagErrorBoundaryProps> = ({
  children,
  ...rest
}) => (ErrorBoundary ? <ErrorBoundary {...rest}>{children}</ErrorBoundary> : null);
