import { TargetingOptionIds } from 'modules/segment/segmentForms/ManualStepForm/targetingOptions';
import { ManualStepValues } from 'modules/segment/types';

export const defaultBucketState: ManualStepValues = {
  id: null,
  [TargetingOptionIds.keywords]: {
    isIncluded: true,
    isTouched: false,
    keywordsText: '',
    keywordsValues: [],
    uploadedValues: [],
    intentPrompt: false,
  },
  [TargetingOptionIds.categories]: {
    isIncluded: true,
    isTouched: false,
    categoriesValues: [],
  },
  [TargetingOptionIds.sentiments]: {
    isIncluded: true,
    isTouched: false,
    sentimentsValue: {
      min: -1,
      max: 1,
    },
  },
  [TargetingOptionIds.emotions]: {
    isIncluded: true,
    isTouched: false,
    emotionsValues: [],
  },
  [TargetingOptionIds.entity]: {
    isIncluded: true,
    isTouched: false,
    entity: [],
  },
  [TargetingOptionIds.languages]: {
    isIncluded: true,
    isTouched: false,
    languagesValues: [],
  },
  [TargetingOptionIds.personas]: {
    isIncluded: true,
    isTouched: false,
    personasValues: [],
  },
  categoriesDataObject: {},
  categoriesSearchString: '',
};
