import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from '../../../services/logService';
import { distanceCheckerApi } from './distanceCheckerApi';
import { DistanceCheckerRequest, DistanceCheckerResponse } from './distanceCheckerApiTypes';
import { getDistanceCheckerState } from './distanceCheckerSelectors';
import {
  checkDistanceFailure,
  checkDistanceStart,
  checkDistanceSuccess,
} from './distanceCheckerSlice';
import { DistanceCheckerState } from './types';

function* distanceCheckerGetSaga() {
  try {
    const distanceCheckerState: DistanceCheckerState = yield select(getDistanceCheckerState);

    const requestData: DistanceCheckerRequest = {
      url: distanceCheckerState.url,
      segmentId: distanceCheckerState.segmentId,
    };

    const response: DistanceCheckerResponse = yield call(
      distanceCheckerApi.getDistanceCheckerData,
      requestData,
    );

    yield put(checkDistanceSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(
      checkDistanceFailure({ message: i18next.t('adminPanel.distanceChecker.errors.failed') }),
    );
  }
}

export function* distanceCheckerSagas() {
  yield all([takeLatest(checkDistanceStart.type, distanceCheckerGetSaga)]);
}
